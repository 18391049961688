import React, { Fragment } from "react";
import SearchBar from "./SearchBar";
import Filters from "./Filters";
import { Row, Col, Button } from "shards-react";
import Headers from "./HeaderData";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Loader from "../loader/Loader";
import { connect } from "react-redux";

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: null
    };
  }

  applyFilters() {}

  filterCaseInsensitive(filter, row, isFilterApply) {
    let isFalse = true;
    const id = filter.pivotId || filter.id;
    if (id === "disabled") {
      let isDisabled = row[id] === 1 ? "no" : "yes";
      return String(isDisabled)
        .toLowerCase()
        .startsWith(filter.value.toLowerCase());
    }

    if (row[id] !== undefined) {
      isFalse = String(row[id])
        .toLowerCase()
        .startsWith(filter.value.toLowerCase());
    }

    return isFalse;
  }

  handleFilterClick = evt => {
    console.log(this.state);
  };

  handleChange = evt => {
    console.log(`name: ${evt.target.name} value: ${evt.target.value}`);
    this.setState({
      [evt.target.name]: evt.target.value
    });
  };

  render() {
    let data = this.props.tableData;
    if (this.state.search) {
      if (data) {
        data = data.filter(row => {
          const search = this.state.search.toLowerCase();
          switch (this.props.tableType) {
            // To Do Implement search feature
            case "work-in-progress":
              return true;
            case "passages":
              return (
                String(row.id)
                  .toLowerCase()
                  .includes(search) ||
                String(row.name)
                  .toLowerCase()
                  .includes(search) ||
                String(row.passageLength.name)
                  .toLowerCase()
                  .includes(search) ||
                String(row.genre.name)
                  .toLowerCase()
                  .includes(search) ||
                String(row.grade.name)
                  .toLowerCase()
                  .includes(search) ||
                String(row.readingLevel)
                  .toLowerCase()
                  .includes(search) ||
                String(row.disabled)
                  .toLowerCase()
                  .includes(search)
              );
            case "manage-users":
              return (
                row.name.toLowerCase().includes(search) ||
                row.email.toLowerCase().includes(search) ||
                row.role.toLowerCase().includes(search)
              );
            case "questions":
              return (
                String(row.id)
                  .toLowerCase()
                  .includes(search) ||
                String(row.question)
                  .toLowerCase()
                  .includes(search) ||
                String(row.questionType.name)
                  .toLowerCase()
                  .includes(search) ||
                String(row.skill.name)
                  .toLowerCase()
                  .includes(search) ||
                String(row.passage.name)
                  .toLowerCase()
                  .includes(search) ||
                String(row.skill.tier.name)
                  .toLowerCase()
                  .includes(search)
              );
            case "review-questions":
              console.log(row);
              return (
                String(row.question)
                  .toLowerCase()
                  .includes(search) ||
                String(row.passage.name)
                  .toLowerCase()
                  .includes(search) ||
                String(row.userName)
                  .toLowerCase()
                  .includes(search) ||
                String(row.submittedAt)
                  .toLowerCase()
                  .includes(search)
              );
            case "books":
              console.log(row);
              return (
                String(row.id)
                  .toLowerCase()
                  .includes(search) ||
                String(row.title)
                  .toLowerCase()
                  .includes(search) ||
                String(row.publisherRights)
                  .toLowerCase()
                  .includes(search) ||
                String(row.author)
                  .toLowerCase()
                  .includes(search) ||
                String(row.noOfPassages)
                  .toLowerCase()
                  .includes(search) ||
                String(row.age)
                  .toLowerCase()
                  .includes(search)
              );
            case "content-pack-questions":
              return (
                String(row.name)
                  .toLowerCase()
                  .includes(search) ||
                String(row.gradeId)
                  .toLowerCase()
                  .includes(search)
              )
            default:
              return "";
          }
        });
      }
    }
    return (
      <Fragment>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <Row>
              <Col lg="12">
                { !this.props.hideSearch ?
                  <SearchBar
                    value={this.state.search}
                    onChange={e => this.setState({ search: e.target.value })}
                  />
                  :
                  null
                }
              </Col>
              <Col lg="12">
                {this.props.filter === false ? "" : null
                // <Filters
                //   applyFilters={this.handleFilterClick}
                //   handleChange={this.handleChange}
                //   genreData={this.props.genre}
                //   questionTypesData={this.props.questionTypes}
                //   skillData={this.props.skills}
                //   gradeData={this.props.grades}
                //   passageData={this.props.passages}
                //   passageLengthData={this.props.passageLength}
                //   grades={this.props.grades}
                //   filterType={this.props.filterType}
                //   bookData={this.props.books}
                //   usersData={this.props.users}
                //   showFilter={this.props.filter}
                // />
                }
              </Col>
            </Row>
            <br />

            <Row>
              <Col lg="12">
                <ReactTable
                  columns={Headers(
                    this.props.tableType,
                    this.props.handlePublishPassage ||
                      this.props.handleWorkInProgressDone,
                    this.props.match,
                    this.props.userData,
                    {...this.props}
                  )}
                  defaultFilterMethod={(filter, row) =>
                    this.filterCaseInsensitive(filter, row)
                  }
                  data={data}
                  sortable={this.props.filter}
                  defaultPageSize={10}
                  filterable={true}
                  className="table mb-0"
                  getTrProps={this.props.getTrProps}
                  getTdProps={this.props.getTdProps}
                />
              </Col>
            </Row>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.userData
});

export default connect(
  mapStateToProps,
  null
)(Table);
