import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllSkills, clearSkills } from "../redux/actions/skill-actions";
import { setStatus } from "../redux/actions/status-actions";

class Skills extends React.Component {
  componentDidMount() {
    this.retrieveData();
  }

  retrieveData = () => {
    if (this.props.match.url.includes("assigned-skills")) {
      this.props.getAllSkills(true);
    }
  };

  componentWillUnmount() {
    this.props.clearSkills();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.match.params.status !== this.props.match.params.status) {
  //     let status = this.props.match.params.status;
  //     if (this.props.match.params.status === "disabled") {
  //       status = "ready passages";
  //     }
  //     this.props.triggerNotification(`changed to ${status}`, "info", "tr");
  //     this.retrieveData();
  //   }
  // }

  publishPassage = id => {
    this.props.publishPassage(
      "publishToGame",
      {
        disabled: 0,
        pending: 0
      },
      id,
      this.props.triggerNotification
    );
    setTimeout(() => {
      this.retrieveData();
    }, 500);
  };

  render() {
    let mainTitle = "skills";
    if (this.props.currentStatus === "draft") {
      mainTitle = `${mainTitle} in draft`;
    } else if (this.props.match.url.includes("/assigned-skills")) {
      mainTitle = `${mainTitle} assigned`;
    } else if (this.props.currentStatus === "published") {
      mainTitle = `${mainTitle} in published`;
    } else if (this.props.match.url.includes("disabled/1")) {
      mainTitle = "ready skills";
    }
    const tableType = this.props.match.url.includes("assigned-skills")
      ? "assigned-skills"
      : "skills";
    return (
      <Container>
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title={`All ${mainTitle}`}
            subtitle=""
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col lg="12">
            <Table
              tableType={tableType}
              {...this.props}
              handlePublishPassage={this.publishPassage}
              tableData={this.props.skills}
              filterType={"all-passages"}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  skills: state.skills,
  currentStatus: state.currentStatus
});

const mapActionToProps = dispatch => ({
  getAllSkills: bindActionCreators(getAllSkills, dispatch),
  clearSkills: bindActionCreators(clearSkills, dispatch),
  setStatus: bindActionCreators(setStatus, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(Skills);
