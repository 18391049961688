import { postContentPack, getContentPacks } from "../../api/content-pack-api";
import { SET_ID } from "./id-actions";

export const PUBLISH_CONTENT_PACK =
  "contentPack:publish";
export const RETRIEVE_ALL_CONTENT_PACK =
  "contentPack:retrieveAll";

export function getAllContentPacks(userId) {
  return dispatch => {
    getContentPacks(userId)
      .then(({ data }) => {
        dispatch({
          type: RETRIEVE_ALL_CONTENT_PACK,
          payload: data.data
        });
      })
      .catch(err => {
        return dispatch({
          type: RETRIEVE_ALL_CONTENT_PACK,
          payload: []
        });
      });
  };
}

export function publishContentPack(request, notificationSystem) {
  return dispatch => {
    postContentPack(request)
      .then(data => {
        dispatch({
          type: SET_ID,
          payload: data.data.data.id
        });
        notificationSystem("Pack Created Successfully", "success");
      })
      .catch(err => {
        return dispatch({
          type: PUBLISH_CONTENT_PACK,
          payload: []
        });
      });
  };
}
