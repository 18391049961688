import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "shards-react";
import { Link } from "react-router-dom";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";

const Books = props => {
  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="All books"
          subtitle=""
          className="text-sm-left"
        />
        <Col sm="8" className="text-right">
          <Link to="books/new">
            <Button size="sm" theme="white">
              <i className="fas fa-plus" />
              &nbsp; Add new book
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Table
            filter={false}
            {...props}
            tableData={props.books}
            tableType="books"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Books;
