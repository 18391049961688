import {GET_COMMONCORE} from "../actions/common-core-actions";

export default function commonCoreReducer(state = [], {type, payload}) {
  switch (type) {
    case GET_COMMONCORE:
      const filtered = payload.sort((a, b) => {
        return  (a.grade > b.grade) ? 1 : -1;
      });
      return filtered;
    default:
      return state;
  }
}
