import { getSkills, assignSkills } from "../../api/skills-api";

export const RETRIEVE_ALL_SKILLS = "skills:retrieveAllSkills";
export const ASSIGN_SKILLS = "skills:assignSkill";
export const CLEAR_SKILLS = "skills:clearSkills";

export function getAllSkills(isAssigned) {
  return dispatch => {
    getSkills(isAssigned)
      .then(data => {
        dispatch({
          type: RETRIEVE_ALL_SKILLS,
          payload: [...data.data.data]
        });
      })
      .catch(err => {
        dispatch({
          type: RETRIEVE_ALL_SKILLS,
          payload: []
        });
      });
  };
}

export function assignSkill(skills = [], notificationSystem) {
  return dispatch => {
    assignSkills(skills)
      .then(data => {
        notificationSystem("Assigned skill successfully", "success", "tr");
        dispatch({
          type: ASSIGN_SKILLS,
          payload: skills
        });
      })
      .catch(err => {
        console.log(err.response);
        notificationSystem(err.response.data.details, "error", "tr");
      });
  };
}

export function clearSkills() {
  return {
    type: CLEAR_SKILLS,
    payload: []
  };
}
