export default function() {
  return [
    {
      title: "Questions",
      to: "/teacher/add-questions",
      htmlBefore: '<i class="material-icons">chat</i>',
      submenu: [
        {
          title: "My Drafts",
          to: "/teacher/questions/all/draft"
        },
        {
          title: "In Review",
          to: "/teacher/questions/all/in-review"
        },
        {
          title: "Denied",
          to: "/teacher/questions/all/denied"
        },
        {
          title: "Add new question",
          to: "/teacher/questions/new"
        },
        {
          title: "Published",
          to: "/teacher/questions/all/published"
        }

      ]
    },
    {
      title: "Independent Questions",
      to: "/teacher/add-independent-questions",
      htmlBefore: '<i class="material-icons">chat</i>',
      submenu: [
        {
          title: "My Drafts",
          to: "/teacher/independent-questions/all/draft"
        },
        {
          title: "In Review",
          to: "/teacher/independent-questions/all/in-review"
        },
        {
          title: "Denied",
          to: "/teacher/independent-questions/all/denied"
        },
        {
          title: "Add new independent question",
          to: "/teacher/independent-questions/new"
        },
        {
          title: "Published",
          to: "/teacher/independent-questions/all/published"
        }

      ]
    },
    {
      title: "Passages",
      to: "/teacher/assigned-passages/published",
      htmlBefore: '<i class="material-icons">assignment</i>',
      submenu: [
        {
          title: "Assigned Passages",
          to: "/teacher/assigned-passages/published"
        },
        {
          title: "Published Passages",
          to: "/teacher/passages/all/published"
        },
      ]
    },
    // {
    //   title: "Assigned Skills",
    //   to: "/teacher/assigned-skills/published",
    //   htmlBefore: '<i class="material-icons">assignment</i>'
    // },
    {
      title: "Logout",
      htmlBefore: '<i class="material-icons">backspace</i>',
      to: "/login",
      logout: true
    }
  ];
}
