import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  FormInput,
  FormTextarea,
  Row
} from "shards-react";
import FormSelect from '../../../components/formselect/FormSelect';
import { generate } from "shortid";
import PageTitle from "../../../components/common/PageTitle";
import { setId } from "../../../redux/actions/id-actions";
import { getAllPassages } from "../../../redux/actions/passage-actions";
import { addQuestion } from "../../../redux/actions/question-actions";
import Editor from "../../../components/add-new-post/Editor";
import GrammarOutput from "../../../components/grammar-output/GrammarOutput";
import CheckGrammarButton from "../../../components/button/CheckGrammarButton";
import { resetGrammarMessages, checkGrammar } from "../../../redux/actions/grammar-actions";
import { Answers } from "../answer/Answers";
import { addAnswer, answerChange, removeAnswer } from "../QuestionOperations";
import {
  validateAndBuildAnswersByQuestionType,
  validateOrderQuestionType, validateOtherQuestionTypes,
  validateQuestionAnswers
} from "../questionValidation";
import { QuestionType } from "../../../enums/QuestionType";

const AddQuestions = props => {
  // states
  const [state, setState] = useState({
    potentialAnswers: [],
    correctAnswers: [],
    questionTypeId: null, // 1 = multiple choice, 3 = drag and drop, 4 = select all
    passageId: props.selected || null,
    skillId: null,
    skillTierId: null,
    question: null,
    difficulty: null,
    status: "In Review",
    commonCoreId: null,
    dei: 0
  });
  const [answers, setAnswers] = useState([]);
  const [order, setOrder] = useState([1, 2, 3, 4, 5, 6]);

  useEffect(() => {
    console.log(props);
    props.getPassages("published");
    props.setId(null);
    props.resetGrammarMessages();
    return () => {
      console.log("Add Question dismounting");
    };
  }, []);

  useEffect(() => {
    if (props.questionId !== null) {
      if (props.match.url.includes("writer")) {
        props.history.push(`/writer/questions/edit/${props.questionId}`);
      } else if (props.match.url.includes("lead+")) {
        props.history.push(`/lead+/questions/edit/${props.questionId}`);
      } else {
        props.history.push(`/teacher/questions/edit/${props.questionId}`);
      }
    }
  }, [props.questionId]);

  const handleChange = evt => {
    setState({
      ...state,
      [evt.target.name]: evt.target.value
    });
  };

  const renderCommonCore = () => {
    let commonCore = props.commonCore;
    // set max and min values
    const initalValue = 1;
    const lastValue = 8;
    const range = [];
    if (state.difficulty > initalValue && state.difficulty < lastValue) {
      range.push(Number(state.difficulty));
      range.push(Number(state.difficulty) - 1);
      range.push(Number(state.difficulty) + 1);
    } else {
      if (Number(state.difficulty) === initalValue) {
        range.push(Number(state.difficulty));
        range.push(Number(state.difficulty) + 1);
      } else {
        range.push(Number(state.difficulty));
        range.push(Number(state.difficulty) - 1);
      }
    }
    commonCore = commonCore.filter(e => {
      return range.includes(e.grade);
    });

    return (
      <FormGroup>
        <label htmlFor="commonCoreId">Standards</label>
        <FormSelect name="commonCoreId" onChange={handleChange}>
          <option value={null}>Choose</option>
          {commonCore.map((data, i) => {
            const index = data.description.indexOf("CCSS");
            const displayTitle = data.description.slice(index);
            const displayDescription = data.description.slice(0, index);
            return (
              <option key={i} title={displayTitle} value={data.id}>
                {displayDescription}
              </option>
            );
          })}
        </FormSelect>
      </FormGroup>
    );
  };

  const validateQuestion = () => {
    let isValid = true;

    if (state.questionTypeId === null) {
      props.triggerNotification("Question type needs to be selected", "error");
      isValid = false;
    }

    if (state.skillTierId === null) {
      props.triggerNotification("Skill Tier needs to be selected", "error");
      isValid = false;
    }

    // multiple choice validation
    isValid = validateQuestionAnswers(state.questionTypeId, answers, props.triggerNotification);
    // drag and drop

    if (state.passageId === null) {
      props.triggerNotification("Passage needs to be selected", "error");
      isValid = false;
    }
    if (state.difficulty === null) {
      props.triggerNotification("Difficulty needs to be selected", "error");
      isValid = false;
    }
    if (state.skillId === null) {
      props.triggerNotification("Skill needs to be selected", "error");
      isValid = false;
    }
    if (state.question === null) {
      props.triggerNotification("Question needs to be entered", "error");
      isValid = false;
    }

    if (state.commonCoreId === null) {
      props.triggerNotification("Must select a common core standard", "error");
      isValid = false;
    }

    return isValid;
  };

  const handleAnswerChange = (evt, id) => {
    answerChange(evt, id, setAnswers)
  };

  const requestBuilder = (potentialAnswers, correctAnswers, status) => {
    return {
      potentialAnswers,
      correctAnswers,
      questionTypeId: state.questionTypeId,
      passageId: state.passageId,
      genreId: state.genreId,
      skillId: state.skillId,
      skillTierId: state.skillTierId,
      question: state.question,
      difficulty: state.difficulty,
      status,
      commonCoreId: state.commonCoreId,
      dei: state.dei ? Number(state.dei) : 0
    };
  };

  const removeRow = id => {
    removeAnswer(id, answers, setAnswers)
  };
  const addRow = () => {
    addAnswer(state.questionTypeId, props.triggerNotification, answers, setAnswers)
  }

  const addQuestion = status => {
    try {
      const { potentialAnswers, correctAnswers, requestObject } = validateAndBuildAnswersByQuestionType(state.questionTypeId, answers, status, props.triggerNotification, requestBuilder);
      return validateQuestion(potentialAnswers, correctAnswers)
        ? props.addQuestion(requestObject, props.triggerNotification)
        : null;
    } catch (error) {
      console.log('Make sure you have selected atleast one correct answer option and that no answser fields are empty.');
    }
  };

  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add new question"
          subtitle=""
          className="text-sm-left"
        />
        <Col sm="8" className="text-right">
          {props.match.url.includes("viewer") ? (
            ""
          ) : (
            <Button theme="info" onClick={() => window.location.reload()}>
              Start a new question
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Row>
            <Col lg="12">
              <label htmlFor="questions">Question:</label>
              <Editor
                editorName="question"
                text={state.question}
                handleQuillChange={question => setState({ ...state, question })}
                toolbar={[['bold', 'italic'], ['image']]}
              />
            </Col>
            <Col lg="2">
              <CheckGrammarButton
                style={{ marginTop: 30 }}
                onClick={() => props.checkGrammar(state.question || '')}
              />
            </Col>
          </Row>
        </Col>
        <Col lg="12">
          <GrammarOutput />
        </Col>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="passage">Passage:</label>
            {props.selected ? (
              <>
                <FormSelect name="passageId" onChange={handleChange} disabled>
                  <option value={null}>Choose</option>
                  {props.passages.map((e, i) => {
                    if (props.selected === e.id) {
                      return (
                        <option key={i} value={e.id} selected>
                          {e.name}
                        </option>
                      );
                    } else {
                      return (
                        <option key={i} value={e.id}>
                          {e.name}
                        </option>
                      );
                    }
                  })}
                </FormSelect>
                {props.passages.length > 0 ? (
                  <>
                    {props.selected !== "Choose" && props.selected !== null ? (
                      <Card style={{ marginTop: "10px", overflow: "auto" }}>
                        <CardBody>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: props.passages.filter(e => {
                                return e.id === Number(state.passageId);
                              })[0].text
                            }}
                          />
                        </CardBody>
                      </Card>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <FormSelect name="passageId" onChange={handleChange}>
                  <option value={null}>Choose</option>
                  {props.passages.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </FormSelect>
                {props.passages.length > 0 ? (
                  <>
                    {state.passageId !== "Choose" &&
                      state.passageId !== null ? (
                      <Card style={{ marginTop: "10px", overflow: "auto" }}>
                        <CardBody>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: props.passages.filter(e => {
                                return e.id === Number(state.passageId);
                              })[0].text
                            }}
                          />
                        </CardBody>
                      </Card>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="difficulty">Difficulty:</label>
            <FormSelect name="difficulty" onChange={handleChange}>
              <option value={null}>Choose</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
            </FormSelect>
          </FormGroup>
        </Col>
        <Col lg="12">{state.difficulty > 0 ? renderCommonCore() : null}</Col>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="skills">Skills:</label>
            <FormSelect name="skillId" onChange={handleChange}>
              <option value={null}>Choose</option>
              {
                Object.keys(props.skillGroups).map((group) => {
                  return (
                    <optgroup label={group}>
                      {
                        props.skillGroups[group].map((skill) => {
                          if (skill) {
                            return (
                              <option key={group + skill.name} value={skill.id}>
                                {skill.name}
                              </option>
                            )
                          }
                        })
                      }
                    </optgroup>
                  )
                })
              }
            </FormSelect>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="skillTier">Skill Tier:</label>
            <FormSelect name="skillTierId" onChange={handleChange}>
              <option value={null}>Choose</option>
              <option value={1} selected={state.skillTierId === 1 ? "selected" : ""}>Inferential</option>
              <option value={2} selected={state.skillTierId === 2 ? "selected" : ""}>Literal</option>
            </FormSelect>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="questionType">Question type:</label>
            <FormSelect onChange={handleChange} name="questionTypeId">
              <option value={null}>Choose</option>
              {props.questionTypes.map((e, i) => {
                if (e.name !== "Highlight") {
                  return (
                    <option key={i} value={e.id}>
                      {e.name}
                    </option>
                  );
                }
              })}
            </FormSelect>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        {/* answer */}
        {answers.map((row, i) => {
          return <Answers state={state} removeRow={removeRow} row={row} order={order} handleAnswerChange={handleAnswerChange} />
        })}
      </Row>
      <Row>
        <Col lg="12">
          <Button onClick={addRow}>Add new answer</Button>
        </Col>
      </Row>
      <Row style={{ paddingTop: "0.5rem" }}>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="dei">DEI:</label>
            <FormSelect onChange={handleChange} name="dei">
              <option value="0" selected={!state.dei}>False</option>
              <option value="1" selected={state.dei}>True</option>
            </FormSelect>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="text-right">
          &nbsp;
          <Button theme="success" onClick={() => addQuestion("Draft")}>
            Save as Draft
          </Button>
          &nbsp;
          {props.currentStatus === "Draft" ? (
            <Button onClick={() => addQuestion("In Review")}>
              Send To Review
            </Button>
          ) : null}
        </Col>
      </Row>
      <br />
    </Container>
  );
};

const mapStateToProps = state => ({
  passages: state.passages,
  questionId: state.id,
  commonCore: state.commonCore,
  currentStatus: state.currentStatus
});

const mapActionToProps = dispatch => ({
  addQuestion: bindActionCreators(addQuestion, dispatch),
  getPassages: bindActionCreators(getAllPassages, dispatch),
  setId: bindActionCreators(setId, dispatch),
  resetGrammarMessages: bindActionCreators(resetGrammarMessages, dispatch),
  checkGrammar: bindActionCreators(checkGrammar, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(AddQuestions);
