import Axios from "axios";

const api = require("../api/api-config");

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`
};

export function getIndependentQuestions(endpoint = "local") {
  return new Promise((resolve, reject) => {
    Axios.get(api.api + `/independent-questions/${endpoint}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getIndependentQuestionById(endpoint = "local", id) {
  return new Promise((resolve, reject) => {
    Axios.get(api.api + `/independent-questions/${endpoint}/${id}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function postIndependentQuestions(questions, endpoint = "local") {
  return new Promise((resolve, reject) => {
    Axios.post(api.api + `/independent-questions/${endpoint}`, questions)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function putIndependentQuestions(questions, id, endpoint = "local") {
  return new Promise((resolve, reject) => {
    Axios.put(api.api + `/independent-questions/${endpoint}/${id}`, questions)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function deleteIndependentQuestions(question, endpoint = "local") {
  return new Promise((resolve, reject) => {
    Axios.delete(api.api + `/independent-questions/${endpoint}`, question)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function duplicateIndependentQuestionById(questionId) {
  return Axios.post(`${api.api}/independent-questions/live/duplicate/${questionId}`);
}
