import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  FormInput,
  FormTextarea,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "shards-react";
import FormSelect from '../../../components/formselect/FormSelect';
import { generate } from "shortid";
import PageTitle from "../../../components/common/PageTitle";
import { setId } from "../../../redux/actions/id-actions";
import {
  clearPassages,
  getAllPassages
} from "../../../redux/actions/passage-actions";
import {
  addQuestion,
  clearQuestion,
  getQuestionId,
  updateQuestion
} from "../../../redux/actions/question-actions";
import GrammarOutput from "../../../components/grammar-output/GrammarOutput";
import CheckGrammarButton from "../../../components/button/CheckGrammarButton";
import { resetGrammarMessages, checkGrammar } from "../../../redux/actions/grammar-actions";
import Editor from "../../../components/add-new-post/Editor";
import { Answers } from "../answer/Answers";
import { addAnswer, editAnswerChange, removeAnswer, sendQuestionBack, sendQuestionToReview } from "../QuestionOperations";
import {
  validateAndBuildAnswersByQuestionType,
  validateOrderQuestionType,
  validateQuestionAnswers
} from "../questionValidation";
import { QuestionCategory } from "../../../enums/QuestionType";

const EditQuestions = props => {
  // states
  const [state, setState] = useState({
    id: null,
    potentialAnswers: [],
    correctAnswers: [],
    questionTypeId: null, // 1 = multiple choice, 3 = drag and drop, 4 = select all
    passageId: null,
    skillId: null,
    skillTierId: null,
    question: null,
    difficulty: null,
    internalNote: null,
    status: "In Review",
    commonCoreId: null,
    isLoaded: false,
    creator: '',
    dei: 0
  });
  const [answers, setAnswers] = useState([]);
  const [order] = useState([1, 2, 3, 4, 5, 6]);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState({
    editing: false
  });
  const [note, setNote] = useState({
    internalNote: null,
    status: "Denied"
  });
  const formatValue = (text) => {
    if (text) {
      return text.replace(/\n/g, '<br>');
    }
    return null;
  }
  useEffect(() => {
    props.getPassages("published");
    props.setId(null);
    props.resetGrammarMessages();
    props
      .getQuestionById(props.currentStatus, props.match.params.id)
      .then(data => {
        const stateData = { ...data.data.data };
        setAnswers(
          stateData.answers.map((e, i) => {
            return {
              label: e.label,
              value: e.value,
              id: generate()
            };
          })
        );
        console.log(stateData);
        setState({
          potentialAnswers: [],
          correctAnswers: [],
          questionTypeId: stateData.questionType.id, // 1 = multiple choice, 3 = drag and drop, 4 = select all
          passageId: stateData.passage.id,
          skillId: stateData.skill.id,
          skillTierId: stateData.skill.tier.id,
          question: stateData.question,
          difficulty: stateData.difficulty || "",
          internalNote: stateData.internalNote || "",
          status: stateData.status,
          commonCoreId: stateData.commonCoreId,
          isLoaded: true,
          creator: stateData.userName,
          dei: stateData.dei || 0
        });
      })
      .catch(err => console.log(err));
    return () => {
      props.clearPassages();
      props.clearQuestions();
      props.setId(null);
      console.log("edit Question dismounting");
    };
  }, []);

  useEffect(() => {
    if (props.questionId !== null) {
      if (props.match.url.includes("writer")) {
        props.history.push(`/writer/questions/edit/${props.questionId}`);
      } else if (props.match.url.includes("lead+")) {
        props.history.push(`/lead+/questions/edit/${props.questionId}`);
      } else {
        props.history.push(`/teacher/questions/edit/${props.questionId}`);
      }
    }
  }, [props.currentStatus]);

  const renderCommonCore = () => {
    let commonCore = props.commonCore;
    // set max and min values
    const initalValue = 1;
    const lastValue = 8;
    const range = [];
    if (state.difficulty > initalValue && state.difficulty < lastValue) {
      range.push(Number(state.difficulty));
      range.push(Number(state.difficulty) - 1);
      range.push(Number(state.difficulty) + 1);
    } else {
      if (Number(state.difficulty) === initalValue) {
        range.push(Number(state.difficulty));
        range.push(Number(state.difficulty) + 1);
      } else {
        range.push(Number(state.difficulty));
        range.push(Number(state.difficulty) - 1);
      }
    }
    commonCore = commonCore.filter(e => {
      return range.includes(e.grade);
    });

    return (
      <FormGroup>
        <label htmlFor="commonCoreId">Standards</label>
        <FormSelect name="commonCoreId" onChange={handleChange}>
          <option value={null}>Choose</option>
          {commonCore.map((data, i) => {
            const index = data.description.indexOf("CCSS");
            const displayTitle = data.description.slice(index);
            const displayDescription = data.description.slice(0, index);
            if (state.commonCoreId === data.id) {
              return (
                <option
                  key={i}
                  title={displayTitle}
                  selected={true}
                  value={data.id}
                >
                  {displayDescription}
                </option>
              );
            } else {
              return (
                <option key={i} title={displayTitle} value={data.id}>
                  {displayDescription}
                </option>
              );
            }
          })}
        </FormSelect>
      </FormGroup>
    );
  };

  const handleChange = evt => {
    setEdit({
      editing: true
    });
    let value = evt.target.value;
    if (evt.target.value === "difficulty") {
      value = Number(value);
    }
    setState({
      ...state,
      [evt.target.name]: value
    });
    console.log(state);
  };

  const toggleModal = () => {
    setModal(!modal);
  };
  const validateQuestion = () => {
    let isValid = true;

    if (state.questionTypeId === null) {
      props.triggerNotification("Question type needs to be selected", "error");
      isValid = false;
    }
    // multiple choice validation
    isValid = validateQuestionAnswers(state.questionTypeId, answers, props.triggerNotification);

    if (state.passageId === null) {
      props.triggerNotification("Passage needs to be selected", "error");
      isValid = false;
    }
    if (state.difficulty === null) {
      props.triggerNotification("Difficulty needs to be selected", "error");
      isValid = false;
    }
    if (state.skillId === null) {
      props.triggerNotification("Skill needs to be selected", "error");
      isValid = false;
    }
    if (state.question === null) {
      props.triggerNotification("Question needs to be entered", "error");
      isValid = false;
    }
    if (state.commonCoreId === null) {
      props.triggerNotification("Must select a common core standard", "error");
      isValid = false;
    }

    if (state.skillTierId === null) {
      props.triggerNotification("Skill Tier needs to be selected", "error");
      isValid = false;
    }

    return isValid;
  };

  const requestBuilder = (potentialAnswers, correctAnswers, status) => {
    if (status === "publish") {
      return {
        potentialAnswers,
        correctAnswers,
        questionTypeId: state.questionTypeId,
        passageId: state.passageId,
        skillId: state.skillId,
        skillTierId: state.skillTierId,
        question: state.question,
        difficulty: state.difficulty,
        commonCoreId: Number(state.commonCoreId),
        dei: state.dei ? Number(state.dei) : 0
      };
    } else {
      return {
        potentialAnswers,
        correctAnswers,
        questionTypeId: state.questionTypeId,
        passageId: state.passageId,
        skillId: state.skillId,
        skillTierId: state.skillTierId,
        question: state.question,
        difficulty: state.difficulty,
        commonCoreId: Number(state.commonCoreId),
        dei: state.dei ? Number(state.dei) : 0,
        status
      };
    }
  };

  const handleAnswerChange = (evt, id) => {
    editAnswerChange(evt, id, setEdit, setAnswers)
  };
  const removeRow = id => {
    removeAnswer(id, answers, setAnswers)
  };
  const addRow = () => {
    addAnswer(state.questionTypeId, props.triggerNotification, answers, setAnswers)
  }

  const addQuestion = status => {
    try {
      if (status === "draft") {
        status = "Draft";
      } else if (status === "denied") {
        status = "Denied";
      }
      if (Number(state.questionTypeId) === 3) {
        // drag and drop
        const { potentialAnswers, correctAnswers, requestObject } = validateOrderQuestionType(answers, props.triggerNotification, status, requestBuilder);
        if (validateQuestion(potentialAnswers, correctAnswers, status)) {
          setEdit({
            editing: false
          });
          props.updateQuestion(
            props.currentStatus,
            requestObject,
            props.match.params.id,
            props.triggerNotification
          );
        }
        return;
      } else {
        const {
          potentialAnswers,
          correctAnswers,
          requestObject
        } = validateAndBuildAnswersByQuestionType(state.questionTypeId, answers, status, props.triggerNotification, requestBuilder);

        if (validateQuestion(potentialAnswers, correctAnswers, status)) {
          setEdit({
            editing: false
          });
          props.updateQuestion(
            props.currentStatus,
            requestObject,
            props.match.params.id,
            props.triggerNotification
          );
        }
        return;
      }
    } catch (error) {
      console.log('Make sure you have selected atleast one correct answer option and that no answser fields are empty.');
    }
  };

  const sendToReview = () => {
    sendQuestionToReview(props, validateQuestion, QuestionCategory.Passage)
  };

  const sendBack = () => {
    sendQuestionBack(props, note, toggleModal, QuestionCategory.Passage)
  };

  const publishQuestion = () => {
    props.publishQuestion(
      {
        localQuestionId: props.match.params.id
      },
      props.triggerNotification,
      "Publish"
    );
  };

  const handleNote = evt => {
    setNote({ ...note, [evt.target.name]: evt.target.value });
  };
  const isAllowedToEdit = () => {
    if (
      props.userData.user.role !== 'External Creator' ||
      state.status !== 'published'
    ) {
      return true;
    }
    return state.educationToolData.userId === props.userData.user.id;
  }

  const passageText = () => {
    const data = props.passages.filter(e => {
      return e.id === Number(state.passageId);
    })[0]
    return data ? data.text : ''
  }
  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Edit question"
          subtitle=""
          className="text-sm-left"
        />
      </Row>
      {state.isLoaded ? (
        <>
          <Row>
            <Col lg="12">
              <Row>
                <Col lg="10">
                  <FormGroup>
                    <label htmlFor="questions">Question:</label>
                    {/*<Editor  value={state.question || ""}*/}
                    {/*         name="question"*/}
                    {/*         onChange={handleChange}>*/}
                    {/*</Editor>*/}
                    <Editor
                      editorName="question"
                      text={state.question}
                      handleQuillChange={question => {
                        setEdit({ editing: true });
                        setState({ ...state, question });
                      }}
                      readOnly={!isAllowedToEdit()}
                      toolbar={isAllowedToEdit() ? [['bold', 'italic'], ['image']] : []}
                    />
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <CheckGrammarButton
                    style={{ marginTop: 30 }}
                    onClick={() => props.checkGrammar(state.question || '')}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg="12">
              <GrammarOutput />
            </Col>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="passage">Passage:</label>
                <FormSelect name="passageId" onChange={handleChange}>
                  <option value={null}>Choose</option>
                  {props.passages.map((e, i) => {
                    if (state.passageId === e.id) {
                      return (
                        <option key={i} value={e.id} selected>
                          {e.name}
                        </option>
                      );
                    } else {
                      return (
                        <option key={i} value={e.id}>
                          {e.name}
                        </option>
                      );
                    }
                  })}
                </FormSelect>
                <br />
                <>
                  {state.passageId !== "Choose" && state.passageId !== null ? (
                    <>
                      {props.passages.length > 0 ? (
                        <Card style={{ marginTop: "10px", overflow: 'auto' }}>
                          <CardBody>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: formatValue(
                                  passageText()
                                )
                              }}
                            />
                          </CardBody>
                        </Card>
                      ) : null}
                    </>
                  ) : (
                    ""
                  )}
                </>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="difficulty">Difficulty:</label>
                <FormSelect name="difficulty" onChange={handleChange}>
                  <option value={null}>Choose</option>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((e, i) => {
                    if (state.difficulty === e) {
                      return (
                        <option value={e} selected>
                          {e}
                        </option>
                      );
                    } else {
                      return <option value={e}>{e}</option>;
                    }
                  })}
                </FormSelect>
              </FormGroup>
            </Col>
            <Col lg="12">
              {state.difficulty > 0 ? renderCommonCore() : null}
            </Col>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="skills">Skills:</label>
                <FormSelect name="skillId" onChange={handleChange}>
                  <option value={null}>Choose</option>
                  {
                    Object.keys(props.skillGroups).map((group) => {
                      return (
                        <optgroup label={group}>
                          {
                            props.skillGroups[group].map((skill) => {
                              if (skill) {
                                if (skill.id === state.skillId) {
                                  return (
                                    <option key={group + skill.name} value={skill.id} selected>
                                      {skill.name}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option key={group + skill.name} value={skill.id}>
                                      {skill.name}
                                    </option>
                                  );
                                }
                              }
                            })
                          }
                        </optgroup>
                      )
                    })
                  }
                </FormSelect>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="skillTier">Skill Tier:</label>
                <FormSelect name="skillTierId" onChange={handleChange}>
                  <option value={null}>Choose</option>
                  <option value={1} selected={state.skillTierId === 1 ? "selected" : ""}>Inferential</option>
                  <option value={2} selected={state.skillTierId === 2 ? "selected" : ""}>Literal</option>
                </FormSelect>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="questionType">Question type:</label>
                <FormSelect onChange={handleChange} name="questionTypeId">
                  <option value={null}>Choose</option>
                  {props.questionTypes.map((e, i) => {
                    if (e.name !== "Highlight") {
                      if (state.questionTypeId === e.id) {
                        return (
                          <option key={i} value={e.id} selected>
                            {e.name}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={e.id}>
                            {e.name}
                          </option>
                        );
                      }
                    }
                  })}
                </FormSelect>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            {/* answer */}
            {answers.map((row, i) => {
              return <Answers key={i} handleAnswerChange={handleAnswerChange} order={order} row={row} removeRow={removeRow} state={state} currentStatus={props.currentStatus} edit={true} />
            })}
          </Row>
          <Row>
            <Col lg="12">
              {props.currentStatus !== "in-review" ||
                props.match.url.includes("writer") ? (
                <Button onClick={addRow}>Add new answer</Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row style={{ paddingTop: "0.5rem" }}>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="dei">DEI:</label>
                <FormSelect onChange={handleChange} name="dei">
                  <option value="0" selected={!state.dei}>False</option>
                  <option value="1" selected={state.dei}>True</option>
                </FormSelect>
              </FormGroup>
            </Col>
          </Row>
          &nbsp;
          {/* Buttons */}
          <EditQuestionButtons
            {...props}
            sendToReview={sendToReview}
            publishQuestion={publishQuestion}
            addQuestion={addQuestion}
            toggleModal={toggleModal}
            currentUser={props.userData.user.name}
            questionCreator={state.creator}
            edit={edit.editing}
          />
        </>
      ) : (
        ""
      )}
      <br />
      <br />
      <Row>
        <Col lg="12">
          {state.internalNote && (
            <>
              <FormTextarea value={state.internalNote} disabled />
            </>
          )}
        </Col>
      </Row>
      <br />
      <Modal open={modal} toggle={toggleModal}>
        <ModalHeader>Note</ModalHeader>
        <ModalBody>
          <FormTextarea name="internalNote" onChange={handleNote} />
        </ModalBody>
        <ModalFooter>
          <Button outline theme="danger" onClick={sendBack}>
            Send back
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

const EditQuestionButtons = ({
  sendToReview,
  publishQuestion,
  toggleModal,
  addQuestion,
  currentStatus,
  match,
  currentUser,
  questionCreator,
  edit
}) => {
  let renderButtons = null;
  let reset = null;
  let sendBack = "";
  if (
    (match.url.includes("writer") ||
      match.url.includes("teacher") ||
      match.url.includes("lead+")) &&
    edit
  ) {
    if (currentStatus === "in-review") {
      if (match.url.includes("writer") || match.url.includes("lead+")) {
        renderButtons = (
          <Button theme="success" onClick={() => addQuestion(currentStatus)}>
            Update {currentStatus}
          </Button>
        );
      } else if (match.url.includes("teacher")) {
        // Can only edit, if the current user(logged in) is the same as the one who created the question.
        if (currentUser === questionCreator) {
          renderButtons = (
            <Button theme="success" onClick={() => addQuestion(currentStatus)}>
              Update {currentStatus}
            </Button>
          );
          // otherwise the update-review button will be disabled.
        } else {
          renderButtons = (
            <Button theme="success" disabled="true" onClick={() => addQuestion(currentStatus)}>
              Update {currentStatus}
            </Button>
          );
        }
      }
    } else if (currentStatus === "published") {
      if (match.url.includes("writer")) {
        renderButtons = (
          <Button theme="success" onClick={() => addQuestion(currentStatus)}>
            Update {currentStatus}
          </Button>
        );
      }
    } else {
      renderButtons = (
        <Button theme="success" onClick={() => addQuestion(currentStatus)}>
          Update {currentStatus}
        </Button>
      );
    }
  } else if (
    !["publish", "published", "in-review"].includes(currentStatus) &&
    !edit
  ) {
    renderButtons = (
      <Button onClick={() => sendToReview()}>Send To Review</Button>
    );
  } else if (
    (match.url.includes("writer") || match.url.includes("lead+")) &&
    !["publish", "published"].includes(currentStatus) &&
    !edit
  ) {
    renderButtons = (
      <Button onClick={() => publishQuestion("Publish")}>Publish</Button>
    );
  }
  if (edit) {
    reset = <Button onClick={() => window.location.reload()}>Reset</Button>;
  }

  if (
    ["in-review"].includes(currentStatus) &&
    (match.url.includes("writer") || match.url.includes("lead+")) &&
    !edit
  ) {
    sendBack = <Button onClick={toggleModal}>Send Back</Button>;
  }
  return (
    <Row>
      <Col lg="12" className="text-right">
        {reset}
        &nbsp;
        {renderButtons}
        &nbsp;
        {sendBack}
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  passages: state.passages,
  question: state.question,
  currentStatus: state.currentStatus,
  questionId: state.id,
  commonCore: state.commonCore
});

const mapActionToProps = dispatch => ({
  getPassages: bindActionCreators(getAllPassages, dispatch),
  getQuestionById: bindActionCreators(getQuestionId, dispatch),
  updateQuestion: bindActionCreators(updateQuestion, dispatch),
  clearPassages: bindActionCreators(clearPassages, dispatch),
  clearQuestions: bindActionCreators(clearQuestion, dispatch),
  publishQuestion: bindActionCreators(addQuestion, dispatch),
  setId: bindActionCreators(setId, dispatch),
  checkGrammar: bindActionCreators(checkGrammar, dispatch),
  resetGrammarMessages: bindActionCreators(resetGrammarMessages, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(EditQuestions);
