import React from "react";
import { Container, Col, Row } from "shards-react";
import "../../assets/loader.css";

const Loader = () => {
  return (
    <Container className="h-100">
      <Row className="h-100 justify-content-center align-items-center">
        <Col lg="12" className="text-center align-self-center">
          <div className="holder">
            <div className="preloader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Loader;
