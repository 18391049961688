import React from "react";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllGenres } from "../redux/actions/genre-actions";
import { getAllGrades } from "../redux/actions/grade-actions";
import { getAllSkills } from "../redux/actions/skill-actions";
import { getAllSkillGroups } from "../redux/actions/skill-groups-actions";
import { getAllQuestionTypes } from "../redux/actions/questionType-actions";

import { getAllPassageLengths } from "../redux/actions/passage-length-actions";
import { getAllBooks } from "../redux/actions/book-actions";
import {getCommonCore} from "../redux/actions/common-core-actions";
import { getCommonCoreIndependent } from "../redux/actions/common-core-independent-actions";

class LeadLayout extends React.Component {
  constructor(props) {
    super(props);
    // retrieve data
    this.props.getGenres();
    this.props.getGrades();
    this.props.getSkills();
    this.props.getSkillGroups();
    this.props.getQuestionTypes();
    this.props.getPassageLengths();
    this.props.getBooks();
    this.props.getCommonCore();
    this.props.getCommonCoreIndependent();
  }

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, { ...this.props });
    });

    return (
      <Container fluid>
        <Row>
          <MainSidebar sidebarType={"teacher"} />
          <Col
            className="main-content p-0"
            lg={{ size: 10, offset: 2 }}
            md={{ size: 9, offset: 3 }}
            sm="12"
            tag="main"
          >
            {<MainNavbar />}
            {children}
            {!this.props.noFooter && <MainFooter />}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    genre: state.genre,
    grades: state.grades,
    skills: state.skills,
    skillGroups: state.skillGroups,
    questionTypes: state.questionTypes,
    passageLength: state.passageLength,
    books: state.books,
    userData: state.userData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGenres: bindActionCreators(getAllGenres, dispatch),
    getGrades: bindActionCreators(getAllGrades, dispatch),
    getSkills: bindActionCreators(getAllSkills, dispatch),
    getSkillGroups: bindActionCreators(getAllSkillGroups, dispatch),
    getQuestionTypes: bindActionCreators(getAllQuestionTypes, dispatch),
    getPassageLengths: bindActionCreators(getAllPassageLengths, dispatch),
    getBooks: bindActionCreators(getAllBooks, dispatch),
    getCommonCore: bindActionCreators(getCommonCore, dispatch),
    getCommonCoreIndependent: bindActionCreators(getCommonCoreIndependent, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadLayout);
