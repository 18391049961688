import { getIndependentSkills } from "../../api/skills-api";

export const RETRIEVE_ALL_INDEPENDENT_SKILLS = "skills:retrieveAllIndependentSkills";

export function getAllIndependentSkills() {
  return dispatch => {
    getIndependentSkills()
      .then(data => {
        dispatch({
          type: RETRIEVE_ALL_INDEPENDENT_SKILLS,
          payload: [...data.data.data]
        });
      })
      .catch(err => {
        dispatch({
          type: RETRIEVE_ALL_INDEPENDENT_SKILLS,
          payload: []
        });
      });
  };
}
