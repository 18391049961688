import React from "react";
import { Col, FormGroup, FormInput } from "shards-react";


/**
 * @description handles
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PairingQuestionType = (props) => {
    const {onChange, row} = props;
    return (
      <>
                <Col
                    sm="5"
                    className={"form-group"}
                >
                    <FormGroup>
                      <label htmlFor="selectionValues">Selection Values</label>
                        <FormInput value={row.label} id={"selectionValues"} name="label" onChange={onChange} />
                    </FormGroup>

                </Col>
                <Col style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>

                  <i className={"material-icons"}>arrow_forward</i>
                </Col>
                <Col
                    sm="4"
                    className={"form-group"}
                >
                    <FormGroup>
                      <label htmlFor="pairingValues">Pairing Values</label>
                        <FormInput value={row.value}  name="value" id={"pairingValues"} onChange={onChange} />
                    </FormGroup>
                </Col>
        </>
    )
}

export default PairingQuestionType
