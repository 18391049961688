import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { authenticateDashboardUser, logoutUser } from "../redux/actions/user-actions";
import Loader from "../components/loader/Loader";

const DashboardAuth = props => {
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		if (!isLoading) {
			props.logoutUser();
      localStorage.removeItem("token");
      localStorage.removeItem("persist:root");
			setIsLoading(true);
			setTimeout(() => {
				const token = window.location.search.split("?token=")[1];
				// get user here before update
				props.authenticateDashboardUser(token, props.triggerNotification);
			}, 500);
		}
	}, []);

	useEffect(() => {
		if (props.userData.user) {
			setTimeout(() =>
        props.history.push("/external-creator/independent-questions/new")
        , 500);
		}
	}, [props.userData]);

	return <Loader />;
};

const mapStateToProps = state => ({
	userData: state.userData
});

const mapActionToProps = dispatch => ({
	authenticateDashboardUser: bindActionCreators(authenticateDashboardUser, dispatch),
	logoutUser: bindActionCreators(logoutUser, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(DashboardAuth);
