import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Col,
  Container,
  Row
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { getAllContentPacks } from "../redux/actions/content-pack-actions";
import { getAllIndependentQuestions } from "../redux/actions/independent-question-actions";
import Table from "../components/filterable-table/Table";
import IndependentQuestionsModal from "../components/modal/IndependentQuestionsModal";

const AllContentPacks = props => {
  const [showQuestionsPopup, setShowQuestionPopup] = useState(false);
  const [questionsToPreview, setQuestionsToPreview] = useState([]);

  useEffect(() => {
    props.getAllContentPacks(props.userData.user.id);
    props.getAllIndependentQuestions("published");
  }, []);

  const handleContentPackPreviewClick = id => {
    const contentPack = props.contentPacks.filter(contentPack => contentPack.id === id);
    if (contentPack.length) {
      const selectedContent = JSON.parse(contentPack[0].selectedContent);
      setShowQuestionPopup(true);
      const questionsToPreview = props.independentQuestions.filter(question => selectedContent.includes(question.id));
      setQuestionsToPreview(questionsToPreview);
    }
  }

  const renderPreviewPopup = () => {
    if (questionsToPreview.length) {
      return <IndependentQuestionsModal
          isOpen={showQuestionsPopup}
          questions={questionsToPreview}
          handleClose={() => {
            setShowQuestionPopup(false);
            setQuestionsToPreview([]);
          }} />;
    }
    return null;
  }

  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="My Content Packs"
          subtitle=""
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col lg="12">
					<Table
						filter={false}
						{...props}
						hideSearch={true}
						tableData={props.contentPacks}
            tableType="all-content-packs"
            handleContentPackPreviewClick={id => handleContentPackPreviewClick(id)}
						getTdProps={() => ({
							style: {
								display: 'flex',
								justifyContent: 'center'
							}
						})}
					/>
          {renderPreviewPopup()}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  userData: state.userData,
  contentPacks: state.contentPacks,
  independentQuestions: state.independentQuestions
});

const mapActionToProps = dispatch => ({
  getAllContentPacks: bindActionCreators(
    getAllContentPacks,
    dispatch
  ),
  getAllIndependentQuestions: bindActionCreators(
    getAllIndependentQuestions,
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(AllContentPacks);
