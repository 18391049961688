import { getAllUserSkills } from "../../api/user-skills-api";

export const getUserSkills = "userSkills:getUserSkills";
export const userSkillsDone = "userSkills:markTeacherDone";

export function retrieveUserSkills() {
  return dispatch => {
    getAllUserSkills()
      .then(data => {
        dispatch({
          type: getUserSkills,
          payload: data.data.data
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
}
