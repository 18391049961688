import { SET_ID, CLEAR_ID } from "../actions/id-actions";

export function idReducer(state = null, { type, payload }) {
  switch (type) {
    case SET_ID:
      return payload;
    case CLEAR_ID:
      return null;
    default:
      return state;
  }
}
