import React from "react";
import { Button, FormSelect } from "shards-react";
import { Link } from "react-router-dom";
import moment from "moment";

const filterMethod = (filter, row) => {
  let { id, value } = filter;
  value = value.toString().toLowerCase();
  if (row[id]) {
    return row[id]
      .toString()
      .toLowerCase()
      .includes(value);
  }
  return false;
}
const filterDisabled = (filter, row) => {
  let { id, value } = filter;
  value = value.toString().toLowerCase();
  let column = row[id] ? 'yes' : 'no';
  return column === value;
}
export default function (item, eventHandler = null, urls, userData, props) {
  const { url } = urls;
  //   assigned passage view
  let disabled = null;
  if (url.includes("assigned-passages")) {
    disabled = {
      Header: "Number of Questions Assigned",
      accessor: "numOfQuestionsAssigned"
    };
  }
  if (url.includes("disabled")) {
    disabled = {
      Header: "# of questions",
      accessor: "numOfQuestions"
    };
  }
  // validator for allowing publishing
  const handleIfPublishIsAllowed = rowItem => {
    let isValid = false;
    switch (rowItem.original.passageLength.id) {
      case 1:
        isValid = rowItem.original.numOfQuestions >= 6;
        break;
      case 2:
        isValid = rowItem.original.numOfQuestions >= 2;
        break;
      case 3:
        isValid = rowItem.original.numOfQuestions >= 8;
        break;
      case 4:
        isValid = rowItem.original.numOfQuestions >= 4;
        break;
      default:
        break;
    }

    return isValid;
  };

  //   checks if it is writer
  let isWriter = url.includes("writer");
  // creates links for different view types
  let editQuestionLink = "/writer/questions/edit/";
  if (url.includes("lead+")) {
    editQuestionLink = "/lead+/questions/edit/";
  } else if (url.includes("external-creator")) {
    editQuestionLink = "/external-creator/questions/edit/";
  }

  // viewer links
  let viewerLink = "";
  switch (url) {
    case "/lead/assign-passages":
      viewerLink = "/lead/viewer/passages/publish";
      break;
    case "/lead+/assign-passages":
      viewerLink = "/lead+/viewer/passages/publish";
      break;
    case "/teacher/assigned-passages/published":
      viewerLink = "/teacher/viewer/passages/publish";
      break;
    case "/writer/assigned-passages/published":
      viewerLink = "/writer/viewer/passages/publish";
      break;
    case "/lead/passages/published":
      viewerLink = "/lead/viewer/passages/publish";
      break;
    case "/lead+/passages/published":
      viewerLink = "/lead+/viewer/passages/publish";
      break;
    case "/lead/passages/disabled":
      viewerLink = "/lead/viewer/passages/publish";
      break;
    case "/lead+/passages/disabled":
      viewerLink = "/lead+/viewer/passages/publish";
      break;
    case "/lead+/passages/all/disabled/1":
      viewerLink = "/lead+/viewer/passages/publish";
      break;
    case "/writer/passages/all/disabled/1":
      viewerLink = "/writer/passages/edit";
      break;
    default:
      break;
  }

  if (url.includes("teacher")) {
    editQuestionLink = "/teacher/questions/edit/";
  } else if (url.includes("lead+")) {
    editQuestionLink = "/lead+/questions/edit/";
  } else if (url.includes("lead")) {
    editQuestionLink = "/lead/questions/edit/";
  }
  if (url.includes("independent-questions")) {
    editQuestionLink = editQuestionLink.replace(
      "questions",
      "independent-questions"
    );
  }
  switch (item) {
    case "work-in-progress":
      return [
        {
          Header: "ID",
          accessor: "id"
        },
        {
          Header: "Teacher Name",
          accessor: "username",
          filterMethod
        },
        {
          Header: "Passage Name",
          accessor: "passage.name",
          filterMethod
        },
        {
          Header: "Number of assigned questions",
          accessor: "numOfQuestionsAssigned",
          filterMethod
        },
        {
          Header: "Number of approved questions",
          accessor: "numOfQuestionsApproved",
          filterMethod
        },
        {
          Header: "Actions",
          accessor: "",
          filterable: false,
          sortable: false,
          Cell: row => (
            <div style={{ textAlign: "" }}>
              <Button
                outline
                theme="success"
                onClick={() => eventHandler(row.row.id)}
              >
                Done
              </Button>
            </div>
          )
        }
      ];
    case "independent-work-in-progress":
      return [
        {
          Header: "ID",
          accessor: "id"
        },
        {
          Header: "Teacher Name",
          accessor: "username",
          filterMethod
        },
        {
          Header: "Grade",
          accessor: "gradeId",
          filterMethod
        },
        {
          Header: "Skill Name",
          accessor: "skill.name",
          filterMethod
        },
        {
          Header: "Number of assigned questions",
          accessor: "numOfQuestionsAssigned",
          filterMethod
        },
        {
          Header: "Number of approved questions",
          accessor: "numOfQuestionsApproved",
          filterMethod
        }
      ];
    case "passages":
      if (url.includes("assigned-passages")) {
        return [
          {
            Header: "ID",
            accessor: "id"
          },
          {
            Header: "Passage Title",
            accessor: "name",
            filterMethod
          },
          {
            Header: "Length",
            accessor: "passageLength.name",
            filterMethod
          },
          {
            Header: "Genre",
            accessor: "genre.name",
            filterMethod
          },
          {
            Header: "Grade",
            accessor: "grade.name" || "gradeName",
            filterMethod
          },
          {
            Header: "Reading",
            accessor: "readingLevel",
            filterMethod
          },
          disabled,
          {
            Header: "Disabled",
            accessor: "disabled",
            Cell: row => {
              let isDisabled = "No";
              if (row.original.disabled === 1) {
                isDisabled = "Yes";
              } else if (row.original.disabled === 0) {
                isDisabled = "No";
              }
              return <div style={{ textAlign: "" }}>{isDisabled}</div>;
            },
            filterMethod: filterDisabled
          },
          {
            Header: "Actions",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: row => (
              <div style={{ textAlign: "" }}>
                <Link to={`${viewerLink}/${row.row.id}`}>
                  <Button outline theme="info">
                    View
                  </Button>
                </Link>
              </div>
            )
          }
        ];
      } else if (disabled !== null) {
        return [
          {
            Header: "ID",
            accessor: "id"
          },
          {
            Header: "Passage Title",
            accessor: "name",
            filterMethod
          },
          {
            Header: "Length",
            accessor: "passageLength.name",
            filterMethod
          },
          {
            Header: "Genre",
            accessor: "genre.name",
            filterMethod
          },
          {
            Header: "Grade",
            accessor: "grade.name" || "gradeName",
            filterMethod
          },
          {
            Header: "Reading",
            accessor: "readingLevel",
            filterMethod
          },
          disabled,
          {
            Header: "Disabled",
            accessor: "disabled",
            Cell: row => {
              let isDisabled = "No";
              if (row.original.disabled === 1) {
                isDisabled = "Yes";
              } else if (row.original.disabled === 0) {
                isDisabled = "No";
              }
              return <div style={{ textAlign: "" }}>{isDisabled}</div>;
            },
            filterMethod: filterDisabled
          },
          {
            Header: "Actions",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: row => (
              <div style={{ textAlign: "" }}>
                <Link to={`${viewerLink}/${row.row.id}`}>
                  <Button outline theme="info">
                    {isWriter ? "Edit" : "View"}
                  </Button>
                </Link>
                {handleIfPublishIsAllowed(row) &&
                  (url.includes("writer") || url.includes("lead+")) ? (
                  <Button outline onClick={() => eventHandler(`${row.row.id}`)}>
                    Publish
                  </Button>
                ) : (
                  ""
                )}
              </div>
            )
          }
        ];
      } else {
        return [
          {
            Header: "ID",
            accessor: "id"
          },
          {
            Header: "Passage Title",
            accessor: "name",
            filterMethod
          },
          {
            Header: "Length",
            accessor: "passageLength.name",
            filterMethod
          },
          {
            Header: "Genre",
            accessor: "genre.name",
            filterMethod
          },
          {
            Header: "Grade",
            accessor: "grade.name" || "gradeName",
            filterMethod
          },
          {
            Header: "Reading",
            accessor: "readingLevel",
            filterMethod
          },
          {
            Header: "Disabled",
            accessor: "disabled",
            Cell: row => {
              let isDisabled = "No";
              if (row.original.disabled === 1) {
                isDisabled = "Yes";
              } else if (row.original.disabled === 0) {
                isDisabled = "No";
              }
              return <div style={{ textAlign: "" }}>{isDisabled}</div>;
            },
            filterMethod: filterDisabled
          },
          {
            Header: "Actions",
            accessor: "",
            filterable: false,
            sortable: false,
            Cell: row => (
              <div style={{ textAlign: "center" }}>
                {isWriter ? (
                  <>
                    <Link to={`/writer/passages/edit/${row.row.id}`}>
                      <Button outline theme="info">
                        Edit
                      </Button>
                    </Link>
                    &nbsp;
                  </>
                ) : (
                  <Link to={`${"/teacher/viewer/passages/publish"}/${row.row.id}`}>
                    <Button outline theme="info">
                      View
                    </Button>
                  </Link>
                )}
              </div>
            )
          }
        ];
      }

    case "manage-users":
      return [
        {
          Header: "Name",
          accessor: "name",
          filterMethod
        },
        {
          Header: "Email",
          accessor: "email",
          filterMethod
        },
        {
          Header: "Role",
          accessor: "roleId",
          filterMethod,
          Cell: row => (
            <FormSelect onChange={props.handleRoleSelection} disabled={!props.match.url.includes("lead")} defaultValue={row.row.roleId} name={row.original.id}>
              <option>Choose</option>
              <>
                <option value="1">Writer</option>
                <option value="2">Lead</option>
                <option value="3">Teacher</option>
                <option value="4">Lead/Reviewer</option>
              </>
            </FormSelect>
          )
        }
        // {
        //   Header: "Actions",
        //   accessor: "",
        //   filterable: false,
        //   sortable: false,
        //   Cell: row => (
        //     <div style={{ textAlign: "center" }}>
        //       <Button outline theme="danger">
        //         Delete
        //       </Button>
        //     </div>
        //   )
        // }
      ];
    case "questions":
      return [
        {
          Header: "ID",
          accessor: "id"
        },
        {
          Header: "Question",
          accessor: "question",
          filterMethod
        },
        {
          Header: "Type",
          accessor: "questionType.name",
          filterMethod
        },
        {
          Header: "Skill",
          accessor: "skill.name",
          filterMethod
        },
        {
          Header: "Difficulty",
          accessor: "difficulty",
          filterMethod
        },
        {
          Header: "Passage",
          accessor: "passage.name",
          filterMethod
        },
        {
          Header: "Literal/Inferential",
          accessor: "skill.tier.name",
          filterMethod
        },
        {
          Header: "Disabled",
          accessor: "disabled",
          Cell: row => {
            let isDisabled = "No";
            if (row.original.disabled === 1) {
              isDisabled = "Yes";
            } else if (row.original.disabled === 0) {
              isDisabled = "No";
            }
            return <div style={{ textAlign: "" }}>{isDisabled}</div>;
          },
          filterMethod: filterDisabled,
        },
        {
          Header: "Actions",
          accessor: "",
          filterable: false,
          sortable: false,
          Cell: row => (
            <div style={{ textAlign: "center" }}>
              <Link
                to={{
                  pathname: `${editQuestionLink}${row.row.id}`,
                  id: row.row.id
                }}
              >
                <Button outline theme="info">
                  {url.includes("/teacher/questions/all/in-review") ||
                    url.includes("/lead+/questions/all/published") ||
                    url.includes("/teacher/questions/all/published")
                    ? "View"
                    : "Edit"}
                </Button>
              </Link>
            </div>
          )
        }
      ];
    case "independent-questions":
      return [
        {
          Header: "ID",
          accessor: "id"
        },
        {
          Header: "Name",
          accessor: "name",
          filterMethod,
          show: userData.user.role === 'External Creator'
        },
        {
          Header: "Question",
          accessor: "question",
          filterMethod,
          show: userData.user.role !== 'External Creator'
        },
        {
          Header: "Type",
          accessor: "questionType.name",
          filterMethod
        },
        {
          /**
           * @todo
           * Column labels ideally have to be metadata that each Group/District would have
           */
          Header: userData.user.role === 'External Creator' ?
            "Course Code" : "Skill",
          accessor: "skill.name",
          show: url.includes("/published"),
          filterMethod
        },
        {
          Header: "Grade",
          accessor: "gradeId",
          show: userData.user.role !== 'External Creator',
          filterMethod
        },
        {
          Header: "Standards",
          accessor: "standards.description",
          show: url.includes("/published"),
          filterMethod
        },
        {
          Header: "Created By",
          accessor: "educationToolData.userName",
          filterMethod,
          show: url.includes("/published") || userData.user.role === 'External Creator'
        },
        {
          Header: "Disabled",
          accessor: "disabled",
          Cell: row => {
            let isDisabled = "No";
            if (row.original.disabled === 1) {
              isDisabled = "Yes";
            } else if (row.original.disabled === 0) {
              isDisabled = "No";
            }
            return <div style={{ textAlign: "" }}>{isDisabled}</div>;
          },
          filterMethod: filterDisabled
        },
        {
          Header: "Actions",
          accessor: "",
          filterable: false,
          sortable: false,
          Cell: row => {
            const { user } = userData;
            let buttonText = 'Edit';
            if (
              (user.role === 'External Creator' &&
                row.row._original.educationToolData &&
                user.id !== row.row._original.educationToolData.userId) ||
              (url.includes("/teacher/questions/all/in-review") ||
                url.includes("/lead+/independent-questions/all/published") ||
                url.includes("/teacher/independent-questions/all/published"))
            ) {
              buttonText = 'View';
            }
            return (
              <div style={{ textAlign: "center" }}>
                <Link
                  to={{
                    pathname: `${editQuestionLink}${row.row.id}`,
                    id: row.row.id
                  }}
                  className="mr-1"
                >
                  <Button outline theme="info">
                    {buttonText}
                  </Button>
                </Link>
                {
                  url.includes('/published') &&
                  userData.user.role === 'External Creator' &&
                  <Button outline theme="info" onClick={() => props.handleDuplicateClick(row.row.id)}>
                    Duplicate
                  </Button>
                }
              </div>
            );
          }
        }
      ];
    case "review-questions":
      return [
        {
          Header: "Question",
          accessor: "question",
          filterMethod
        },
        {
          Header: "Passage",
          accessor: "passage.name",
          filterMethod
        },
        {
          Header: "Submitted by",
          accessor: "userName",
          filterMethod
        },
        {
          Header: "Submitted at",
          accessor: "updatedAt",
          Cell: row => <span>{moment(row.row.updatedAt).format("LLLL")}</span>
        },
        {
          Header: "Actions",
          accessor: "id",
          filterable: false,
          sortable: false,
          Cell: row => {
            let linkViewType = "writer";
            console.log(url);
            if (url.includes("/lead+")) {
              linkViewType = "lead+";
            }
            return (
              <div style={{ textAlign: "center" }}>
                <Link
                  to={`/${linkViewType}/questions/edit/${row.row.id}/review`}
                >
                  <Button outline theme="info" id="review">
                    Review
                  </Button>
                </Link>
              </div>
            );
          }
        }
      ];
    case "review-independent-questions":
      return [
        {
          Header: "Question",
          accessor: "question",
          filterMethod
        },
        {
          Header: "Skill",
          accessor: "skill.name",
          filterMethod
        },
        {
          Header: "Submitted by",
          accessor: "userName",
          filterMethod
        },
        {
          Header: "Submitted at",
          accessor: "updatedAt",
          Cell: row => <span>{moment(row.row.updatedAt).format("LLLL")}</span>
        },
        {
          Header: "Actions",
          accessor: "id",
          filterable: false,
          sortable: false,
          Cell: row => {
            let linkViewType = "writer";
            console.log(url);
            if (url.includes("/lead+")) {
              linkViewType = "lead+";
            }
            return (
              <div style={{ textAlign: "center" }}>
                <Link
                  to={`/${linkViewType}/independent-questions/edit/${row.row.id}/review`}
                >
                  <Button outline theme="info" id="review">
                    Review
                  </Button>
                </Link>
              </div>
            );
          }
        }
      ];
    case "skills":
      return [
        {
          Header: "Name",
          accessor: "name",
          filterMethod
        }
      ];
    case "assigned-skills":
      return [
        {
          Header: "Name",
          accessor: "skill.name",
          filterMethod
        },
        {
          Header: "Grade",
          accessor: "gradeId",
          filterMethod
        },
        {
          Header: "# of Questions Approved",
          accessor: "numOfQuestionsApproved",
          filterMethod
        },
        {
          Header: "# of Questions Assigned",
          accessor: "numOfQuestionsAssigned",
          filterMethod
        }
      ];
    case "books":
      return [
        {
          Header: "ID",
          accessor: "id"
        },
        {
          Header: "Title",
          accessor: "title",
          filterMethod
        },
        {
          Header: "Author",
          accessor: "author",
          filterMethod
        },
        {
          Header: "# of Passages",
          accessor: "noOfPassages",
          filterMethod
        },
        {
          Header: "Source",
          accessor: "publisherRights",
          filterMethod
        },
        {
          Header: "Actions",
          accessor: "id",
          filterable: false,
          sortable: false,
          Cell: row => (
            <div style={{ textAlign: "center" }}>
              <Link to={`/writer/books/edit/${row.row.id}`}>
                <Button theme="info">Edit</Button>
              </Link>
            </div>
          )
        }
      ];
    case "content-pack-questions":
      return [
        {
          Header: "Name",
          accessor: "name",
          filterMethod,
          Cell: row => <div style={{ alignItems: 'center', display: 'flex' }}>{row.row.name}</div>
        },
        {
          Header: "Question Type",
          accessor: "questionType.name",
          filterMethod,
          Cell: row => <div style={{ textAlign: 'center' }}>{row.row['questionType.name']}</div>
        },
        {
          Header: "Course Code",
          accessor: "skill.name",
          filterMethod,
          Cell: row => <div style={{ textAlign: 'center' }}>{row.row['skill.name']}</div>
        },
        {
          Header: "Standard",
          accessor: "standards.description",
          filterMethod,
          Cell: row => <div style={{ textAlign: 'center' }}>{row.row['standards.description']}</div>
        },
        {
          Header: "Created By",
          accessor: "educationToolData.userName",
          filterMethod,
          Cell: row => <div style={{ textAlign: 'center' }}>{row.row['educationToolData.userName']}</div>
        },
        {
          Header: "Actions",
          accessor: "id",
          filterable: false,
          sortable: false,
          Cell: row => {
            const { isSelected } = row.row._original;
            return (
              <div style={{ textAlign: "center" }}>
                <Button
                  theme="light"
                  onClick={() => props.handlePreview(row.row.id)}>
                  <i className="material-icons mr-1">search</i>
                  Preview
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  theme={isSelected ? 'danger' : 'success'}
                  onClick={() => props.handleSelect(row.row.id)}>
                  {isSelected ?
                    (<>
                      <i className="material-icons mr-1">close</i>
                      Unselect
                    </>) :
                    (<>
                      <i className="material-icons mr-1">check</i>
                      Select
                    </>)
                  }
                </Button>
              </div>
            );
          }
        }
      ];
    case "all-content-packs":
      return [
        {
          Header: "Name",
          accessor: "name",
          filterMethod
        },
        {
          Header: "Created At",
          accessor: "createdAt",
          filterMethod
        },
        {
          Header: "Num Of Questions",
          accessor: "numOfQuestions",
          filterMethod
        },
        {
          Header: "Actions",
          accessor: "id",
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <div style={{ textAlign: "center" }}>
                <Button theme="primary" onClick={() => props.handleContentPackPreviewClick(row.row.id)}>
                  Preview
                </Button>
              </div>
            );
          }
        }
      ];
    default:
      return [];
  }
}
