import Axios from "axios";

const api = require("../api/api-config");

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`
};

export function uploadBase64(data) {
  console.log('/upload');
  return new Promise((resolve, reject) => {
    Axios.post(`${api.api}/upload/base64`, {
      data
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}