import { getGrades } from "../../api/grade-api";

export const RETRIEVE_ALL_GRADES = 'passage:retrieveAllGrades';

export function getAllGrades(genres = []) {
    return (dispatch) => {
        getGrades()
        .then((data) => {
            dispatch({
                type: RETRIEVE_ALL_GRADES,
                payload: [...data.data]
            });
        })
        .catch((err) => {
            dispatch({
                type: RETRIEVE_ALL_GRADES,
                payload: genres
            });
        });
    }
}