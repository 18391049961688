import React from "react";
import { Container, Row, Col } from "shards-react";
class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  componentDidCatch(err, next) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorView {...this.props} />;
    }
    return this.props.children;
  }
}

const ErrorView = props => {
  return (
    <Container style={{ height: "78vh" }}>
      <Row className="h-100 center-align justify-content-center align-items-center">
        <Col lg="12">
          <i class="material-icons" style={{ fontSize: "124px" }}>
            error_outline
          </i>
          <p style={{ fontSize: "24px" }}>Something went wrong!</p>
          <p>
            Seems like there is an issue with the site. Try refreshing the page,
            if that doesn't work try logging in again.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorBoundary;
