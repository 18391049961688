import React from "react";
import { Container, Row, Col, Button } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";
import GrammarOutput from "../components/grammar-output/GrammarOutput";

import { connect } from "react-redux";
import {
  addPassage,
  updatePassage,
  publishFromLocal
} from "../redux/actions/passage-actions";
import { bindActionCreators } from "redux";
import { setStatus } from "../redux/actions/status-actions";
import { setId } from "../redux/actions/id-actions";
import { checkGrammar, resetGrammarMessages } from "../redux/actions/grammar-actions";

class AddNewPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
      name: null,
      genreId: null,
      gradeId: null,
      readingLevel: null,
      passageLengthId: null,
      status: "Draft",
      dei: 0,
      citationType: 0
    };
  }

  componentWillMount() {
    this.props.setStatus("draft");
    this.props.setPublishId(null);
  }

  componentDidMount() {
    this.props.resetGrammarMessages();
  }

  componentDidUpdate() {
    console.log(this.props);
  }

  handleChange = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  };

  handleQuillChange = value => {
    this.setState({
      text: value
    });
  };

  handleCheckGrammarClick = () => {
    this.props.checkGrammar(this.state.text);
  }

  saveDraft = (draft = "draft") => {
    if (
      this.state.text !== null &&
      this.state.text !== "" &&
      this.state.name !== null &&
      this.state.name !== ""
    ) {
      if (draft === "publish") {
        console.log(this.state);
        if (this.props.publishedId !== null) {
          publishFromLocal(this.props.publishedId)
            .then(data => {
              this.props.triggerNotification("Succesfully published");
              this.props.setStatus("publish");
              setTimeout(() => {
                this.props.history.push(
                  `/writer/passages/edit/${data.data.data[0].id}`
                );
              }, 500);
            })
            .catch(err => {
              this.props.triggerNotification("Error publishing");
            });
        } else {
          this.props.triggerNotification("Please save as draft first");
        }
      } else {
        if (this.props.publishedId !== null) {
          this.props.onUpdatePassage(
            "local",
            this.state,
            this.props.publishedId,
            this.props.triggerNotification
          );
        } else {
          this.props.onAddPassage(
            "local",
            this.state,
            this.props.triggerNotification
          );
        }
      }
    } else {
      this.props.triggerNotification("Empty entry", "error", "tr");
    }
  };

  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Add New Passage"
            subtitle=""
            className="text-sm-left"
          />
          <Col sm="8" className="text-right">
            <Button onClick={() => window.location.reload()}>
              Start new passage
            </Button>
          </Col>
        </Row>

        <Row>
          {/* Editor */}
          <Col lg="7" md="12">
            <Editor
              showTitle
              value={this.state.text}
              handleChange={this.handleChange}
              handleQuillChange={this.handleQuillChange}
              toolbar={[['bold', 'italic'], ['image']]}
            />
            <GrammarOutput />
          </Col>
          {/* Sidebar Widgets */}
          <Col lg="5" md="12">
            <SidebarActions
              status={"draft"}
              saveDraft={this.saveDraft}
              handleCheckGrammarClick={this.handleCheckGrammarClick}
            />
            <SidebarCategories
              isEdit={false}
              version={"local"}
              {...this.props}
              currentState={this.state}
              handleSideBarCategories={this.handleChange}
              title="Filters"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    passageId: state.passages,
    publishedId: state.id,
    currentStatus: state.currentStatus
  };
};

const mapActionsToProps = dispatch => {
  return {
    onAddPassage: bindActionCreators(addPassage, dispatch),
    onUpdatePassage: bindActionCreators(updatePassage, dispatch),
    setStatus: bindActionCreators(setStatus, dispatch),
    setPublishId: bindActionCreators(setId, dispatch),
    checkGrammar: bindActionCreators(checkGrammar, dispatch),
    resetGrammarMessages: bindActionCreators(resetGrammarMessages, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(AddNewPost);
