import Axios from "axios";

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`
};

const api = require("../api/api-config");

export function getBooks() {
  return new Promise((resolve, reject) => {
    Axios.get(api.api + "/books")
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function putBook(book, id) {
  return new Promise((resolve, reject) => {
    Axios.put(api.api + `/books/${id}`, book)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function postBook(books) {
  return new Promise((resolve, reject) => {
    Axios.post(api.api + "/books", books)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
