import {
  assignPassage,
  getPassageById,
  getPassages,
  postPassage,
  putPassage
} from "../../api/passages-api";
import { SET_ID } from "./id-actions";
import { SET_STATUS } from "./status-actions";

export const ADD_PASSAGE = "passage:addPassage";
export const UPDATE_PASSAGE = "passage:updatePassage";
export const RETRIEVE_ALL_PASSAGES = "passage:retrieveAllPassages";
export const GET_PASSAGE = "passage:getPassage";
export const CLEAR_PASSAGE = "passage:clearPassage";
export const GET_PASSAGE_BY_ID = "passage:getPassageById";
export const ASSIGN_PASSAGE = "passage:assignPassage";

function validCitation(passages, notificationSystem) {
  const currentYear = new Date().getFullYear();
  const title = passages.citationTitle;
  const author = passages.citationAuthor;
  const topic = passages.citationTopic;
  const type = passages.citationType;
  const year = passages.citationYear;
  if ((title || author || topic || year) && (!title || !author || !topic || !year)) {
    notificationSystem("Please fill-in all citation attributes", "error", "tr");
    return false;
  }
  if (title && author && topic && year) {
    if (title.length + author.length > 130) {
      notificationSystem("Title and Author combined cannot be more than 130 characters in length", "error", "tr");
      return false
    } else if (isNaN(parseInt(year)) || parseInt(year) > currentYear) {
      notificationSystem("Please make sure year field has a valid year", "error", "tr");
      return false;
    } else if (finalCitation(title, author, topic, year, type).length > 180) {
      notificationSystem("Citation cannot be more than 180 characters in length", "error", "tr");
      return false;
    }
  }
  return true
}

function finalCitation(title, author, topic, year, type) {
  let finalCitation = ''
  finalCitation = (title && author && topic && (type !== 1
    ? `This is an excerpt from ${title} by ${author}, written in ${year}. It is about ${topic}.`
    : `This is the complete story of ${title} by ${author}, written in ${year}. It is about ${topic}.`))
  return finalCitation
}
function formatCitation(passages) {
  const passage = Object.assign({}, passages);
  const title = passage.citationTitle;
  const author = passage.citationAuthor;
  const topic = passage.citationTopic;
  const type = parseInt(passage.citationType);
  const year = passages.citationYear;
  passage.citation = finalCitation(title, author, topic, year, type)
  delete passage.citationTitle;
  delete passage.citationAuthor;
  delete passage.citationTopic;
  delete passage.citationType;
  delete passage.citationYear;
  return passage;
}

export function assignPassages(passages = [], notificationSystem) {
  return dispatch => {
    assignPassage(passages)
      .then(data => {
        notificationSystem("Assigned passage successfully", "success", "tr");
        dispatch({
          type: ASSIGN_PASSAGE,
          payload: passages
        });
      })
      .catch(err => {
        console.log(err.response);
        notificationSystem(err.response.data.details, "error", "tr");
      });
  };
}

export function publishFromLocal(localPassageId) {
  return postPassage({ localPassageId }, "live");
}

export function addPassage(
  endpoint = "local",
  passages = [],
  notificationSystem
) {
  return dispatch => {
    console.log(endpoint);
    if (validCitation(passages, notificationSystem)) {
      passages = formatCitation(passages);
    } else {
      return;
    }
    postPassage(passages, endpoint)
      .then(data => {
        console.log(data);
        if (passages.status === "Draft") {
          notificationSystem("Passage saved as a draft", "success", "tr");
        } else {
          notificationSystem("Passage added", "success", "tr");
        }
        if (endpoint === "local") {
          console.log("status set");
          dispatch({
            type: SET_STATUS,
            payload: "draft"
          });
        } else {
          dispatch({
            type: SET_STATUS,
            payload: "published"
          });
        }
        const id = data.data.data.id;
        dispatch({
          type: SET_ID,
          payload: id
        });

        dispatch({
          type: ADD_PASSAGE,
          payload: data.data.data
        });
      })
      .catch(err => {
        console.log(err);
        notificationSystem(
          "The following are required when submitting a passage \n Genre, Length, Grade, Reading Level",
          "error",
          "tr"
        );
      });
  };
}

export function clearPassages() {
  return {
    type: CLEAR_PASSAGE,
    payload: []
  };
}

export function retrievePassageById(status, id) {
  let endpoint = "local";
  if (["published", "publish"].includes(status)) {
    endpoint = "live";
  }
  return dispatch => {
    getPassageById(endpoint, id)
      .then(data => {
        console.log(data.data.data);
        dispatch({
          type: GET_PASSAGE_BY_ID,
          payload: [{ ...data.data.data }]
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
}

export function getAllPassages(status, isAssigned) {
  let endpoint = "local";
  if (status === "published") {
    endpoint = "live";
  } else if (status === "in-review") {
    endpoint += "/?status=in%20review";
  } else if (status === "draft") {
    endpoint += "/?status=Draft";
  } else if (status === "disabled") {
    endpoint = "live?disabled=1&pending=1";
  }
  if (isAssigned) {
    return dispatch => {
      getPassages(endpoint, isAssigned)
        .then(data => {
          dispatch({
            type: RETRIEVE_ALL_PASSAGES,
            payload: data.data.data
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  } else {
    return dispatch => {
      getPassages(endpoint)
        .then(data => {
          dispatch({
            type: RETRIEVE_ALL_PASSAGES,
            payload: data.data.data
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  }
}

export function updatePassage(status, passages = {}, id, notificationSystem) {
  let endpoint = "local";
  if (
    status === "published" ||
    status === "publish" ||
    status === "publishToGame"
  ) {
    endpoint = "live";
  }
  return dispatch => {
    if (validCitation(passages, notificationSystem)) {
      passages = formatCitation(passages);
    } else {
      return;
    }
    putPassage(endpoint, passages, id)
      .then(data => {
        if (status === "publishToGame") {
          notificationSystem("Published successfully", "success", "tr");
        } else {
          notificationSystem("Updated sucessfully", "success", "tr");
        }
        console.log(data);
        dispatch({
          type: UPDATE_PASSAGE,
          payload: passages
        });
      })
      .catch(err => {
        console.log(err.response);
        if (err.response.data.status === 500) {
          notificationSystem(
            "Error updating - nothing new added",
            "error",
            "tr"
          );
        } else {
          notificationSystem("Error updating", "error", "tr");
        }
      });
  };
}
