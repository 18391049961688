import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Col,
  Container,
  FormGroup,
  FormInput,
  Row
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { publishContentPack } from "../redux/actions/content-pack-actions";
import { getAllIndependentQuestions } from "../redux/actions/independent-question-actions";
import Table from "../components/filterable-table/Table";
import IndependentQuestionsModal from '../components/modal/IndependentQuestionsModal';
import { setId } from "../redux/actions/id-actions";


const AddContentPack = props => {
  const [contentPack, setContentPack] = useState({
    name: ''
  });
  const [independentQuestions, setIndependentQuestions] = useState(props.independentQuestions);
  const [showQuestionPopup, setShowQuestionPopup] = useState(false);
  const [questionModalData, setQuestionModalData] = useState(null);

  useEffect(() => {
    props.getIndependentQuestions('published');
    return () => {
      props.setId(null);
    }
  }, []);

  useEffect(() => {
    setIndependentQuestions(props.independentQuestions);
  }, [props.independentQuestions]);

  useEffect(() => {
    if (props.contentPackId) {
      props.history.push(`/external-creator/content-pack/all`);
    }
  }, [props.contentPackId]);

  const handleSelect = questionId => {
    const newIndependentQuestions = independentQuestions.map(item => {
      if (item.id === questionId) {
        item = {
          ...item,
          isSelected: !item.isSelected
        }
      }
      return item;
    });
    setIndependentQuestions(newIndependentQuestions);
  }

  const handlePreview = questionId => {
    const question = independentQuestions.filter(item => item.id === questionId);
    setQuestionModalData(question[0]);
    setShowQuestionPopup(true);
  }

  const handleResetQuestionSelection = () => {
    const newIndependentQuestions = independentQuestions.map(item => {
      item = {
        ...item,
        isSelected: false
      }
      return item;
    });
    setIndependentQuestions(newIndependentQuestions);
  }

  const handlePublishContentPack = () => {
    if (handleValidateFields()) {
      const request = {
        ...contentPack,
        selectedContent: independentQuestions
          .filter(item => item.isSelected)
          .map(item => item.id)
      }
      props.publishContentPack(request, props.triggerNotification);
    }
  }

  const handleValidateFields = () => {
    const selectedQuestions = independentQuestions.filter(item => item.isSelected);
    let isValid = true;
    if (!contentPack.name.trim()) {
      props.triggerNotification(
        "Please, give a name for your Content Pack",
        "error"
      );
      isValid = false;
    }
    if (!selectedQuestions.length) {
      props.triggerNotification(
        "Please, select at least one question to publish your Content Pack",
        "error"
      );
      isValid = false;
    }
    return isValid;
  }

  const renderPreviewPopup = () => {
    if (questionModalData) {
      return <IndependentQuestionsModal
        isOpen={showQuestionPopup}
        questions={[questionModalData]}
        handleClose={() => {
          setShowQuestionPopup(false);
          setQuestionModalData(null);
        }} />;
      
    }
    return null;
  }

  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add New Content Pack"
          subtitle=""
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="questions">Content Pack Name:</label>
            <FormInput value={contentPack.name} onChange={e => setContentPack({
              ...contentPack,
              name: e.target.value
            })} />
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <label>Select questions to your Content Pack:</label>
            <br />
            <Table
              filter={false}
              {...props}
              hideSearch={true}
              tableData={independentQuestions}
              tableType="content-pack-questions"
              handleSelect={handleSelect}
              handlePreview={handlePreview}
              getTdProps={(state, rowInfo, column) => {
                if (column.Cell) {
                  return {
                    style: {
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center'
                    }
                  }
                }
                return {}
              }}
              getTrProps={(state, rowInfo) => {
                if (rowInfo) {
                  return {
                    style: rowInfo.row._original.isSelected ?
                      { background: '#27ae60', color: '#fff' } :
                      {}
                  }
                }
                return {}
              }}
            />
            {renderPreviewPopup()}
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <label>
              {`${independentQuestions.filter(question => question.isSelected).length} Questions Selected`}
            </label>
          </FormGroup>
        </Col>
        <Col lg={12} className="text-right">
          <Button theme="danger" onClick={() => handleResetQuestionSelection()}>Reset Question Selection</Button>
          <Button theme="primary" className="ml-1" onClick={() => handlePublishContentPack()}>Publish Content Pack</Button>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  contentPackId: state.id,
  userData: state.userData,
  independentQuestions: state.independentQuestions
});

const mapActionToProps = dispatch => ({
  setId: bindActionCreators(setId, dispatch),
  getIndependentQuestions: bindActionCreators(
    getAllIndependentQuestions,
    dispatch
  ),
  publishContentPack: bindActionCreators(
    publishContentPack,
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(AddContentPack);
