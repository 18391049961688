export const SET_ID = "id:setId";
export const CLEAR_ID = "id:clearId";

export function setId (id) {
	return  {
		type: SET_ID,
		payload: id
	}
}

export function clearId () {
	return {
		type: CLEAR_ID,
		payload: null
	}
}