import Axios from "axios";

const api = require("../api/api-config");

export function getSkills(isAssigned) {
  Axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };
  return new Promise((resolve, reject) => {
    Axios.get(api.api + "/skills", {
      params: { isAssigned }
    })
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function assignSkills(skills) {
  Axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };
  return new Promise((resolve, reject) => {
    Axios.post(api.api + "/skills/assign", skills)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getIndependentSkills() {
  Axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };
  return new Promise((resolve, reject) => {
    Axios.get(api.api + "/skills/independent")
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

// export function getSkills() {
//     return new Promise((resolve, reject) => {

//     });
// }
// export function getSkills() {
//     return new Promise((resolve, reject) => {

//     });
// }
// export function getSkills() {
//     return new Promise((resolve, reject) => {

//     });
// }
