import { RETRIEVE_ALL_QUESTIONTYPES } from "../actions/questionType-actions";



export default function questionTypeReducer(state = [], {type, payload}) {
    switch (type) {
        case RETRIEVE_ALL_QUESTIONTYPES:
            return payload;
        default:
            return state;
    }
}
