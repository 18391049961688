import { getGenres } from "../../api/genre-api";

export const RETRIEVE_ALL_GENRES = 'passage:retrieveAllGenres';

export function getAllGenres(genres = []) {
    return (dispatch) => {
        getGenres()
        .then((data) => {
            return dispatch({
                type: RETRIEVE_ALL_GENRES,
                payload: [...data.data.data]
            });
        })
        .catch((err) => {
            return dispatch({
                type: RETRIEVE_ALL_GENRES,
                payload: genres
            });
        });
    }
}