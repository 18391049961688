import { requestGrammarCheck } from "../../api/grammar-api";

export const GRAMMAR_CHECK_SUCCESS = "grammarCheckSuccess";
export const GRAMMAR_CHECK_RESET = "grammarCheckReset";
export const GRAMMAR_CHECK_ERROR = "grammarCheckError";
export const GRAMMAR_CHECK_START = "grammarCheckStart";

export function checkGrammar(text) {
  return dispatch => {
		const textWithSpacesAfterParagraph = text.replace(/<\/p>/g, ' ');
		const textWithNoTags = textWithSpacesAfterParagraph.replace(/<\/?[^>]+(>|$)/g, "");
		dispatch({ type: GRAMMAR_CHECK_START });
		requestGrammarCheck(textWithNoTags)
			.then(data => {
				dispatch({
					type: GRAMMAR_CHECK_SUCCESS,
					payload: data.data.matches
				});
			})
			.catch(err => {
				dispatch({
					type: GRAMMAR_CHECK_ERROR,
					payload: err
				});
			});
  };
}

export function resetGrammarMessages() {
	return dispatch => {
		dispatch({
			type: GRAMMAR_CHECK_RESET
		})
	}
}
