import {
	GRAMMAR_CHECK_ERROR,
	GRAMMAR_CHECK_SUCCESS,
	GRAMMAR_CHECK_RESET,
	GRAMMAR_CHECK_START
} from "../actions/grammar-actions";

const initialState = {
	messages: [],
	isLoading: false,
	hasCheckedBefore: false,
	error: null
};

export default function grammarCheckReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GRAMMAR_CHECK_ERROR:
      return {
				messages: [],
				isLoading: false,
				hasCheckedBefore: true,
				error: payload
			};
    case GRAMMAR_CHECK_SUCCESS:
      return {
				messages: payload,
				isLoading: false,
				hasCheckedBefore: true,
				error: null
			};
		case GRAMMAR_CHECK_RESET:
			return initialState;
		case GRAMMAR_CHECK_START:
			return {
				...state,
				isLoading: true
			}
    default:
      return state;
  }
}
