import { getAllQuestionType } from "../../api/questionType-api";


export const RETRIEVE_ALL_QUESTIONTYPES = 'passage:retrieveAllQuestionTypes';

export function getAllQuestionTypes(questionType = []) {
    return (dispatch) => {
        getAllQuestionType()
        .then((data) => {
            return dispatch({
                type: RETRIEVE_ALL_QUESTIONTYPES,
                payload: [...data.data.data]
            });
        })
        .catch((err) => {
            return dispatch({
                type: RETRIEVE_ALL_QUESTIONTYPES,
                payload: questionType
            });
        });
    }
}