import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  retrieveUserPassages,
  markTeacherComplete
} from "../redux/actions/user-passage-actions";

const WorkInProgress = props => {
  useEffect(() => {
    props.getUserPassages();
  }, []);
  const handleDone = id => {
    props.markTeacherAssignmentComplete(id, props.triggerNotification);
    setTimeout(() => {
      props.getUserPassages();
    }, 100);
  };
  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Work in Progress"
          subtitle=""
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col lg="12">
          {props.userPassages.length > 0 ? (
            <Table
              tableType="work-in-progress"
              {...props}
              handleWorkInProgressDone={handleDone}
              tableData={props.userPassages}
              filterType={"work-in-progress"}
            />
          ) : (
            <p>Assign passages to view data</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapState = state => {
  return {
    userPassages: state.userPassages
  };
};

const mapActions = dispatch => {
  return {
    getUserPassages: bindActionCreators(retrieveUserPassages, dispatch),
    markTeacherAssignmentComplete: bindActionCreators(
      markTeacherComplete,
      dispatch
    )
  };
};

export default connect(
  mapState,
  mapActions
)(WorkInProgress);
