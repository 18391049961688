import React from "react";
import {Route, Redirect} from "react-router-dom";

const ProtectedRoute = ({component: Component, triggerNotification, ...rest }) => {
    return <Route {...rest} render={
        props => (
            localStorage.getItem("token") ?
             <Component {...props} triggerNotification={triggerNotification}  />
            : <Redirect to={{ pathname: '/' }}/>
        )} />
 };


export default (ProtectedRoute);
