import React, { Fragment } from "react";
import { Container, Row, Col } from "shards-react";
import { Link } from "react-router-dom";

import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";
import GrammarOutput from "../components/grammar-output/GrammarOutput";

import { connect } from "react-redux";
import Loader from "../components/loader/Loader";
import {
  updatePassage,
  retrievePassageById,
  clearPassages,
  addPassage,
  publishFromLocal
} from "../redux/actions/passage-actions";

import moment from "moment";
import { bindActionCreators } from "redux";
import { setStatus } from "../redux/actions/status-actions";
import { checkGrammar, resetGrammarMessages } from "../redux/actions/grammar-actions";

class EditPassage extends React.Component {
  componentDidMount() {
    this.props.resetGrammarMessages();
    this.props.getPassageById(
      this.props.currentStatus,
      this.props.match.params.id
    );
  }

  componentWillUnmount() {
    this.props.clearPassages();
  }
  handleChange = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  };

  handleQuillChange = value => {
    if (value !== "<p>undefined</p>") {
      this.setState({
        text: value
      });
    }
  };

  handleCheckGrammarClick = () => {
    this.props.checkGrammar(this.state.text);
  }

  updateDraft = evt => {
    if (evt === "publish") {
      publishFromLocal(this.props.passages[0].id)
        .then(data => {
          this.props.setCurrentStatus("publish");
          setTimeout(() => {
            this.props.history.push(
              `/writer/passages/edit/${data.data.data[0].id}`
            );
          }, 500);
        })
        .catch(err => {
          this.props.triggerNotification("Error publishing passage");
        });
    } else {
      if (this.state) {
        this.props.onUpdatePassage(
          this.props.currentStatus,
          this.state,
          this.props.match.params.id,
          this.props.triggerNotification
        );

        setTimeout(() => {
          this.props.getPassageById(
            this.props.currentStatus,
            this.props.match.params.id
          );
        }, 200);
      } else {
        this.props.triggerNotification("Nothing to update");
      }
    }
  };

  render() {
    let data = "";
    let date = "";
    let save_button_text =
      this.props.currentStatus === "published" ||
        this.props.currentStatus === "publish"
        ? "Save published"
        : "Save draft";
    if (this.props.passages.length > 0) {
      data = this.props.passages[0];
      date = data.updatedAt;
      date = moment(date).format("LLLL");
    }

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {data ? (
          <Fragment>
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
              <PageTitle
                sm="12"
                md="4"
                title="Edit passage"
                subtitle={data.name}
                className="text-sm-left"
              />
              <Col sm="12" md="8" className="text-right">
                <Link to={`/writer/questions/all/published/${data.id}`}>
                  <u>
                    {this.props.currentStatus !== "draft"
                      ? `There are ${data.numOfQuestions ||
                      0}  questions for this passage`
                      : ""}
                  </u>
                </Link>
              </Col>
            </Row>
            <Row>
              {/* Editor */}
              <Col lg="7" md="12">
                <Editor
                  showTitle
                  handleChange={this.handleChange}
                  handleQuillChange={this.handleQuillChange}
                  toolbar={[['bold', 'italic'], ['image']]}
                  {...data}
                />
                <GrammarOutput />
              </Col>
              {/* Sidebar Widgets */}
              <Col lg="5" md="12">
                <SidebarActions
                  save_button={save_button_text}
                  isPublished={
                    this.props.userData.user.role === "Writer" &&
                    !["publish", "published"].includes(this.props.currentStatus)
                  }
                  status={this.props.currentStatus}
                  saveDraft={this.updateDraft}
                  handleCheckGrammarClick={this.handleCheckGrammarClick}
                />
                <SidebarCategories
                  isEdit={true}
                  version={"local"}
                  currentPassage={data}
                  {...this.props}
                  handleSideBarCategories={this.handleChange}
                  title="Filters"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                {/* data will be retrieved from the database */}
                <p>Last edit {date}</p>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <Loader />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  passages: state.passages,
  currentStatus: state.currentStatus,
  userData: state.userData,
  id: state.id
});

const mapActionsToProps = dispatch => ({
  getPassageById: bindActionCreators(retrievePassageById, dispatch),
  onUpdatePassage: bindActionCreators(updatePassage, dispatch),
  clearPassages: bindActionCreators(clearPassages, dispatch),
  publishPassage: bindActionCreators(addPassage, dispatch),
  setCurrentStatus: bindActionCreators(setStatus, dispatch),
  checkGrammar: bindActionCreators(checkGrammar, dispatch),
  resetGrammarMessages: bindActionCreators(resetGrammarMessages, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(EditPassage);
