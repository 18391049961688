import { getSkillGroups } from "../../api/skill-groups-api";

export const RETRIEVE_ALL_SKILL_GROUPS = "skillGroups:retrieveAllSkillGroups";
export const CLEAR_SKILL_GROUPS = "skillGroups:clearSkillGroups";

export function getAllSkillGroups(isAssigned) {
  return dispatch => {
    getSkillGroups()
      .then(data => {
        dispatch({
          type: RETRIEVE_ALL_SKILL_GROUPS,
          payload: data.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: RETRIEVE_ALL_SKILL_GROUPS,
          payload: []
        });
      });
  };
}

export function clearSkills() {
  return {
    type: CLEAR_SKILL_GROUPS,
    payload: []
  };
}
