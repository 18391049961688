let api = "https://eccp.api.development.shoelacelearning.com/api/v1";
if (window.location.host === "education-tool.shoelacelearning.com") {
  api = "https://eccp.api.shoelacelearning.com/api/v1";
}
else if (
  window.location.host === "localhost:3001" ||
  window.location.host === "localhost:3000"
) {
  api = "http://localhost:8081/api/v1";
}
module.exports = {
  api
};
