import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormInput,
  FormSelect
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addUser, updateUserRole, getAllUsers } from "../redux/actions/user-actions";

const ManageUsers = props => {
  const [state, setState] = useState({
    name: null,
    email: null,
    password: null,
    roleId: null
  });

  useEffect(() => {
    props.getUsers();
  }, []);

  const handleChange = evt => {
    setState({ ...state, [evt.target.name]: evt.target.value });
  };
  const submitUser = () => {
    props.addUser(state, props.triggerNotification);
    // update list of users
    setTimeout(() => {
      props.getUsers();
    }, 300);
  };

  const handleRoleSelection = evt => {
    const userId = evt.target.name;
    const roleId = evt.target.value;
    props.updateUserRole({id: userId, roleId}, props.triggerNotification);
    setTimeout(() => {
      props.getUsers();
    }, 500);
  }

  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Manage Users"
          subtitle=""
          className="text-sm-left"
        />
      </Row>
      <Row form>
        <Col sm="4" className="form-group">
          <label htmlFor="">Name</label>
          <FormInput name="name" onChange={handleChange} type="text" />
        </Col>
        <Col sm="8" className="form-group">
          <label htmlFor="">Email</label>
          <FormInput name="email" onChange={handleChange} type="text" />
        </Col>
      </Row>
      <Row form>
        <Col sm="4" className="form-group">
          <label htmlFor="">Password</label>
          <FormInput name="password" onChange={handleChange} type="password" />
        </Col>
        <Col sm="8" className="form-group">
          <label htmlFor="">Roles</label>
          <FormSelect onChange={handleChange} name="roleId">
            <option>Choose</option>
            {props.match.url.includes("lead") ? (
              <>
                <option value="3">Teacher</option>
              </>
            ) : (
              <>
                <option value="1">Writer</option>
                <option value="2">Lead</option>
                <option value="3">Teacher</option>
                <option value="4">Lead/Reviewer</option>
              </>
            )}
          </FormSelect>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="text-right">
          <Button theme="dark" onClick={submitUser}>
            Add
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col lg="12">
          <Table
            tableType="manage-users"
            handleRoleSelection={handleRoleSelection}
            {...props}
            filter={false}
            tableData={props.userData.users}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapActionToProps = dispatch => {
  return {
    addUser: bindActionCreators(addUser, dispatch),
    updateUserRole: bindActionCreators(updateUserRole, dispatch),
    getUsers: bindActionCreators(getAllUsers, dispatch)
  };
};

export default connect(
  null,
  mapActionToProps
)(ManageUsers);
