import { RETRIEVE_ALL_SKILL_GROUPS } from "../actions/skill-groups-actions";

export default function skillGroupsReducer(state = [], {type, payload}) {
    switch (type) {
        case RETRIEVE_ALL_SKILL_GROUPS:
            return payload;
        default:
            return state;
    }
}
