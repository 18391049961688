import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllUsers } from "../redux/actions/user-actions";
import {
  getAllIndependentQuestions,
  clearIndependentQuestion,
  updateIndependentQuestion
} from "../redux/actions/independent-question-actions";
import { setStatus } from "../redux/actions/status-actions";

const ReviewIndependentQuestions = props => {
  useEffect(() => {
    props.getIndependentQuestions("in-review");
    props.setStatus("in-review");
    props.getUsers();
    return () => {
      props.clearIndependentQuestion();
    };
  }, []);

  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Review Independent Questions"
          subtitle=""
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col lg="12">
          <Table
            filter={true}
            {...props}
            tableData={props.independentQuestions}
            tableType="review-independent-questions"
            filterType="review-independent-questions"
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    users: state.userData.users,
    independentQuestions: state.independentQuestions
  };
};

const mapActionToProps = dispatch => {
  return {
    getUsers: bindActionCreators(getAllUsers, dispatch),
    getIndependentQuestions: bindActionCreators(
      getAllIndependentQuestions,
      dispatch
    ),
    clearIndependentQuestion: bindActionCreators(
      clearIndependentQuestion,
      dispatch
    ),
    updateIndependentQuestion: bindActionCreators(
      updateIndependentQuestion,
      dispatch
    ),
    setStatus: bindActionCreators(setStatus, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(ReviewIndependentQuestions);
