import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { retrieveUserSkills } from "../redux/actions/user-skills-actions";

const IndependentWorkInProgress = props => {
  useEffect(() => {
    props.getUserSkills();
  }, []);
  // const handleDone = id => {
  //   props.markTeacherAssignmentComplete(id, props.triggerNotification);
  //   setTimeout(() => {
  //     props.getUserSkills();
  //   }, 100);
  // };
  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Work in Progress"
          subtitle=""
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col lg="12">
          {props.userSkills.length > 0 ? (
            <Table
              tableType="independent-work-in-progress"
              {...props}
              // handleWorkInProgressDone={handleDone}
              tableData={props.userSkills}
              filterType={"independent-work-in-progress"}
            />
          ) : (
            <p>Assign independent skills to view data</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const mapState = state => {
  return {
    userSkills: state.userSkills
  };
};

const mapActions = dispatch => {
  return {
    getUserSkills: bindActionCreators(retrieveUserSkills, dispatch)
    // markTeacherAssignmentComplete: bindActionCreators(
    //   markTeacherComplete,
    //   dispatch
    // )
  };
};

export default connect(
  mapState,
  mapActions
)(IndependentWorkInProgress);
