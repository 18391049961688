import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Col,
  Container,
  FormGroup,
  FormInput,
  FormTextarea,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "shards-react";
import FormSelect from '../../../components/formselect/FormSelect';
import { generate } from "shortid";
import PageTitle from "../../../components/common/PageTitle";
import { setId } from "../../../redux/actions/id-actions";
import {
  addIndependentQuestion,
  clearIndependentQuestion,
  getIndependentQuestionId,
  updateIndependentQuestion
} from "../../../redux/actions/independent-question-actions";
import { getAllIndependentSkills } from "../../../redux/actions/skill-independent-actions";
import Editor from "../../../components/add-new-post/Editor";
import { getCommonCoreIndependent } from "../../../redux/actions/common-core-independent-actions";
import { Answers } from "../answer/Answers";
import {
  validateAndBuildAnswersByQuestionType,
  validateOrderQuestionType,
  validateQuestionAnswers
} from "../questionValidation";
import { addAnswer, editAnswerChange, removeAnswer, sendQuestionBack, sendQuestionToReview } from "../QuestionOperations";
import { QuestionCategory } from "../../../enums/QuestionType";

const EditIndependentQuestions = props => {
  // states
  const [state, setState] = useState({
    id: null,
    name: null,
    potentialAnswers: [],
    correctAnswers: [],
    questionTypeId: null, // 1 = multiple choice, 3 = drag and drop, 4 = select all
    skillId: null,
    gradeId: null,
    question: null,
    subjectId: null,
    grades: Array.from(Array(8)).map((e, i) => i + 1),
    internalNote: null,
    commonCoreIndependentId: null,
    status: "In Review",
    educationToolData: {},
    feedbackCorrect: "",
    feedbackIncorrect: "",
    isLoaded: false,
    dei: 0
  });
  const [answers, setAnswers] = useState([]);
  const [order] = useState([1, 2, 3, 4, 5, 6]);
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState({
    editing: false
  });
  const [viewTitle, setViewTitle] = useState('Edit independent question');

  const [note, setNote] = useState({
    internalNote: null,
    status: "Denied"
  });
  useEffect(() => {
    props.setId(null);
    props.getAllIndependentSkills();
    props
      .getIndependentQuestionById(props.currentStatus, props.match.params.id)
      .then(data => {
        const stateData = { ...data.data.data };
        setAnswers(
          stateData.answers.map((e, i) => {
            return {
              label: e.label,
              value: e.value,
              id: generate()
            };
          })
        );
        let feedbackCorrect = '';
        let feedbackIncorrect = '';
        try {
          feedbackCorrect = JSON.parse(stateData.feedbackCorrect)[0];
          feedbackIncorrect = JSON.parse(stateData.feedbackIncorrect)[0];
        } catch (e) {
          console.error('Feedback contains invalid JSON');
        }
        setState({
          ...state,
          name: stateData.name,
          potentialAnswers: [],
          correctAnswers: [],
          questionTypeId: stateData.questionType.id, // 1 = multiple choice, 3 = drag and drop, 4 = select all
          skillId: stateData.skill ? stateData.skill.id : null,
          gradeId: stateData.gradeId,
          question: stateData.question,
          internalNote: stateData.internalNote || "",
          status: stateData.status,
          commonCoreIndependentId: stateData.commonCoreIndependentId,
          educationToolData: stateData.educationToolData || {},
          feedbackCorrect: feedbackCorrect,
          feedbackIncorrect: feedbackIncorrect,
          subjectId: stateData.subjectId,
          isLoaded: true,
          dei: stateData.dei ? stateData.dei : 0
        });
        let viewTitle = 'Edit Independent Question';
        if (
          stateData.educationToolData &&
          stateData.educationToolData.userId !== props.userData.user.id
        ) {
          viewTitle = 'View Independent Question';
          if (props.userData.user.role === 'External Creator') {
            viewTitle = 'View Question';
          }
        } else if (props.userData.user.role === 'External Creator') {
          viewTitle = 'Edit Question';
        }
        setViewTitle(viewTitle);
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          setViewTitle('Question Not Found');
        }
        console.log(err);
      });
    return () => {
      props.clearIndependentQuestions();
      props.setId(null);
      console.log("edit Question dismounting");
    };
  }, []);

  useEffect(() => {
    props.getAllIndependentSkills();
    props.getCommonCoreIndependent();
  }, [state.gradeId])


  useEffect(() => {
    if (props.questionId !== null) {
      if (props.match.url.includes("writer")) {
        props.history.push(
          `/writer/independent-questions/edit/${props.questionId}`
        );
      } else if (props.match.url.includes("lead+")) {
        props.history.push(
          `/lead+/independent-questions/edit/${props.questionId}`
        );
      } else if (props.match.url.includes("external-creator")) {
        props.history.push(
          `/external-creator/independent-questions/edit/${props.questionId}`
        );
      } else if (props.match.url.includes("lead")) {
        props.history.push(
          `/lead/independent-questions/edit/${props.questionId}`
        );
      } else {
        props.history.push(
          `/teacher/independent-questions/edit/${props.questionId}`
        );
      }
    }
  }, [props.currentStatus]);

  const handleChange = evt => {
    setEdit({
      editing: true
    });
    let value = evt.target.value;
    if (evt.target.value === "difficulty") {
      value = Number(value);
    }
    setState({
      ...state,
      [evt.target.name]: value
    });
  };

  const isAllowedToEdit = () => {
    if (
      props.userData.user.role !== 'External Creator' ||
      state.status !== 'published'
    ) {
      return true;
    }
    return state.educationToolData.userId === props.userData.user.id;
  }

  const toggleModal = () => {
    setModal(!modal);
  };
  const validateQuestion = () => {
    let isValid = true;

    if (state.questionTypeId === null) {
      props.triggerNotification("Question type needs to be selected", "error");
      isValid = false;
    }
    if (state.commonCoreIndependentId === null) {
      props.triggerNotification("Common Core cannot be empty", "error");
      isValid = false;
    }
    // multiple choice validation
    isValid = validateQuestionAnswers(state.questionTypeId, answers, props.triggerNotification);
    if (props.userData.user.role !== 'External Creator') {
      if (state.commonCoreId === null) {
        props.triggerNotification("Must select a common core standard", "error");
        isValid = false;
      }
    }

    if (props.userData.user.role === 'External Creator') {
      if (state.subjectId === null) {
        props.triggerNotification("Please, select a subject", "error");
        isValid = false;
      }
    }

    if (state.skillId === null) {
      props.triggerNotification("Skill needs to be selected", "error");
      isValid = false;
    }
    if (state.gradeId === null) {
      props.triggerNotification("Grade needs to be selected", "error");
      isValid = false;
    }
    if (state.question === null) {
      props.triggerNotification("Question needs to be entered", "error");
      isValid = false;
    }

    return isValid;
  };

  const requestBuilder = (potentialAnswers, correctAnswers, status) => {
    const request = {
      name: state.name,
      potentialAnswers,
      correctAnswers,
      questionTypeId: state.questionTypeId,
      skillId: state.skillId,
      gradeId: state.gradeId,
      question: state.question,
      commonCoreIndependentId: state.commonCoreIndependentId,
      feedbackCorrect: JSON.stringify([state.feedbackCorrect]),
      feedbackIncorrect: JSON.stringify([state.feedbackIncorrect]),
      dei: Number(state.dei)
    };
    if (status !== "publish") {
      request.status = status;
    }
    if (props.userData.user.role === 'External Creator') {
      request.subjectId = state.subjectId;
    }
    return request;
  };

  const handleAnswerChange = (evt, id) => {
    editAnswerChange(evt, id, setEdit, setAnswers)
  };
  const removeRow = id => {
    removeAnswer(id, answers, setAnswers)
  };
  const addRow = () => {
    addAnswer(state.questionTypeId, props.triggerNotification, answers, setAnswers)
  }

  const editIndependentQuestions = status => {
    try {
      if (status === "draft") {
        status = "Draft";
      } else if (status === "denied") {
        status = "Denied";
      }
      if (Number(state.questionTypeId) === 3) {
        // drag and drop
        const { potentialAnswers, correctAnswers, requestObject } = validateOrderQuestionType(answers, props.triggerNotification, status, requestBuilder);
        if (validateQuestion(potentialAnswers, correctAnswers, status)) {
          setEdit({
            editing: false
          });
          props.updateIndependentQuestion(
            props.currentStatus,
            requestObject,
            props.match.params.id,
            props.triggerNotification
          );
        }
        return;
      } else {
        const {
          potentialAnswers,
          correctAnswers,
          requestObject
        } = validateAndBuildAnswersByQuestionType(state.questionTypeId, answers, status, props.triggerNotification, requestBuilder);

        if (validateQuestion(potentialAnswers, correctAnswers, status)) {
          setEdit({
            editing: false
          });
          props.updateIndependentQuestion(
            props.currentStatus,
            requestObject,
            props.match.params.id,
            props.triggerNotification
          );
        }
        return;
      }
    } catch (error) {
      console.log('Make sure you have selected atleast one correct answer option and that no answser fields are empty.');
    }
  };

  const sendToReview = () => {
    sendQuestionToReview(props, validateQuestion, QuestionCategory.Independent)
  };

  const sendBack = () => {
    sendQuestionBack(props, note, toggleModal, QuestionCategory.independentQuestions)
  };

  const publishIndependentQuestion = () => {
    if (validateQuestion()) {
      props.publishIndependentQuestion(
        {
          localQuestionId: props.match.params.id
        },
        props.triggerNotification,
        "Publish"
      );
    }
  };

  const handleNote = evt => {
    setNote({ ...note, [evt.target.name]: evt.target.value });
  };

  let skills = props.skillsIndependent;
  const userId = props.userData.user.id;
  if (props.match.url.includes("teacher")) {
    skills = props.userSkills
      .filter(item => item.userId === userId)
      .map(item => ({ ...item.skill }))
      .filter((item, index, array) => {
        return array.map(mapItem => mapItem.name).indexOf(item.name) === index;
      });
  } else if (props.match.url.includes("external-creator")) {
    skills = skills.filter(item => {
      return item.subject &&
        item.grade === parseInt(state.gradeId) &&
        item.subject.id === parseInt(state.subjectId);
    });
  }

  const renderActions = row => {
    if (
      props.userData.user.role === 'External Creator' &&
      props.currentStatus !== "draft" &&
      state.educationToolData.userId !== props.userData.user.id
    ) {
      return null;
    }
    if (props.currentStatus !== "in-review") {
      return (
        <>
          <label htmlFor="feInputCountry">Actions</label>
          <br />
          <Button
            theme="danger"
            onClick={() => removeRow(row.id)}
          >
            remove
          </Button>
        </>
      );
    }
    return null;
  }

  const renderAddNewAnswerButton = () => {
    if (
      props.userData.user.role === 'External Creator' &&
      props.currentStatus !== "draft" &&
      state.educationToolData.userId !== props.userData.user.id
    ) {
      return null;
    }
    if (
      props.currentStatus !== "in-review" ||
      props.match.url.includes("writer")
    ) {
      return (
        <Button onClick={addRow}>Add new answer</Button>
      )
    }
    return null;
  }

  let commonCoreIndependentData = props.commonCoreIndependent || [];
  let commonCoreIndependent = commonCoreIndependentData
    .filter(item => [item.gradeId - 1, item.gradeId, item.gradeId + 1].includes(parseInt(state.gradeId)));
  if (props.userData.user.role === 'External Creator' && !props.userData.user.isInternal) {
    commonCoreIndependent = commonCoreIndependent
      .filter(item => {
        return item.skillId === parseInt(state.skillId) &&
          item.subjectId === parseInt(state.subjectId);
      });
  }

  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title={viewTitle}
          subtitle=""
          className="text-sm-left"
        />
      </Row>
      {state.isLoaded ? (
        <>
          <Row>
            {props.userData.user.role === 'External Creator' &&
              <Col lg="12">
                <FormGroup>
                  <label htmlFor="name">Name:</label>
                  <FormInput name="name" value={state.name} onChange={handleChange} readOnly={!isAllowedToEdit()} />
                </FormGroup>
              </Col>
            }
            <Col lg="12">
              <label htmlFor="questions">Question:</label>
              <Editor
                editorName="question"
                text={state.question}
                handleQuillChange={question => {
                  setEdit({ editing: true });
                  setState({ ...state, question });
                }}
                readOnly={!isAllowedToEdit()}
                toolbar={isAllowedToEdit() ? [['bold', 'italic'], ['image']] : []}
              />
            </Col>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="gradeId">Grade:</label>
                <FormSelect name="gradeId" onChange={handleChange} readOnly={!isAllowedToEdit()}>
                  <option value={null}>Choose</option>
                  {state.grades.map((e, i) => {
                    const selected = state.gradeId === e;
                    return (
                      <option key={i} value={e} selected={selected}>
                        {e}
                      </option>
                    );
                  })}
                </FormSelect>
              </FormGroup>
            </Col>
            {
              props.userData.user.role === 'External Creator' &&
              <Col lg="12">
                <FormGroup>
                  <label htmlFor="subjectId">Subject:</label>
                  <FormSelect name="subjectId" onChange={handleChange} readOnly={!isAllowedToEdit()}>
                    <option value={null}>Choose</option>
                    {props.subjects.map((e, i) => {
                      const selected = e.id === state.subjectId;
                      return (
                        <option key={i} value={e.id} selected={selected}>
                          {e.name}
                        </option>
                      );
                    })}
                  </FormSelect>
                </FormGroup>
              </Col>
            }
            <Col lg="12">
              <FormGroup>
                <label htmlFor="skills">Skills:</label>
                <FormSelect name="skillId" onChange={handleChange} readOnly={!isAllowedToEdit()}>
                  <option value={null}>Choose</option>
                  {
                    Object.keys(props.skillGroups).map((group) => {
                      return (
                        <optgroup label={group}>
                          {
                            props.skillGroups[group].map((skill) => {
                              if (skill) {
                                const selected = skill.id === state.skillId;
                                return (
                                  <option key={group + skill.name} value={skill.id} selected={selected}>
                                    {skill.name}
                                  </option>
                                )
                              }
                            })
                          }
                        </optgroup>
                      )
                    })
                  }
                </FormSelect>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="commonCoreIndependentId">Standards</label>
                <FormSelect name="commonCoreIndependentId" onChange={handleChange} readOnly={!isAllowedToEdit()}>
                  <option value={null}>Choose</option>
                  {commonCoreIndependent.map((data, i) => (
                    <option key={i} title={data.description} value={data.id} selected={data.id === state.commonCoreIndependentId}>
                      {data.description}
                    </option>
                  ))}
                </FormSelect>
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="questionType">Question type:</label>
                <FormSelect onChange={handleChange} name="questionTypeId" readOnly={!isAllowedToEdit()}>
                  <option value={null}>Choose</option>
                  {props.questionTypes.map((e, i) => {
                    if (e.name !== "Highlight") {
                      const selected = state.questionTypeId === e.id
                      return (
                        <option key={i} value={e.id} selected={selected}>
                          {e.name}
                        </option>
                      );
                    }
                  })}
                </FormSelect>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            {/* answer */}
            {answers.map((row, i) => {
              return <Answers state={state} removeRow={removeRow} row={row} order={order} handleAnswerChange={handleAnswerChange} currentStatus={props.currentStatus} edit={true} />;
            })}
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                {renderAddNewAnswerButton()}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="feedbackIncorrect">Feedback for Incorrect Answer:</label>
                <FormInput name="feedbackIncorrect" value={state.feedbackIncorrect} onChange={handleChange} readOnly={!isAllowedToEdit()} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label htmlFor="dei">DEI:</label>
                <FormSelect onChange={handleChange} name="dei">
                  <option value="0" selected={!state.dei}>False</option>
                  <option value="1" selected={state.dei}>True</option>
                </FormSelect>
              </FormGroup>
            </Col>
          </Row>

          &nbsp;
          {/* Buttons */}
          {
            props.userData.user.role === 'External Creator' &&
              state.status === 'published' &&
              state.educationToolData.userId !== props.userData.user.id
              ? null :
              <EditQuestionButtons
                {...props}
                sendToReview={sendToReview}
                publishIndependentQuestion={publishIndependentQuestion}
                addIndependentQuestion={editIndependentQuestions}
                toggleModal={toggleModal}
                edit={edit.editing}
              />
          }
        </>
      ) : (
        ""
      )}
      <br />
      <br />
      <Row>
        <Col lg="12">
          {state.internalNote && (
            <>
              <FormTextarea value={state.internalNote} disabled />
            </>
          )}
        </Col>
      </Row>
      <br />
      <Modal open={modal} toggle={toggleModal}>
        <ModalHeader>Note</ModalHeader>
        <ModalBody>
          <FormTextarea name="internalNote" onChange={handleNote} />
        </ModalBody>
        <ModalFooter>
          <Button outline theme="danger" onClick={sendBack}>
            Send back
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

const EditQuestionButtons = ({
  sendToReview,
  publishIndependentQuestion,
  toggleModal,
  addIndependentQuestion,
  currentStatus,
  match,
  edit
}) => {
  let renderButtons = null;
  let reset = null;
  let sendBack = "";
  if (
    (match.url.includes("writer") ||
      match.url.includes("teacher") ||
      match.url.includes("external-creator") ||
      match.url.includes("lead+")) &&
    edit
  ) {
    if (currentStatus === "in-review") {
      if (match.url.includes("writer") || match.url.includes("lead+") || match.url.includes("lead") || match.url.includes("teacher")) {
        renderButtons = (
          <Button
            theme="success"
            onClick={() => addIndependentQuestion(currentStatus)}
          >
            Update {currentStatus}
          </Button>
        );
      }
    } else if (currentStatus === "published") {
      if (match.url.includes("writer") || match.url.includes("external-creator")) {
        renderButtons = (
          <Button
            theme="success"
            onClick={() => addIndependentQuestion(currentStatus)}
          >
            Update {currentStatus}
          </Button>
        );
      }
    } else {
      renderButtons = (
        <Button
          theme="success"
          onClick={() => addIndependentQuestion(currentStatus)}
        >
          Update {currentStatus}
        </Button>
      );
    }
  } else if (
    !["publish", "published", "in-review"].includes(currentStatus) &&
    !match.url.includes("external-creator") &&
    !edit
  ) {
    renderButtons = (
      <Button onClick={() => sendToReview()}>Send To Review</Button>
    );
  } else if (
    (match.url.includes("writer") || match.url.includes("lead+") || match.url.includes("lead") || match.url.includes("external-creator")) &&
    !["publish", "published"].includes(currentStatus) &&
    !edit
  ) {
    renderButtons = (
      <Button onClick={() => publishIndependentQuestion("Publish")}>
        {match.url.includes("external-creator") ? 'Submit to Question Pool' : 'Publish'}
      </Button>
    );
  }
  if (edit) {
    reset = <Button onClick={() => window.location.reload()}>Reset</Button>;
  }

  if (
    ["in-review"].includes(currentStatus) &&
    (match.url.includes("writer") || match.url.includes("lead+") || match.url.includes("lead")) &&
    !edit
  ) {
    sendBack = <Button onClick={toggleModal}>Send Back</Button>;
  }
  return (
    <Row>
      <Col lg="12" className="text-right">
        {reset}
        &nbsp;
        {renderButtons}
        &nbsp;
        {sendBack}
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  return {
    questionId: state.id,
    userData: state.userData,
    skills: state.skills,
    userSkills: state.userSkills,
    skillsIndependent: state.skillsIndependent,
    subjects: state.subjects,
    currentStatus: state.currentStatus,
    independentQuestions: state.independentQuestions,
    commonCoreIndependent: state.commonCoreIndependent
  };
};

const mapActionToProps = dispatch => ({
  getIndependentQuestionById: bindActionCreators(
    getIndependentQuestionId,
    dispatch
  ),
  updateIndependentQuestion: bindActionCreators(
    updateIndependentQuestion,
    dispatch
  ),
  clearIndependentQuestions: bindActionCreators(
    clearIndependentQuestion,
    dispatch
  ),
  publishIndependentQuestion: bindActionCreators(
    addIndependentQuestion,
    dispatch
  ),
  setId: bindActionCreators(setId, dispatch),
  getAllIndependentSkills: bindActionCreators(getAllIndependentSkills, dispatch),
  getCommonCoreIndependent: bindActionCreators(getCommonCoreIndependent, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(EditIndependentQuestions);
