export const GET_STATUS = "status:retrieveStatus";
export const SET_STATUS = "status:setStatus";

export function getStatus() {
  return {
    type: GET_STATUS,
    payload: []
  };
}

export function setStatus(status) {
  return {
    type: SET_STATUS,
    payload: status
  };
}
