import {
  RETRIEVE_ALL_BOOKS,
  ADD_BOOK,
  UPDATE_BOOK
} from "../actions/book-actions";

export default function bookReducer(state = [], { type, payload }) {
  switch (type) {
    case RETRIEVE_ALL_BOOKS:
      return payload;
    case ADD_BOOK:
      return state;
    case UPDATE_BOOK:
      return state;
    default:
      return state;
  }
}
