import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Button } from "shards-react";

class GrammarOutput extends React.Component {

	handleReplaceText = () => {
		this.props.handleReplaceText()
	}

	handleCleanHTMLChecks = (messages) => {
		const htmlTags = ['br'];
		return messages.filter(message => {
			const { text, offset, length } = message.context;
			const possibleMistake = text.substr(offset, length);
			return !htmlTags.includes(possibleMistake);
		});
	}

	renderContent = () => {
		const { messages, isLoading, hasCheckedBefore } = this.props.grammarCheck;
		if (isLoading) {
			return <p>Checking grammar...</p>;
		}

		const cleanMessages = this.handleCleanHTMLChecks(messages);
		if (cleanMessages.length) {
			return this.renderMessages(cleanMessages);
		}

		if (hasCheckedBefore) {
			return (<>
				<i className="material-icons mr-1">check</i>
				<span>All set. No recommendations found.</span>
			</>);
		}

		return (<>
			<i className="material-icons mr-1">spellcheck</i>
			<span>Click on "Check Grammar" to check your content.</span>
		</>);
	}

	renderMessages = messages => {
		return messages.map(entry => {
			const { context, replacements, message } = entry;
			const { length, offset, text } = context;
			let suggestions = [];
			if (replacements.length) {
				suggestions = replacements.map(replacement => replacement.value);
			}
			const mistake = text.substr(offset, length);
			return (<div className="grammar-output__item">
				<h6>{message}</h6>
				<p className="grammar-output__suggestion-prompt">
					<strong>Sentence:</strong>  <em>{text}</em>
				</p>
				<strong>Possible mistake:</strong> <em className="grammar-output__mistake">{mistake}</em>
				{suggestions.length > 0 && (<>
					{/* <p className="grammar-output__suggestion-prompt">Would you like to replace by one of these?</p> */}
					<p className="grammar-output__suggestion-prompt">Do you mean one of these?</p>
					<ul className="grammar-output__suggestion-list">
						{suggestions.map(suggestion => {
							return (
								<li>
									{suggestion}
									{/* <Button
										className="grammar-output__replace-button"
										onClick={() => this.props.handleReplaceText(offset, length, suggestion)}>
										Yes
									</Button> */}
								</li>
							)})}
					</ul>
				</>)}
			</div>);
		});
	}
	render() {
		return (
			<Card small className="mb-3">
      	<CardBody>
					{this.renderContent()}
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = state => ({
	grammarCheck: state.grammarCheck
});

export default connect(
	mapStateToProps,
	null
)(GrammarOutput);