import React from "react";
import Select from 'react-select'

const formatChildren = (children) => {
  let selected = false;
  const options = children.map(e=> {
    if (e && e.props) {
      const option = {value: e.props.value, label:e.props.children};
      if (e.props.selected) {
        selected = option;
      }
      return option;
    } else {
      return null;
    }
  }).filter(e => e);
  return { options, selected }
}

const FormSelect = (props) => {
  let {children, name} = props;
  console.log(name, children);
  if (Array.isArray(children[1])) {
    children = children[1];
  }
  let options = [];
  let selected = {};
  if (children[0] && children[0].type === "optgroup") {
    let groups = [];
    for (let child of children) {
      const { label, children } = child.props;
      const { options, selected: optionSelection } = formatChildren(children);
      selected = optionSelection ? optionSelection : selected;
      groups.push({ label, options })
    }
    options = groups;
  } else {
    const { options: formattedOptions, selected: optionSelection } = formatChildren(children);
    selected = optionSelection ? optionSelection : selected;
    options = formattedOptions;
  }
  const handleChange = (e, name) => {
    const evt = {
      target: {
        value: e.value,
        name
      }
    }
    props.onChange(evt);
  }
  if (options && options.length > 0) {
    return (
      <Select defaultValue={selected} onChange={(e) => handleChange(e, name)} options={options} id={name}/>
    );
  } else {
    return null
  }
};

export default FormSelect;
