import React, { Fragment } from "react";
import { Row, Col, FormInput, Button } from "shards-react";
import "../assets/login.css";
import { connect } from "react-redux";
import { authenticateUser, updateUser } from "../redux/actions/user-actions";
import Loader from "../components/loader/Loader";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      isLoading: false
    };
    if (this.props.userData) {
      if (this.props.userData.user) {
        this.redirect(this.props.userData);
      }
    }
  }

  handleEnterKeyUp = evt => {
    if (evt.key === "Enter") {
      this.handleLoginSubmission();
    }
  };

  handleLoginInput = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  };

  isEmpty = field => {
    return field === "" || field === null;
  };

  redirect = userData => {
    if (userData.user.role === "Writer") {
      this.props.history.push("/writer");
    } else if (userData.user.role === "Lead") {
      this.props.history.push("/lead");
    } else if (userData.user.role === "Teacher") {
      this.props.history.push("/teacher");
    } else if (userData.user.role === "External Creator") {
      this.props.history.push("/external-creator");
    } else {
      this.props.history.push("/lead+");
    }
  };

  handleLoginSubmission = () => {
    // login
    this.setState({ isLoading: true });
    if (this.isEmpty(this.state.email) || this.isEmpty(this.state.password)) {
      this.props.triggerNotification(
        "Please enter username and password",
        "info",
        "tr"
      );
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1000);
    } else {
      this.props
        .onLoginUser(this.state)
        .then(data => {
          const userData = { ...data.data.data };
          this.props.updateUser(userData);
          localStorage.setItem("token", userData.token);
          setTimeout(() => {
            this.setState({ isLoading: false });
            this.redirect(userData);
          }, 1000);
        })
        .catch(err => {
          console.log(err);
          this.props.triggerNotification(
            "incorrect username or password",
            "error",
            "tr"
          );
          this.setState({ isLoading: false });
        });
    }
  };

  render() {
    return (
      <Fragment>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <Row onKeyUp={this.handleEnterKeyUp}>
            <Col lg={{ size: 12 }} className="center-align">
              <h1 className="login-header">
                ECCP <br />
                <i>
                  Educational Content <br /> Creation Platform
                </i>
              </h1>
              <br />
              <FormInput
                onChange={this.handleLoginInput}
                name="email"
                placeholder="enter email"
              />
              <br />
              <FormInput
                onChange={this.handleLoginInput}
                name="password"
                type="password"
                placeholder="enter password"
              />
              <br />
              <Button theme={"dark"} onClick={this.handleLoginSubmission}>
                Login
              </Button>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  userData: state.userData
});

const mapActionsToProps = {
  onLoginUser: authenticateUser,
  updateUser: updateUser
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(Login);
