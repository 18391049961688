import { RETRIEVE_ALL_GRADES } from "../actions/grade-actions";


export default function gradeReducer(state = [], {type, payload}) {
    switch (type) {
        case RETRIEVE_ALL_GRADES:
            return payload;
        default:
            return state;
    }
}
