import {
  getIndependentQuestionById,
  getIndependentQuestions,
  postIndependentQuestions,
  putIndependentQuestions,
  duplicateIndependentQuestionById
} from "../../api/independent-questions-api";
import { SET_ID } from "./id-actions";
import { SET_STATUS } from "./status-actions";

export const RETRIEVE_ALL_INDEPENDENT_QUESTIONS =
  "independentQuestion:retrieveAllQuestions";
export const ADD_INDEPENDENT_QUESTION =
  "independentQuestion:addIndependentQuestion";
export const CLEAR_INDEPENDENT_QUESTION =
  "independentQuestion:clearIndependentQuestions";
export const UPDATE_INDEPENDENT_QUESTION =
  "independentQuestion:updateIndependentQuestion";

export function getAllIndependentQuestions(status, userId) {
  let endpoint = "local";
  if (status === "published" || status === "publish") {
    endpoint = "live";
  } else if (status === "in-review") {
    endpoint += "/?status=in%20review";
    if (userId) {
      endpoint = `${endpoint}&userId=${userId}`;
    }
  } else if (status === "draft") {
    endpoint += "/?status=Draft";
  } else if (status === "denied") {
    endpoint += "/?status=denied";
  }
  return dispatch => {
    getIndependentQuestions(endpoint)
      .then(data => {
        return dispatch({
          type: RETRIEVE_ALL_INDEPENDENT_QUESTIONS,
          payload: data.data.data
        });
      })
      .catch(err => {
        return dispatch({
          type: RETRIEVE_ALL_INDEPENDENT_QUESTIONS,
          payload: []
        });
      });
  };
}

export function getIndependentQuestionId(status, id, questions = []) {
  let endpoint = "local";
  if (status === "published" || status === "publish") {
    endpoint = "live";
  }

  return dispatch => {
    return getIndependentQuestionById(endpoint, id);
  };
}

export function updateIndependentQuestion(
  status,
  question,
  id,
  notificationSystem
) {
  let endpoint = "local";
  if (status === "published" || status === "publish") {
    endpoint = "live";
  }

  if (question.status) {
    if (question.status === "in-review") {
      question.status = "In Review";
    } else if (question.status.includes("published")) {
      delete question.status;
    }
  }

  return dispatch => {
    putIndependentQuestions(question, id, endpoint)
      .then(data => {
        if (status === "sendToReview") {
          dispatch({
            type: SET_STATUS,
            payload: "in-review"
          });
          notificationSystem("Sent to review", "success");
        } else if (status === "Denied") {
          notificationSystem("Sent back", "success");
        } else {
          notificationSystem("Successful", "success");
        }
      })
      .catch(err => {
        notificationSystem(err.response.data.details);
      });
  };
}

export function clearIndependentQuestion() {
  return {
    type: CLEAR_INDEPENDENT_QUESTION,
    payload: []
  };
}

export function addIndependentQuestion(
  questions = [],
  notificationSystem,
  status = "local"
) {
  let endpoint = "local";
  if (status === "Publish") {
    endpoint = "live";
  }
  return dispatch => {
    postIndependentQuestions(questions, endpoint)
      .then(data => {
        if (endpoint === "live") {
          dispatch({
            type: SET_ID,
            payload: data.data.data[0].id
          });
          dispatch({
            type: SET_STATUS,
            payload: "publish"
          });
          notificationSystem("Published successfully", "success", "tr");
        } else {
          notificationSystem("Successful", "success", "tr");
          dispatch({
            type: SET_ID,
            payload: data.data.data.id
          });
          dispatch({
            type: SET_STATUS,
            payload: status === "Published" ? "publish" : "draft"
          });
        }

        return dispatch({
          type: ADD_INDEPENDENT_QUESTION,
          payload: questions
        });
      })
      .catch(err => {
        console.log(err);
        notificationSystem("Error", "error", "tr");
      });
  };
}

export function duplicateIndependentQuestion(id) {
  return dispatch => duplicateIndependentQuestionById(id);
}
