import {
  ADD_PASSAGE,
  UPDATE_PASSAGE,
  RETRIEVE_ALL_PASSAGES,
  CLEAR_PASSAGE,
  GET_PASSAGE_BY_ID,
  ASSIGN_PASSAGE
} from "../actions/passage-actions";

export default function passageReducer(state = [], { type, payload }) {
  let prevState = "";
  switch (type) {
    case ADD_PASSAGE:
      return state;
    case UPDATE_PASSAGE:
      prevState = Object.assign([], state);
      return prevState;
    case RETRIEVE_ALL_PASSAGES:
      return payload;
    case GET_PASSAGE_BY_ID:
      return payload;
    case ASSIGN_PASSAGE:
      return state;
    case CLEAR_PASSAGE:
      return [];
    default:
      return state;
  }
}
