import Axios from "axios";

const api = require("../api/api-config").api;

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`
};

export function getContentPacks(userId) {
  return new Promise((resolve, reject) => {
    Axios.get(`${api}/user-generated-content?userId=${userId}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function postContentPack(request) {
  return new Promise((resolve, reject) => {
    Axios.post(`${api}/user-generated-content`, request)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}