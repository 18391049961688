import {getCommonCoreStandards} from "../../api/common-core-api";
export const GET_COMMONCORE = 'common-core:getAll';

export const getCommonCore = () => {
  return (dispatch) => {
    getCommonCoreStandards()
      .then((data) => {
        return dispatch({
          type: GET_COMMONCORE,
          payload: [...data.data.data]
        });
      })
      .catch((err) => {
        return dispatch({
          type: GET_COMMONCORE,
          payload: []
        });
      });
  }
}
