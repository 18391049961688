import React, {Fragment} from "react";
import {Button, Col, FormInput, FormSelect} from "shards-react";
import {QuestionType} from "../../../enums/QuestionType";
import PairingQuestionType from "./PairingQuestionType";

export const Answers = ({
                   handleAnswerChange,
                    row,
  state,
  order,
  removeRow,
  edit = false,
  currentStatus = null
                 }) => {

  const isOrdering = state.questionTypeId == QuestionType.DragAndDrop || state.questionTypeId == QuestionType.Order;
  const isPairing = state.questionTypeId == QuestionType.Pair
  const isOtherQuestionTypes  =  !isOrdering && !isPairing;
 return (
    <Fragment>
      {!isPairing &&
        <Col md="6" className="form-group">
          <label htmlFor="answerLabel">Answers</label>
          <FormInput
            onChange={evt => handleAnswerChange(evt, row.id)}
            name="label"
            value={row.label}
            id="answerLabel"
          />
        </Col>
      }
      {isPairing &&  <PairingQuestionType row={row} onChange={evt => handleAnswerChange(evt, row.id)} />}
      { isOrdering && (
        <Col md="4" className="form-group">
          <label htmlFor="order">Order</label>
          <FormSelect
            name="value"
            onChange={evt => handleAnswerChange(evt, row.id)}
            id="order"
          >
            <option>Choose...</option>
            {order.map((e, i) => {
              if (e === row.value) {
                return (
                  <option key={i} value={e} selected>
                    {e}
                  </option>
                );
              } else {
                return (
                  <option key={i} value={e}>
                    {e}
                  </option>
                );
              }
            })}
          </FormSelect>
        </Col>
      ) }

      {isOtherQuestionTypes && (
        <Col md="4" className="form-group">
        <label htmlFor="feInputState">Correct/Incorrect</label>
        <FormSelect
        name="value"
        onChange={evt => handleAnswerChange(evt, row.id)}
        id="feInputState"
        >
        <option>Choose...</option>
        <option value={true} selected={row.value}>Correct</option>
        <option value={false}   selected={!row.value ? true : false}>Incorrect</option>
        </FormSelect>
        </Col>)}

      <Col md="2" className="form-group">
        {
          edit && currentStatus !== "in-review" ? (
              <>
                <label htmlFor="feInputCountry">Actions</label>
                <br />
                <Button
                  theme="danger"
                  onClick={() => removeRow(row.id)}
                >
                  remove
                </Button>
              </>
            ) : (
              ""
            )
        }
        {!edit &&  <>
        <label htmlFor="feInputCountry">Actions</label>
        <br />
        <Button theme="danger" onClick={() => removeRow(row.id)}>
          remove
        </Button>
       </>}
      </Col>
    </Fragment>
  )
}
