import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import {
  NavItem,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Dropdown
} from "shards-react";

const SidebarNavItemDropdown = ({ item, onLogOut }) => {
  const [state, setState] = useState({
    open: false
  });

  const toggleDropdown = () => {
    setState({
      open: !state.open
    });
    return state.open;
  };

  const keepSideBarOpen = () => {
    setState({
      open: true
    });
  };

  return (
    <>
      <NavItem>
        <Dropdown open={state.open} toggle={toggleDropdown}>
          <DropdownToggle nav caret>
            <span
              onClick={() => (item.logout ? onLogOut() : null)}
              to={item.to}
            >
              {item.htmlBefore && (
                <div
                  className="d-inline-block item-icon-wrapper"
                  dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                />
              )}
              {item.title && <span>{item.title}</span>}
              {item.htmlAfter && (
                <div
                  className="d-inline-block item-icon-wrapper"
                  dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                />
              )}
            </span>
          </DropdownToggle>
          <DropdownMenu>
            {item.submenu.map((e, i) => {
              return (
                <NavLink
                  activeClassName="active"
                  key={i}
                  onClick={keepSideBarOpen}
                  to={{ pathname: e.to, state: e.title.toLowerCase() }}
                >
                  <DropdownItem>{e.title}</DropdownItem>
                </NavLink>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </NavItem>
    </>
  );
};

SidebarNavItemDropdown.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItemDropdown;
