import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  Row
} from "shards-react";
import {
  assignPassages,
  getAllPassages
} from "../../redux/actions/passage-actions";
import { getAllUsers } from "../../redux/actions/user-actions";
import AddQuestions from "../../views/question/passage/AddQuestions";
import PageTitle from "../common/PageTitle";
import Loader from "../loader/Loader";

const Viewer = props => {
  useEffect(() => {
    console.log(props);
    const { viewType } = props.match.params;
    if (viewType === "passages") {
      props.getAllPassages("published");
    }
    props.getUsers();
  }, []);

  let content = "";
  if (props.match.params.viewType === "passages") {
    const passage = props.passages.filter(e => {
      return e.id === Number(props.match.params.id);
    });
    content = (
      <PassageView
        users={props.users}
        passages={props.passages}
        {...props}
        {...passage[0]}
        match={props.match}
        triggerNotification={props.triggerNotification}
        assignPassage={props.assignPassage}
      />
    );
  } else {
    content = "question";
  }
  return props.passages.length > 0 ? <> {content} </> : <Loader />;
};
const styles = {
  fontSize: "18px"
};
const PassageView = props => {
  console.log(props);
  const [state, setState] = useState({
    passageId: props.id,
    userId: null,
    numOfQuestions: null
  });

  const [view, setView] = useState({
    isPassageView: true
  });
  const handleChange = evt => {
    setState({ ...state, [evt.target.name]: evt.target.value });
  };

  const assignPassage = () => {
    if (state.userId === null || state.numOfQuestions === null) {
      props.triggerNotification("All fields must be filled");
    } else {
      props.assignPassage(state, props.triggerNotification);
    }
  };
  const switchView = () => {
    setView({
      isPassageView: !view.isPassageView
    });
  };

  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title={props.name}
          subtitle=""
          className="text-sm-left"
          l
        />
        <Col sm="8" className="float-right text-right">
          <Button onClick={switchView}>
            {view.isPassageView ? "Write question" : "see passage"}
          </Button>
        </Col>
      </Row>

      {view.isPassageView ? (
        <Card>
          <CardHeader style={{ overflow: "auto" }}>
            {props.text ? (
              <span
                style={styles}
                dangerouslySetInnerHTML={{ __html: props.text }}
              />
            ) : (
              ""
            )}
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="4">
                <h4>Genre:</h4>
                {props.genre ? <p style={styles}>{props.genre.name}</p> : ""}
              </Col>
              <Col sm="4">
                <h4>Grade:</h4>
                {props.grade ? <p style={styles}>{props.grade.name}</p> : ""}
              </Col>
              <Col sm="4">
                <h4>Passage Length:</h4>
                {props.passageLength ? (
                  <p style={styles}>{props.passageLength.name}</p>
                ) : (
                  ""
                )}
              </Col>
              <Col sm="4">
                <h4>Reading Level</h4>
                <p style={styles}>{props.readingLevel}</p>
              </Col>
              <Col sm="4">
                {props.match.url.includes("lead") && (
                  <>
                    <h4>Number of Questions:</h4>
                    <p style={styles}>{props.numOfQuestions}</p>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            {props.match.url.includes("lead") ? (
              <Row form>
                <Col sm="4">
                  <FormSelect name="userId" onChange={handleChange}>
                    <option value={null}>User</option>
                    {props.users.length > 0
                      ? props.users
                        .map((e, i) => {
                          return (
                            <option key={i} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })
                      : ""}
                  </FormSelect>
                </Col>
                <Col sm="4">
                  <FormInput
                    placeholder={"Number of Questions"}
                    type="number"
                    onChange={handleChange}
                    name="numOfQuestions"
                  />
                </Col>
                <Col sm="4">
                  <Button outline theme="info" onClick={assignPassage}>
                    Assign Passage
                  </Button>
                </Col>
              </Row>
            ) : null}
          </CardFooter>
        </Card>
      ) : (
        <>
          {" "}
          {/* {props.match.url.includes("teacher") ? ( */}
          <AddQuestions selected={props.id} {...props} />
          {/* ) : null}{" "} */}
        </>
      )}
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    question: state.questions,
    passages: state.passages,
    users: state.userData.users,
    skills: state.skills
  };
};

const mapActionToProps = dispatch => {
  return {
    getAllPassages: bindActionCreators(getAllPassages, dispatch),
    assignPassage: bindActionCreators(assignPassages, dispatch),
    getUsers: bindActionCreators(getAllUsers, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(Viewer);
