import Axios from "axios";

const api = require("../api/api-config");

export function getAllQuestionType() {
  Axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };
  return new Promise((resolve, reject) => {
    Axios.get(api.api + "/question-types")
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

// export function getGenres() {
//     return new Promise((resolve, reject) => {

//     });
// }
// export function getGenres() {
//     return new Promise((resolve, reject) => {

//     });
// }
// export function getGenres() {
//     return new Promise((resolve, reject) => {

//     });
// }
