import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAllPassages,
  clearPassages
} from "../redux/actions/passage-actions";
import { getAllUsers } from "../redux/actions/user-actions";
import {
  getAllQuestions,
  clearQuestion,
  updateQuestion
} from "../redux/actions/question-actions";
import { setStatus } from "../redux/actions/status-actions";

const ReviewQuestions = props => {
  useEffect(() => {
    props.getPassages("published");
    props.getQuestions("in-review");
    props.setStatus("in-review");
    props.getUsers();
    return () => {
      props.clearPassages();
      props.clearQuestions();
    };
  }, []);

  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Review Questions"
          subtitle=""
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col lg="12">
          <Table
            filter={true}
            {...props}
            tableData={props.questions}
            tableType="review-questions"
            filterType="review-questions"
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    passages: state.passages,
    users: state.userData.users,
    questions: state.questions
  };
};

const mapActionToProps = dispatch => {
  return {
    getPassages: bindActionCreators(getAllPassages, dispatch),
    clearPassages: bindActionCreators(clearPassages, dispatch),
    getUsers: bindActionCreators(getAllUsers, dispatch),
    getQuestions: bindActionCreators(getAllQuestions, dispatch),
    clearQuestions: bindActionCreators(clearQuestion, dispatch),
    updateQuestion: bindActionCreators(updateQuestion, dispatch),
    setStatus: bindActionCreators(setStatus, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(ReviewQuestions);
