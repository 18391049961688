import {
  getAllUserPassages,
  markTeacherAsDone
} from "../../api/user-passage-api";

export const getUserPassages = "userPassage:getUserPassages";
export const userPassageDone = "userPassage:markTeacherDone";

export function retrieveUserPassages() {
  return dispatch => {
    getAllUserPassages()
      .then(data => {
        dispatch({
          type: getUserPassages,
          payload: data.data.data
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  };
}

export function markTeacherComplete(id, notificationSystem) {
  return dispatch => {
    markTeacherAsDone(id)
      .then(data => {
        notificationSystem("Success", "success");
        dispatch({
          type: userPassageDone,
          payload: data.data.data
        });
      })
      .catch(err => {
        notificationSystem(err.response.data.details, "success");
        console.log(err.response);
      });
  };
}
