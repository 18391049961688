import { RETRIEVE_ALL_SUBJECTS } from "../actions/subject-actions";


export default function subjectReducer(state = [], {type, payload}) {
    switch (type) {
        case RETRIEVE_ALL_SUBJECTS:
            return payload;
        default:
            return state;
    }
}
