import { getPassageLengths } from "../../api/passage-length-api";

export const RETRIEVE_ALL_PASSAGELENGTHS = 'passage:passageLengths';

export function getAllPassageLengths(genres = []) {
    return (dispatch) => {
        getPassageLengths()
        .then((data) => {
            return dispatch({
                type: RETRIEVE_ALL_PASSAGELENGTHS,
                payload: [...data.data.data]
            });
        })
        .catch((err) => {
            return dispatch({
                type: RETRIEVE_ALL_PASSAGELENGTHS,
                payload: genres
            });
        });
    }
}