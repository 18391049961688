import {
  RETRIEVE_ALL_QUESTIONS,
  ADD_QUESTION,
  CLEAR_QUESTION,
  UPDATE_QUESTION
} from "../actions/question-actions";

export default function questionReducer(state = [], { type, payload }) {
  switch (type) {
    case RETRIEVE_ALL_QUESTIONS:
      return payload;
    case ADD_QUESTION:
      return state;
    case CLEAR_QUESTION:
      return [];
    case UPDATE_QUESTION:
      return state;
    default:
      return state;
  }
}
