import { getUserSkills, userSkillsDone } from "../actions/user-skills-actions";

export function userSkillsReducer(state = [], { type, payload }) {
  switch (type) {
    case getUserSkills:
      return [...payload];
    case userSkillsDone:
      return state;
    default:
      return state;
  }
}
