import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAllQuestions,
  clearQuestion
} from "../redux/actions/question-actions";
import {
  getAllPassages,
  clearPassages
} from "../redux/actions/passage-actions";
import { setStatus } from "../redux/actions/status-actions";

class Questions extends React.Component {
  componentDidMount() {
    this.retrieveData();
  }

  retrieveData = () => {
    this.props.setStatus(this.props.match.params.status);
    if (this.props.match.params.status === "draft") {
      this.props.getQuestions(
        this.props.match.params.status,
        this.props.userData.user.name
      );
    } else {
      this.props.getQuestions(this.props.match.params.status);
    }
    this.props.getPassages("published");
  };

  componentWillUnmount() {
    this.props.clearQuestions();
    this.props.clearPassages();
    console.log("unmounting Questions");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.status !== this.props.match.params.status) {
      this.props.triggerNotification(
        `changed to ${this.props.match.params.status}`,
        "info",
        "tr"
      );
      this.retrieveData();
    }
  }

  render() {
    let addQuestionLink = "/writer/questions/new";
    if (this.props.match.url.includes("/teacher")) {
      addQuestionLink = "/teacher/questions/new";
    } else if (this.props.match.url.includes("/lead+")) {
      addQuestionLink = "/lead+/questions/new";
    }
    let tableData = this.props.questions;
    if (this.props.match.params.id) {
      tableData = tableData.filter(e => {
        return e.passage.id === Number(this.props.match.params.id);
      });
    }
    let mainTitle = "questions";
    if (this.props.currentStatus === "draft") {
      mainTitle = "questions in draft";
    } else if (this.props.currentStatus === "in-review") {
      mainTitle = "questions in review";
    } else if (this.props.currentStatus === "published") {
      mainTitle = "questions in published";
    } else if (this.props.currentStatus === "denied") {
      mainTitle = "questions in denied";
    }
    return (
      <Container>
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title={`All ${mainTitle}`}
            subtitle=""
            className="text-sm-left"
          />
          <Col sm="8" className="text-right">
            <Link to={addQuestionLink}>
              <Button size="sm" theme="white">
                <i className="fas fa-plus" />
                &nbsp; Add new question
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Table
              tableType={"questions"}
              {...this.props}
              tableData={tableData}
              filter={true}
              filterType={"all-questions"}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  questions: state.questions,
  passages: state.passages,
  currentStatus: state.currentStatus,
  userData: state.userData
});

const mapActionToProps = dispatch => ({
  getQuestions: bindActionCreators(getAllQuestions, dispatch),
  getPassages: bindActionCreators(getAllPassages, dispatch),
  clearQuestions: bindActionCreators(clearQuestion, dispatch),
  clearPassages: bindActionCreators(clearPassages, dispatch),
  setStatus: bindActionCreators(setStatus, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(Questions);
