import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAllPassages,
  clearPassages
} from "../redux/actions/passage-actions";
import { setStatus } from "../redux/actions/status-actions";

class Passages extends React.Component {
  componentDidMount() {
    this.retrieveData();
  }

  retrieveData = () => {
    const { status, disabled } = this.props.match.params;
    if (Number(disabled) === 1) {
      this.props.setStatus("publish");
      this.props.getPassages("disabled");
    } else {
      this.props.setStatus(status);
      if (this.props.match.url.includes("assigned-passages")) {
        this.props.getPassages(status, true);
      } else {
        this.props.getPassages(status);
      }
    }
  };

  componentWillUnmount() {
    this.props.clearPassages();
    console.log("unmounting Passages");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.status !== this.props.match.params.status) {
      let status = this.props.match.params.status;
      if (this.props.match.params.status === "disabled") {
        status = "ready passages";
      }
      this.props.triggerNotification(`changed to ${status}`, "info", "tr");
      this.retrieveData();
    }
  }

  publishPassage = id => {
    this.props.publishPassage(
      "publishToGame",
      {
        disabled: 0,
        pending: 0
      },
      id,
      this.props.triggerNotification
    );
    setTimeout(() => {
      this.retrieveData();
    }, 500);
  };

  render() {
    let passageData = this.props.passages;
    console.log(passageData);
    let mainTitle = "Passages";
    if (this.props.currentStatus === "draft") {
      mainTitle = "passages in draft";
    } else if (this.props.match.url.includes("/assigned-passages")) {
      mainTitle = "passages assigned";
    } else if (this.props.currentStatus === "published") {
      mainTitle = "passages in published";
    } else if (this.props.match.url.includes("disabled/1")) {
      mainTitle = "ready passages";
    }
    return (
      <Container>
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title={`All ${mainTitle}`}
            subtitle=""
            className="text-sm-left"
          />
          <Col sm="8" className="text-right">
            {!this.props.match.url.includes("writer") ||
            this.props.match.url.includes("assigned-passages") ? (
              ""
            ) : (
              <Link to="/writer/passages/new">
                <Button size="sm" theme="white">
                  <i className="fas fa-plus" />
                  &nbsp; Add new passage
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Table
              tableType="passages"
              {...this.props}
              handlePublishPassage={this.publishPassage}
              tableData={passageData}
              filterType={"all-passages"}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  passages: state.passages,
  currentStatus: state.currentStatus
  // books: state.books
});

const mapActionToProps = dispatch => ({
  getPassages: bindActionCreators(getAllPassages, dispatch),
  clearPassages: bindActionCreators(clearPassages, dispatch),
  setStatus: bindActionCreators(setStatus, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(Passages);
