import { getSubjects } from "../../api/subjects-api";

export const RETRIEVE_ALL_SUBJECTS = "subjects:retrieveAllSubjects";
export function getAllSubjects() {
  return dispatch => {
		getSubjects()
      .then(data => {
        dispatch({
          type: RETRIEVE_ALL_SUBJECTS,
          payload: [...data.data.data]
        });
      })
      .catch(err => {
        dispatch({
          type: RETRIEVE_ALL_SUBJECTS
        });
      });
  };
}