import Axios from "axios";

const api = require("../api/api-config");

export function getSkillGroups(isAssigned) {
  Axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };
  return new Promise((resolve, reject) => {
    Axios.get(api.api + "/skill-groups")
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
