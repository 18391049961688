import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Col,
  Container,
  FormInput,
  FormSelect,
  Row
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { addBook } from "../redux/actions/book-actions";

class AddNewBook extends React.Component {
  state = {
    genreId: null,
    title: null
  };

  handleChange = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  };

  addBook = () => {
    if (
      this.state.title !== null &&
      this.state.title !== "" &&
      (this.state.genreId !== null && this.state.genreId !== "")
    ) {
      this.props.addBook(this.state, this.props.triggerNotification);
    } else {
      this.props.triggerNotification(
        "Title and genre must be entered",
        "info",
        "tr"
      );
    }
  };

  render() {
    console.log(this.props);
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Add New Book"
            subtitle=""
            className="text-sm-left"
          />
        </Row>
        <Row form>
          <Col sm="4" className="form-group">
            <label htmlFor="">Title</label>
            <FormInput onChange={this.handleChange} name="title" type="text" />
          </Col>
          <Col sm="4" className="form-group">
            <label htmlFor="">Author</label>
            <FormInput onChange={this.handleChange} name="author" type="text" />
          </Col>
          <Col sm="4" className="form-group">
            <label htmlFor="">genre</label>
            <FormSelect onChange={this.handleChange} name="genreId">
              <option value={null}>Choose</option>
              {this.props.genre.map((e, i) => {
                return (
                  <option key={i} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </FormSelect>
          </Col>

          <Col sm="4" className="form-group">
            <label htmlFor="">Year</label>
            <FormInput onChange={this.handleChange} name="year" type="text" />
          </Col>
          <Col sm="4" className="form-group">
            <label htmlFor="">Publisher Rights</label>
            <FormInput
              onChange={this.handleChange}
              name="publisherRights"
              type="text"
            />
          </Col>
          <Col sm="4" className="form-group">
            <label htmlFor="">Description</label>
            <FormInput
              onChange={this.handleChange}
              name="description"
              type="text"
            />
          </Col>
          <Col sm="4" className="form-group">
            <label htmlFor="">Approximate grade level</label>
            <FormInput
              onChange={this.handleChange}
              name="approxGradeLevel"
              type="text"
            />
          </Col>
          <Col sm="4" className="form-group">
            <label htmlFor="">Link</label>
            <FormInput onChange={this.handleChange} name="link" type="text" />
          </Col>
          <Col sm="4" className="form-group">
            <label htmlFor="">Advisory</label>
            <FormInput
              onChange={this.handleChange}
              name="advisory"
              type="text"
            />
          </Col>
          <Col sm="4" className="form-group">
            <label htmlFor="">Additional Info</label>
            <FormInput
              onChange={this.handleChange}
              name="additionalInfo"
              type="text"
            />
          </Col>
          <Col sm="4" className="form-group">
            <label htmlFor="">Translated By, From (Year)</label>
            <FormInput
              onChange={this.handleChange}
              name="translatedBy"
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Button theme="dark" onClick={this.addBook}>
              Add Book
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionToProps = dispatch => {
  return {
    addBook: bindActionCreators(addBook, dispatch)
  };
};

export default connect(
  null,
  mapActionToProps
)(AddNewBook);
