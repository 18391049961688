import { RETRIEVE_ALL_PASSAGELENGTHS } from "../actions/passage-length-actions";



export default function passageLengthReducer(state = [], {type, payload}) {
    switch (type) {
        case RETRIEVE_ALL_PASSAGELENGTHS:
            return payload;
        default:
            return state;
    }
}
