import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAllIndependentQuestions,
  clearIndependentQuestion,
  duplicateIndependentQuestion
} from "../redux/actions/independent-question-actions";
import { setStatus } from "../redux/actions/status-actions";

class IndependentQuestions extends React.Component {
  componentDidMount() {
    this.retrieveData();
  }

  retrieveData = () => {
    this.props.setStatus(this.props.match.params.status);
    if (this.props.match.params.status === "draft") {
      this.props.getIndependentQuestions(
        this.props.match.params.status,
        this.props.userData.user.name
      );
    } else {
      let params = [this.props.match.params.status];
      if (["Teacher", "Lead"].includes(this.props.userData.user.role)) {
        params.push(this.props.userData.user.id);
      }
      this.props.getIndependentQuestions(...params);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.status !== this.props.match.params.status) {
      this.retrieveData();
    }
  }

  handleDuplicateClick = questionId => {
    this.props.duplicateIndependentQuestion(questionId)
      .then(({ data }) => {
        this.props.setCurrentStatus("draft");
        const { url } = this.props.match;
        const basUrl = url.split('/independent-questions')[0];
        this.props.history.push(`${basUrl}/independent-questions/edit/${data.data.id}`);
      })
      .catch(err => {
        console.log(err);
        this.props.triggerNotification(
          "There was an internal error processing your request.",
          "error"
        );
      })
  }

  render() {
    let addQuestionLink = "/writer/independent-questions/new";
    if (this.props.match.url.includes("/teacher")) {
      addQuestionLink = "/teacher/independent-questions/new";
    } else if (this.props.match.url.includes("/lead+")) {
      addQuestionLink = "/lead+/independent-questions/new";
    } else if (this.props.match.url.includes("/external-creator")) {
      addQuestionLink = "/external-creator/independent-questions/new";
    } else if (this.props.match.url.includes("/lead")) {
      addQuestionLink = "/lead/independent-questions/new";
    }
    let mainTitle = "Independent Questions";
    if (this.props.currentStatus === "draft") {
      mainTitle = `All ${mainTitle} in draft`;
      if (this.props.userData.user.role === 'External Creator') {
        mainTitle = 'My Drafts';
      }
    } else if (this.props.currentStatus === "in-review") {
      mainTitle = `All ${mainTitle} in review`;
    } else if (this.props.currentStatus === "published") {
      mainTitle = `All ${mainTitle} in published`;
      if (this.props.userData.user.role === 'External Creator') {
        mainTitle = 'Questions Pool';
      }
    } else if (this.props.currentStatus === "denied") {
      mainTitle = `All ${mainTitle} in denied`;
    }
    return (
      <Container>
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title={mainTitle}
            subtitle=""
            className="text-sm-left"
          />
          <Col sm="8" className="text-right">
            <Link to={addQuestionLink}>
              <Button size="sm" theme="white">
                <i className="fas fa-plus" />
                &nbsp; Add new question
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Table
              tableType={"independent-questions"}
              {...this.props}
              tableData={this.props.independentQuestions}
              filter={true}
              filterType={"all-questions"}
              handleDuplicateClick={this.handleDuplicateClick}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  independentQuestions: state.independentQuestions,
  currentStatus: state.currentStatus,
  userData: state.userData
});

const mapActionToProps = dispatch => ({
  getIndependentQuestions: bindActionCreators(
    getAllIndependentQuestions,
    dispatch
  ),
  clearIndependentQuestions: bindActionCreators(
    clearIndependentQuestion,
    dispatch
  ),
  setStatus: bindActionCreators(setStatus, dispatch),
  duplicateIndependentQuestion: bindActionCreators(
    duplicateIndependentQuestion,
    dispatch
  ),
  setCurrentStatus: bindActionCreators(setStatus, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(IndependentQuestions);
