import {getCommonCoreIndependentStandards} from "../../api/common-core-api";
export const GET_COMMONCORE_INDEPENDENT = 'common-core-independent:getAll';

export const getCommonCoreIndependent = () => {
  return (dispatch) => {
    getCommonCoreIndependentStandards()
      .then((data) => {
        return dispatch({
          type: GET_COMMONCORE_INDEPENDENT,
          payload: [...data.data.data]
        });
      })
      .catch((err) => {
        return dispatch({
          type: GET_COMMONCORE_INDEPENDENT,
          payload: []
        });
      });
  }
}
