import React from "react";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { Card, CardBody, Form, FormInput } from "shards-react";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import "../../assets/remove-buttons.css";
import { uploadBase64 } from "../../api/upload-api";
import { bindActionCreators } from "redux";
import { Delta } from "quill";
import { Quill } from "react-quill";

let Inline = Quill.import('blots/inline');

/**
 * Overrides Italic for italic editor
 */
class ItalicBlot extends Inline { };
ItalicBlot.blotName = 'italic';
ItalicBlot.tagName = 'i';
Quill.register('formats/italic', ItalicBlot);

/**
 * ReactQuill loses focus when dynamically rendering custom handlers
 * That's why this constant lives outside the FC
 * @link https://stackoverflow.com/questions/59825450/react-quill-custom-image-handler-module-causing-typing-issues-with-the-editor
 **/
const handlers = {
  image: function () {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = function () {
      const file = input.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64Image = reader.result;
        try {
          const uploadResponse = await uploadBase64(base64Image);
          const range = this.quill.getSelection();
          const { url } = uploadResponse.data.data;
          this.quill.insertEmbed(range.index, 'image', url);
        } catch (error) {
          if (error.response && error.response.data.err == 'FILE_SIZE') {
            alert('File size must be equal or less than 124kb for loading performance reasons. Please, optimize or reduce the size of the image to match our requirements.');
          }
        }
      }
    }.bind(this);
  }
};

const Editor = (props) => {
  const formatValue = () => {
    const { text } = props;
    if (text) {
      return text.replace(/\n/g, '<br>');
    }
    return null;
  }

  const handleQuillChange = (evt) => {
    const filteredStyleText = evt.replace(/ style=("|\')(.*?)("|\')/g, "");
    let filteredSpanText = filteredStyleText.replace(/<span/g, "<p");
    filteredSpanText = filteredSpanText.replace(/<\/span/g, "</p");
    props.handleQuillChange(filteredSpanText);
  }

  return (
    <Card small className="mb-3">
      <CardBody>
        <Form className="add-new-post">
          {
            props.showTitle ?
              <FormInput name="name" onChange={props.handleChange} size="lg" className="mb-3" defaultValue={props.name} placeholder="Your Post Title" />
              :
              null
          }
          <ReactQuill
            name={props.editorName || 'text'}
            onChange={handleQuillChange}
            defaultValue={formatValue()}
            className="add-new-post__editor mb-1"
            {...props}
            modules={{
              toolbar: {
                container: props.toolbar,
                handlers
              }
            }}
          />
        </Form>
      </CardBody>
    </Card>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    uploadBase64: bindActionCreators(uploadBase64, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Editor);
