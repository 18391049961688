import Axios from "axios";

const api = require("../api/api-config");

export function getAllUserSkills() {
  return new Promise((resolve, reject) => {
    Axios.get(api.api + "/skills/users")
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function markTeacherAsDone(id) {
  return new Promise((resolve, reject) => {
    Axios.put(api.api + `/user-passages/${id}`, { status: "Done" })
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
