import { GET_STATUS, SET_STATUS } from "../actions/status-actions";

export default function skillReducer(state = [], { type, payload }) {
  switch (type) {
    case GET_STATUS:
      return state;
    case SET_STATUS:
      return payload;
    default:
      return state;
  }
}
