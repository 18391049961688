export default function() {
  return [
    {
      title: "Questions",
      to: "/external-creator/independent-questions/new",
      htmlBefore: '<i class="material-icons">chat</i>',
      submenu: [
				{
					title: "Add New Question",
					to: "/external-creator/independent-questions/new"
        },
        {
          title: "My Drafts",
          to: "/external-creator/independent-questions/all/draft"
        },
        {
					title: "Questions Pool",
					to: "/external-creator/independent-questions/all/published"
				},
      ]
		},
		{
      title: "Content Packs",
      to: "/external-creator/content-pack/new",
      htmlBefore: '<i class="material-icons">chat</i>',
      submenu: [
				{
					title: "Add new Content Pack",
					to: "/external-creator/content-pack/new"
				},
        {
          title: "My Content Packs",
          to: "/external-creator/content-pack/all"
        }
      ]
    },
    {
      title: "Logout",
      htmlBefore: '<i class="material-icons">backspace</i>',
      to: "/login",
      logout: true
    }
  ];
}
