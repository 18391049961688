import React from "react";
import { Redirect } from "react-router-dom";
// Layout Types
import { DefaultLayout, NoLayout } from "./layouts";
import AddNewBook from "./views/AddNewBook";
// Route Views
// import BlogOverview from "./views/BlogOverview";
import AddNewPost from "./views/AddNewPassage";
import AddQuestions from "./views/question/passage/AddQuestions";
import AddIndependentQuestions from "./views/question/independent/AddIndependentQuestions";
import Books from "./views/Books";
import EditBook from "./views/EditBook";
import EditPassage from "./views/EditPassage";
import EditQuestions from "./views/question/passage/EditQuestions";
import EditIndependentQuestions from "./views/question/independent/EditIndependentQuestions";
// import BlogPosts from "./views/BlogPosts";
import Login from "./views/Login";
import ManageUsers from "./views/ManageUsers";
import Passages from "./views/Passages";
import Skills from "./views/Skills";
import Questions from "./views/Questions";
import IndependentQuestions from "./views/IndependentQuestions";
import ReviewQuestions from "./views/ReviewQuestionsMain";
import ReviewIndependentQuestions from "./views/ReviewIndependentQuestionsMain";
import AssignPassages from "./views/AssignPassages";
import AssignSkills from "./views/AssignSkills";
import LeadDashBoard from "./layouts/LeadDashBoard";
import TeacherDashboard from "./layouts/TeacherDashboard";
import ExternalCreatorDashboard from "./layouts/ExternalCreatorDashboard";
import Errors from "./views/Errors";
import Viewer from "./components/viewer/Viewer";
import WorkInProgress from "./views/WorkInProgress";
import IndependentWorkInProgress from "./views/IndependentWorkInProgress";
import LeadWriterDashboard from "./layouts/LeadWriterDashboard";
import ErrorBoundary from "./ErrorBoundary";
import AddContentPack from "./views/AddContentPack";
import AllContentPacks from "./views/AllContentPacks";
import DashboardAuth from "./views/DashboardAuth";

export default [
  {
    path: "/",
    exact: true,
    layout: NoLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/login",
    exact: true,
    layout: NoLayout,
    component: Login
  },
  {
    path: "/writer",
    exact: true,
    layout: DefaultLayout,
    protected: false,
    component: () => <Redirect to="/writer/review-questions" />
  },
  {
    path: "/writer/review-questions",
    layout: DefaultLayout,
    exact: true,
    protected: true,
    component: ReviewQuestions
  },
  {
    path: "/writer/review-independent-questions",
    layout: DefaultLayout,
    exact: true,
    protected: true,
    component: ReviewIndependentQuestions
  },
  {
    path: "/writer/passages/all/:status/:disabled?",
    exact: false,
    layout: DefaultLayout,
    protected: true,
    component: Passages
  },
  {
    path: "/writer/questions/all/:status/:id?",
    exact: false,
    layout: DefaultLayout,
    protected: true,
    component: Questions
  },
  {
    path: "/writer/questions/edit/:id/:review?",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: EditQuestions
  },
  {
    path: "/writer/questions/new",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: AddQuestions
  },
  {
    path: "/writer/independent-questions/all/:status/:id?",
    exact: false,
    layout: DefaultLayout,
    protected: true,
    component: IndependentQuestions
  },
  {
    path: "/writer/independent-questions/edit/:id/:review?",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: EditIndependentQuestions
  },
  {
    path: "/writer/independent-questions/new",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: AddIndependentQuestions
  },
  {
    path: "/writer/passages/new",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: AddNewPost
  },
  {
    path: "/writer/passages/edit/:id",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: EditPassage
  },
  {
    path: "/writer/books",
    exact: true,
    protected: true,
    layout: DefaultLayout,
    component: Books
  },
  {
    path: "/writer/books/edit/:id",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: EditBook
  },
  {
    path: "/writer/books/new",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: AddNewBook
  },
  {
    path: "/writer/manage-users",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: ManageUsers
  },
  {
    path: "/writer/assigned-passages/:status",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: Passages
  },
  // lead dashboard
  {
    path: "/lead",
    exact: true,
    layout: LeadDashBoard,
    protected: false,
    component: () => <Redirect to="/lead/assign-passages" />
  },
  {
    path: "/lead/assign-passages",
    exact: true,
    layout: LeadDashBoard,
    protected: true,
    component: AssignPassages
  },
  {
    path: "/lead/passages/:status",
    exact: true,
    layout: LeadDashBoard,
    protected: true,
    component: Passages
  },
  {
    path: "/lead/work-in-progress",
    exact: true,
    layout: LeadDashBoard,
    protected: true,
    component: WorkInProgress
  },
  {
    path: "/lead/manage-users",
    exact: true,
    layout: LeadDashBoard,
    protected: true,
    component: ManageUsers
  },
  {
    path: "/lead/viewer/:viewType?/:status?/:id?",
    exact: true,
    layout: LeadDashBoard,
    protected: true,
    component: Viewer
  },
  {
    path: "/lead/independent-questions/all/:status/:id?",
    exact: false,
    layout: LeadDashBoard,
    protected: true,
    component: IndependentQuestions
  },
  {
    path: "/lead/independent-questions/edit/:id/:review?",
    exact: true,
    layout: LeadDashBoard,
    protected: true,
    component: EditIndependentQuestions
  },
  {
    path: "/lead/independent-questions/new",
    exact: true,
    layout: LeadDashBoard,
    protected: true,
    component: AddIndependentQuestions
  },
  //   lead + edit dashboard
  {
    path: "/lead+",
    exact: true,
    layout: LeadWriterDashboard,
    protected: false,
    component: () => <Redirect to="/lead+/assign-passages" />
  },
  {
    path: "/lead+/review-independent-questions",
    layout: LeadWriterDashboard,
    exact: true,
    protected: true,
    component: ReviewIndependentQuestions
  },
  {
    path: "/lead+/assign-passages",
    exact: true,
    layout: LeadWriterDashboard,
    protected: false,
    component: AssignPassages
  },
  {
    path: "/lead+/assign-skills",
    exact: true,
    layout: LeadWriterDashboard,
    protected: false,
    component: AssignSkills
  },
  {
    path: "/lead+/independent-questions/work-in-progress",
    exact: true,
    layout: LeadWriterDashboard,
    protected: false,
    component: IndependentWorkInProgress
  },
  {
    path: "/lead+/independent-questions/edit/:id/:review?",
    exact: true,
    layout: LeadWriterDashboard,
    protected: true,
    component: EditIndependentQuestions
  },
  {
    path: "/lead+/review-questions",
    exact: true,
    layout: LeadWriterDashboard,
    protected: false,
    component: ReviewQuestions
  },
  {
    path: "/lead+/passages/:status",
    exact: true,
    layout: LeadWriterDashboard,
    protected: false,
    component: Passages
  },
  {
    path: "/lead+/work-in-progress",
    exact: true,
    layout: LeadWriterDashboard,
    protected: false,
    component: WorkInProgress
  },
  {
    path: "/lead+/questions/edit/:id/:review?",
    exact: true,
    layout: LeadWriterDashboard,
    protected: true,
    component: EditQuestions
  },
  {
    path: "/lead+/manage-users",
    exact: true,
    layout: LeadWriterDashboard,
    protected: false,
    component: ManageUsers
  },
  {
    path: "/lead+/questions/all/:status/:id?",
    exact: false,
    layout: LeadWriterDashboard,
    protected: true,
    component: Questions
  },
  {
    path: "/lead+/questions/edit/:id/:review?",
    exact: true,
    layout: LeadWriterDashboard,
    protected: true,
    component: EditQuestions
  },
  {
    path: "/lead+/questions/new",
    exact: true,
    layout: LeadWriterDashboard,
    protected: true,
    component: AddQuestions
  },
  {
    path: "/lead+/passages/all/:status/:disabled?",
    exact: false,
    layout: LeadWriterDashboard,
    protected: true,
    component: Passages
  },
  {
    path: "/lead+/independent-questions/all/:status/:id?",
    exact: false,
    layout: LeadWriterDashboard,
    protected: true,
    component: IndependentQuestions
  },
  {
    path: "/lead+/independent-questions/edit/:id/:review?",
    exact: true,
    layout: LeadWriterDashboard,
    protected: true,
    component: EditIndependentQuestions
  },
  {
    path: "/lead+/independent-questions/new",
    exact: true,
    layout: LeadWriterDashboard,
    protected: true,
    component: AddIndependentQuestions
  },
  // teacher dashboard
  {
    path: "/teacher",
    exact: true,
    layout: TeacherDashboard,
    protected: true,
    component: () => <Redirect to="/teacher/questions/all/draft" />
  },
  {
    path: "/teacher/questions/all/:status",
    exact: true,
    layout: TeacherDashboard,
    protected: true,
    component: Questions
  },
  {
    path: "/teacher/questions/new",
    exact: true,
    layout: TeacherDashboard,
    protected: true,
    component: AddQuestions
  },
  {
    path: "/teacher/questions/edit/:id",
    exact: true,
    layout: TeacherDashboard,
    protected: true,
    component: EditQuestions
  },
  {
    path: "/teacher/independent-questions/new",
    exact: true,
    layout: TeacherDashboard,
    protected: true,
    component: AddIndependentQuestions
  },
  {
    path: "/teacher/independent-questions/edit/:id/:review?",
    exact: true,
    layout: TeacherDashboard,
    protected: true,
    component: EditIndependentQuestions
  },
  {
    path: "/teacher/independent-questions/all/:status/:id?",
    exact: false,
    layout: TeacherDashboard,
    protected: true,
    component: IndependentQuestions
  },
  {
    path: "/teacher/passages/all/:status/:disabled?",
    exact: false,
    layout: TeacherDashboard,
    protected: true,
    component: Passages
  },
  {
    path: "/teacher/assigned-passages/:status",
    exact: true,
    layout: TeacherDashboard,
    protected: true,
    component: Passages
  },
  {
    path: "/teacher/assigned-skills/:status",
    exact: true,
    layout: TeacherDashboard,
    protected: true,
    component: Skills
  },
  //   Viewer components to view anything
  {
    path: "/writer/viewer/:viewType?/:status?/:id?",
    exact: true,
    layout: DefaultLayout,
    protected: true,
    component: Viewer
  },
  {
    path: "/teacher/viewer/:viewType?/:status?/:id?",
    exact: true,
    layout: TeacherDashboard,
    protected: true,
    component: Viewer
  },
  {
    path: "/lead+/viewer/:viewType?/:status?/:id?",
    exact: true,
    layout: LeadWriterDashboard,
    protected: true,
    component: Viewer
  },
  {
    path: "/test/:id",
    exact: true,
    layout: NoLayout,
    protected: false,
    component: ErrorBoundary
  },

  // External Creator routes
  {
    path: "/external-creator",
    exact: true,
    layout: ExternalCreatorDashboard,
    protected: true,
    component: () => <Redirect to="/external-creator/independent-questions/new" />
  },
  {
    path: "/external-creator/independent-questions/new",
    layout: ExternalCreatorDashboard,
    exact: true,
    protected: true,
    component: AddIndependentQuestions
  },
  {
    path: "/external-creator/independent-questions/edit/:id",
    exact: true,
    layout: ExternalCreatorDashboard,
    protected: true,
    component: EditIndependentQuestions
  },
  {
    path: "/external-creator/independent-questions/all/:status",
    layout: ExternalCreatorDashboard,
    exact: true,
    protected: true,
    component: IndependentQuestions
  },
  {
    path: "/external-creator/content-pack/new",
    exact: true,
    layout: ExternalCreatorDashboard,
    protected: false,
    component: AddContentPack
  },
  {
    path: "/external-creator/content-pack/all",
    exact: true,
    layout: ExternalCreatorDashboard,
    protected: false,
    component: AllContentPacks
  },
  {
    path: "/dashboard-auth",
    exact: true,
    layout: NoLayout,
    protected: false,
    component: DashboardAuth
  },
  {
    path: "*",
    exact: true,
    layout: NoLayout,
    protected: false,
    component: Errors
  }
];
