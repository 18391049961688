import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";
import getSidebarNavItemsLead from "../data/sidebar-nav-items-lead";
import getSidebarNavItemsTeacher from "../data/sidebar-nav-items-teacher";
import getSidebarNavItemsLeadWriter from "../data/sidebar-nav-items-lead-writer";
import getSidebarNavItemsExternalCreator from "../data/sidebar-nav-item-external-creator";

let _store = {
  menuVisible: false,
  navItems: getSidebarNavItems(),
  navItemsLead: getSidebarNavItemsLead(),
  navItemsTeacher: getSidebarNavItemsTeacher(),
  navItemsLeadWriter: getSidebarNavItemsLeadWriter(),
  navItemsExternalCreator: getSidebarNavItemsExternalCreator()
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems(type) {
    const userTypes = {
      lead: _store.navItemsLead,
      teacher: _store.navItemsTeacher,
      leadWriter: _store.navItemsLeadWriter,
      externalCreator: _store.navItemsExternalCreator
    }
    return userTypes[type] || _store.navItems;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
