import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect,
  FormInput,
} from "shards-react";

class SidebarCategories extends React.Component {

  readingLevel = () => {
    const data = [];
    for (let i = 0; i <= 8.9 * 10; i++) {
      if (i !== 0) {
        if (i / 10 < 1.0) {
          data.push((i / 10).toPrecision(1));
        } else {
          data.push((i / 10).toPrecision(2));
        }
      }
    }
    return data;
  };

  // Use case where the sidebar categories is being rendered when adding a passage
  add = () => {
    return (
      <Card small className="mb-3">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{this.props.title || ""}</h6>
        </CardHeader>
        <CardBody className="p-0">
          <ListGroup flush>
            <ListGroupItem className="d-flex px-3">
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Length</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="passageLengthId"
                >
                  <option>Choose</option>
                  {this.props.passageLength.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </FormSelect>
              </InputGroup>
              &nbsp;
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Genre</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="genreId"
                >
                  <option value={null}>Choose</option>
                  {this.props.genre.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </FormSelect>
              </InputGroup>
            </ListGroupItem>

            <ListGroupItem className="d-flex px-3">
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Grade</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="gradeId"
                >
                  <option value={null}>Choose</option>
                  {this.props.grades.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </FormSelect>
              </InputGroup>
              &nbsp;
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Reading Level</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="readingLevel"
                >
                  <option>Choose</option>
                  {this.readingLevel().map((e, i) => {
                    return <option key={i}>{e}</option>;
                  })}
                </FormSelect>
              </InputGroup>
            </ListGroupItem>

            <ListGroupItem className="d-flex px-3">
              {this.props.version !== "local" ? (
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>Disabled</InputGroupText>
                  </InputGroupAddon>
                  <FormSelect
                    onChange={this.props.handleSideBarCategories}
                    name="disabled"
                  >
                    <option value={null}>Choose</option>
                    <option value={1}>1</option>
                    <option value={0}>0</option>
                  </FormSelect>
                </InputGroup>
              ) : (
                ""
              )}
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Book</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="bookId"
                >
                  <option value={0}>Choose</option>
                  {this.props.books.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.title}
                      </option>
                    );
                  })}
                </FormSelect>
              </InputGroup>
            </ListGroupItem>
            {
              this.props.currentState.bookId
                ? this.props.currentState.bookId > 0 ?
                  <ListGroupItem>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>Passage Number</InputGroupText>
                      </InputGroupAddon>
                      <FormInput name="passageNumber" onChange={this.props.handleSideBarCategories} type="text" />
                    </InputGroup>
                  </ListGroupItem> : null : null
            }

            <ListGroupItem className="px-3">
              <h6>Citation (Optional)</h6>
              <InputGroup className="mb-12">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Title</InputGroupText>
                </InputGroupAddon>
                <FormInput name="citationTitle" placeholder="The Odyssey" onChange={this.props.handleSideBarCategories} />
              </InputGroup>

              <InputGroup className="mb-12">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Author</InputGroupText>
                </InputGroupAddon>
                <FormInput name="citationAuthor" placeholder="Homer" onChange={this.props.handleSideBarCategories} />
              </InputGroup>

              <InputGroup className="mb-12">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Year</InputGroupText>
                </InputGroupAddon>
                <FormInput name="citationYear" placeholder="2022" onChange={this.props.handleSideBarCategories} />
              </InputGroup>

              <InputGroup className="mb-12">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Citation Topic</InputGroupText>
                </InputGroupAddon>
                <FormInput name="citationTopic" placeholder="Epic seafaring" onChange={this.props.handleSideBarCategories} />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Citation Type</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="citationType"
                >
                  <option value={0}>Excerpt</option>
                  <option value={1}>Complete Story</option>
                </FormSelect>
              </InputGroup>

            </ListGroupItem>
            {this.props.currentState.citation &&
              <ListGroupItem className="px-3">
                <h6>Current Citation:</h6>
                {this.props.currentState.citation}
              </ListGroupItem>
            }

            {(!this.props.currentState.citation &&
              (
                this.props.currentState.citationTitle ||
                this.props.currentState.citationAuthor ||
                this.props.currentState.citationTopic ||
                this.props.currentState.citationYear
              )
            )
              &&
              <ListGroupItem className="px-3">
                <h6>Current Citation:</h6>
                {parseInt(this.props.currentState.citationType) !== 1 ? 'This is an excerpt from' : 'This is the complete story of'} {this.props.currentState.citationTitle || '_____'} by {this.props.currentState.citationAuthor || '_____'}, written in {this.props.currentState.citationYear || '_____'}. It is about {this.props.currentState.citationTopic || '_____'}.
              </ListGroupItem>
            }
            <ListGroupItem className="px-3">
              <h6>DEI</h6>
              <InputGroup className="mb-12">
                <InputGroupAddon type="prepend">
                  <InputGroupText>DEI</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="dei"
                >
                  <option value={0} selected={!this.props.currentState.dei}>False</option>
                  <option value={1} selected={this.props.currentState.dei}>True</option>
                </FormSelect>
              </InputGroup>
            </ListGroupItem>

          </ListGroup>
        </CardBody>
      </Card>
    );
  };

  // Use case where the sidebar categories is being rendered by edit passage
  edit = () => {
    return (
      <Card small className="mb-3">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{this.props.title || ""}</h6>
        </CardHeader>
        <CardBody className="p-0">
          <ListGroup flush>
            <ListGroupItem className="d-flex px-3">
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Length</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="passageLengthId"
                >
                  <option>Choose</option>
                  {this.props.passageLength.map((e, i) => {
                    if (this.props.currentPassage.passageLength) {
                      if (
                        e.name === this.props.currentPassage.passageLength.name
                      ) {
                        return (
                          <option key={i} value={e.id} selected>
                            {e.name}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={e.id}>
                            {e.name}
                          </option>
                        );
                      }
                    } else {
                      return (
                        <option key={i} value={e.id}>
                          {e.name}
                        </option>
                      );
                    }
                  })}
                </FormSelect>
              </InputGroup>
              &nbsp;
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Genre</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="genreId"
                >
                  <option value={null}>Choose</option>
                  {this.props.genre.map((e, i) => {
                    if (this.props.currentPassage.genre) {
                      if (e.name === this.props.currentPassage.genre.name) {
                        return (
                          <option key={i} value={e.id} selected>
                            {e.name}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={e.id}>
                            {e.name}
                          </option>
                        );
                      }
                    } else {
                      return (
                        <option key={i} value={e.id}>
                          {e.name}
                        </option>
                      );
                    }
                  })}
                </FormSelect>
              </InputGroup>
            </ListGroupItem>

            <ListGroupItem className="d-flex px-3">
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Grade</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="gradeId"
                >
                  <option value={null}>Choose</option>
                  {this.props.grades.map((e, i) => {
                    if (this.props.currentPassage.grade) {
                      if (e.name === this.props.currentPassage.grade.name) {
                        return (
                          <option key={i} value={e.id} selected>
                            {e.name}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={e.id}>
                            {e.name}
                          </option>
                        );
                      }
                    } else {
                      return (
                        <option key={i} value={e.id}>
                          {e.name}
                        </option>
                      );
                    }
                  })}
                </FormSelect>
              </InputGroup>
              &nbsp;
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Reading Level</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="readingLevel"
                >
                  <option>Choose</option>
                  {this.readingLevel().map((e, i) => {
                    if (this.props.currentPassage.readingLevel) {
                      if (
                        String(e) ===
                        String(this.props.currentPassage.readingLevel)
                      ) {
                        return (
                          <option key={i} value={e} selected>
                            {e}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={e}>
                            {e}
                          </option>
                        );
                      }
                    } else {
                      return (
                        <option key={i} value={e}>
                          {e}
                        </option>
                      );
                    }
                  })}
                </FormSelect>
              </InputGroup>
            </ListGroupItem>

            <ListGroupItem className="d-flex px-3">
              {this.props.version !== "local" ? (
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>Disabled</InputGroupText>
                  </InputGroupAddon>
                  <FormSelect
                    onChange={this.props.handleSideBarCategories}
                    name="disabled"
                  >
                    <option value={null}>Choose</option>
                    <option value={1}>1</option>
                    <option value={0}>0</option>
                  </FormSelect>
                </InputGroup>
              ) : (
                ""
              )}
              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Book</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="bookId"
                >
                  <option value={0}>Choose</option>
                  {this.props.books.map((e, i) => {
                    if (this.props.currentPassage.book) {
                      if (e.id === this.props.currentPassage.book.id) {
                        return (
                          <option key={i} value={e.id} selected>
                            {e.title}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={e.id}>
                            {e.title}
                          </option>
                        );
                      }
                    } else {
                      return (
                        <option key={i} value={e.id}>
                          {e.title}
                        </option>
                      );
                    }
                  })}
                </FormSelect>
              </InputGroup>
            </ListGroupItem>
            {
              this.props.currentPassage.book
                ? this.props.currentPassage.book.id > 0 ?
                  <ListGroupItem>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>Passage Number</InputGroupText>
                      </InputGroupAddon>
                      <FormInput defaultValue={this.props.currentPassage.passageNumber} name="passageNumber" onChange={this.props.handleSideBarCategories} type="text" />
                    </InputGroup>
                  </ListGroupItem> : null : null
            }

            <ListGroupItem className="px-3">
              <h6>Citation (Optional)</h6>
              <InputGroup className="mb-12">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Title</InputGroupText>
                </InputGroupAddon>
                <FormInput defaultValue={this.props.currentPassage.citationTitle} name="citationTitle" placeholder="The Odyssey" onChange={this.props.handleSideBarCategories} />
              </InputGroup>

              <InputGroup className="mb-12">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Author</InputGroupText>
                </InputGroupAddon>
                <FormInput defaultValue={this.props.currentPassage.citationAuthor} name="citationAuthor" placeholder="Homer" onChange={this.props.handleSideBarCategories} />
              </InputGroup>

              <InputGroup className="mb-12">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Year</InputGroupText>
                </InputGroupAddon>
                <FormInput name="citationYear" placeholder="2022" onChange={this.props.handleSideBarCategories} />
              </InputGroup>

              <InputGroup className="mb-12">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Citation Topic</InputGroupText>
                </InputGroupAddon>
                <FormInput defaultValue={this.props.currentPassage.citationTopic} name="citationTopic" placeholder="Epic seafaring" onChange={this.props.handleSideBarCategories} />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                  <InputGroupText>Citation Type</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="citationType"
                >
                  <option value={0}>Excerpt</option>
                  <option value={1}>Complete Story</option>
                </FormSelect>
              </InputGroup>
            </ListGroupItem>
            {this.props.currentPassage.citation &&
              <ListGroupItem className="px-3">
                <h6>Current Citation:</h6>
                {this.props.currentPassage.citation}
              </ListGroupItem>
            }
            {(!this.props.currentPassage.citation &&
              (
                this.props.currentPassage.citationTitle ||
                this.props.currentPassage.citationAuthor ||
                this.props.currentPassage.citationTopic ||
                this.props.currentPassage.citationYear
              )
            ) &&
              <ListGroupItem className="px-3">
                <h6>Current Citation:</h6>
                {parseInt(this.props.currentState.citationType) !== 1 ? 'This is an excerpt from' : 'This is the complete story of'} {this.props.currentState.citationTitle || '_____'} by {this.props.currentState.citationAuthor || '_____'}, written in {this.props.currentState.citationYear || '_____'}. It is about {this.props.currentState.citationTopic || '_____'}.
              </ListGroupItem>
            }
            <ListGroupItem className="px-3">
              <h6>DEI</h6>
              <InputGroup className="mb-12">
                <InputGroupAddon type="prepend">
                  <InputGroupText>DEI</InputGroupText>
                </InputGroupAddon>
                <FormSelect
                  onChange={this.props.handleSideBarCategories}
                  name="dei"
                >
                  <option value={0} selected={!this.props.currentPassage.dei}>False</option>
                  <option value={1} selected={this.props.currentPassage.dei}>True</option>
                </FormSelect>
              </InputGroup>
            </ListGroupItem>


          </ListGroup>
        </CardBody>
      </Card>
    );
  };

  render() {
    console.log(this.props.currentPassage);
    return this.props.isEdit ? this.edit() : this.add();
  }
}

SidebarCategories.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

SidebarCategories.defaultProps = {
  title: "Categories"
};

export default SidebarCategories;
