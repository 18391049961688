export const QuestionType = {
    MultipleChoice: 1,
    Highlight: 2,
    DragAndDrop: 3,
    SelectAll: 4,
    OpenEnded: 5,
    Pair: 6,
    Order: 7,
    TrueFalse: 8
}

export const QuestionCategory = {
    Passage: 'Passage',
    Independent: 'Independent'
}