import { QuestionType } from "../../enums/QuestionType";
import { generate } from "shortid";

/**
 * @description validates question answers
 * @param questionTypeId
 * @param answers
 * @param triggerNotification
 * @returns {boolean}
 */
export function validateQuestionAnswers(questionTypeId, answers, triggerNotification) {
  let isValid = true;
  if (Number(questionTypeId) === QuestionType.MultipleChoice) {
    if (answers.length <= 1) {
      triggerNotification(
        "Multiple choice answers need atleast one correct answer and one incorrect",
        "error"
      );
      isValid = false;
    } else {
      const correct = [];
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].value === true) {
          if (correct.length > 0) {
            triggerNotification(
              "Only one correct answer is allowed for multiple choice", "error"
            );
            isValid = false;
            break;
          } else {
            correct.push(1);
          }
        }
      }

      if (correct.length === 0) {
        triggerNotification(
          "You must have one correct answer",
          "error"
        );
        isValid = false;
      }
    }
  } else if (Number(questionTypeId) === QuestionType.TrueFalse) {
    if (answers.length > 2) {
      triggerNotification(
        "True and False questions can only have two answers, with one correct and one incorrect ",
        "error"
      );
      isValid = false;
    } else {
      const correct = [];
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].value === true) {
          if (correct.length > 0) {
            triggerNotification(
              "Only one correct answer is allowed for True and False question", "error"
            );
            isValid = false;
            break;
          } else {
            correct.push(1);
          }
        }
      }
      if (correct.length === 0) {
        triggerNotification(
          "You must have one correct answer",
          "error"
        );
        isValid = false;
      }
    }
  } else if (Number(questionTypeId) === QuestionType.OpenEnded) {
    if (answers.length >= 1) {
      triggerNotification(
        "Open Ended questions cannot have answer fields", "error"
      );
      isValid = false;
    }
  } else if (Number(questionTypeId) === QuestionType.SelectAll) {
    if (answers.length < 1) {
      triggerNotification(
        "Atleast one correct answer should be entered", "error"
      );
      isValid = false;
    } else {
      const correct = [];
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].value === true) {
          correct.push(1);
        }
      }
      if (correct.length === 0) {
        triggerNotification(
          "There should be atleast one correct answer",
          "error"
        );
      }
    }
  } else if (Number(questionTypeId) === QuestionType.DragAndDrop) {
    if (answers.length < 2) {
      triggerNotification(
        "Drag and drop needs to have atleast two answers.",
        "error"
      );
      isValid = false;
    }
  } else if (Number(questionTypeId) === QuestionType.Order) {
    if (answers.length < 2) {
      triggerNotification(
        "Order question type needs to have atleast two answer fields.",
        "error"
      );
      isValid = false;
    }
  } else if (Number(questionTypeId) === QuestionType.Pair) {
    if (answers.length === 0) {
      isValid = false;
    }
    answers.forEach((answer) => {
      if ((!answer.label && typeof answer.label === "string" && answer.label.trim().length === 0) || typeof answer.value === "boolean" || (typeof answer.value === "string" && answer.value.trim().length === 0)) {
        isValid = false;
      }
    })
    if (!isValid) {
      triggerNotification(
        "Pair answers cannot be empty",
        "error"
      );
    }
  }
  return isValid;
}

/**
 * @description validate and build answers by question type
 * @param questionTypeId
 * @param answers
 * @param status
 * @param triggerNotification
 * @param requestBuilder
 * @returns {{potentialAnswers: *, correctAnswers: *[], requestObject}|*|{potentialAnswers: *[], requestObject, correctAnswers: *[]}}
 */
export const validateAndBuildAnswersByQuestionType = (questionTypeId, answers, status, triggerNotification, requestBuilder) => {
  const numericQuestionTypeId = Number(questionTypeId);
  const isOrderQuestionType = numericQuestionTypeId === QuestionType.DragAndDrop || numericQuestionTypeId === QuestionType.Order;
  const isPairQuestionType = (numericQuestionTypeId === QuestionType.Pair);
  if (isOrderQuestionType) {
    return validateOrderQuestionType(answers, triggerNotification, status, requestBuilder)
  } else if (isPairQuestionType) {
    return validatePairQuestionType(answers, triggerNotification, status, requestBuilder);
  } else {
    return validateOtherQuestionTypes(answers, status, triggerNotification, requestBuilder);
  }
}

/**
 * @description validate pair questions
 * @param answers
 * @param triggerNotification
 * @param status
 * @param requestBuilder
 * @returns {{potentialAnswers: *[], correctAnswers: *[], requestObject}}
 */
export const validatePairQuestionType = (answers, triggerNotification, status, requestBuilder) => {
  const potentialAnswers = [[], []];
  const correctAnswers = [];
  answers.forEach((answer) => {
    /**
     * When creating the potential answers they are based on a 2d indexed array
     * [["Tony"], ["Hawks]]
     * the left array is the selection and right array is pairing, in order to create a proper pair
     * a indexed based pair must be made which will result as such
     * [[0, 1]] which will be the correct answers array [["Tony", "Hawks]]
     */
    potentialAnswers[0].push(answer.label);
    potentialAnswers[1].push(answer.value);
    correctAnswers.push([answer.label, answer.value]);
  });

  const requestObject = requestBuilder(
    potentialAnswers,
    correctAnswers,
    status
  )
  return {
    potentialAnswers,
    correctAnswers,
    requestObject
  }
}

/**
 * @description validates order typ questions and creates correct answer arrays
 * @param answers
 * @param triggerNotification
 * @param status
 * @param requestBuilder
 * @returns {{potentialAnswers: any, correctAnswers: *[], requestObject}|*}
 */
export const validateOrderQuestionType = (answers, triggerNotification, status, requestBuilder) => {
  const order = [];
  for (let i = 0; i < answers.length; i++) {
    if (answers[i].label === null || answers[i].label.trim() === "") {
      return triggerNotification("Answers cannot be empty", "error");
    }
    if (order.includes(answers[i].value)) {
      return triggerNotification(
        `Order number ${answers[i].value} cannot be used more than once`
      );
    } else {
      order.push(answers[i].value);
    }
  }

  const potentialAnswers = Object.assign([], answers);
  let correctAnswers = [];
  let checkOrder = Object.assign([], answers);

  checkOrder.sort((a, b) => {
    return Number(a.value) > Number(b.value) ? 1 : -1;
  });
  let num = 1;
  for (let i = 0; i < checkOrder.length; i++) {
    if (Number(checkOrder[i].value) !== num) {
      return triggerNotification(
        `Cannot have gaps between order numbers`,
        "error"
      );
    }
    num++;
  }
  // resassign only label value into array
  const potentialAnswersTemp = [];
  const correctAnswersTemp = [];
  for (let i = 0; i < potentialAnswers.length; i++) {
    potentialAnswersTemp.push(potentialAnswers[i].label);
    correctAnswersTemp.push(checkOrder[i].label);
  }
  correctAnswers = Object.assign([], correctAnswersTemp);

  const requestObject = requestBuilder(
    potentialAnswersTemp,
    correctAnswers,
    status
  );
  return {
    potentialAnswers,
    correctAnswers,
    requestObject
  }
}

/**
 * @description validates non order question types
 * @param answers
 * @param status
 * @param triggerNotification
 * @param requestBuilder
 * @returns {{potentialAnswers: *[], requestObject, correctAnswers: *[]}|*}
 */
export const validateOtherQuestionTypes = (answers, status, triggerNotification, requestBuilder) => {
  const potentialAnswers = [];
  const correctAnswers = [];
  for (let i = 0; i < answers.length; i++) {
    if (answers[i].label === null || answers[i].label.trim() === "") {
      return triggerNotification("Answers cannot be empty", "error");
    }
    potentialAnswers.push(answers[i].label);
    if (answers[i].value === "true" || answers[i].value === true) {
      correctAnswers.push(answers[i].label);
    }
  }

  const requestObject = requestBuilder(
    potentialAnswers,
    correctAnswers,
    status
  );
  return {
    requestObject,
    potentialAnswers,
    correctAnswers
  }
}


