import {
  PUBLISH_CONTENT_PACK,
  RETRIEVE_ALL_CONTENT_PACK
} from "../actions/content-pack-actions";

export default function contentPackReducer(
  state = [],
  { type, payload }
) {
  switch (type) {
    case PUBLISH_CONTENT_PACK:
      return state;
    case RETRIEVE_ALL_CONTENT_PACK:
      return payload;
    default:
      return state;
  }
}
