export default function() {
  return [
    {
      title: "Passages",
      to: "/lead/passages/published",
      htmlBefore: '<i class="material-icons">question_answer</i>',
      htmlAfter: "",
      submenu: [
        {
          title: "Assign Passages",
          to: "/lead/assign-passages",
          htmlBefore: '<i class="material-icons">chat</i>'
        },
        {
          title: "Pending passages",
          to: "/lead/passages/disabled"
        },
        {
          title: "Work In Progress",
          to: "/lead/work-in-progress"
        }
      ]
    },
    {
      title: "Independent Questions",
      to: "/lead/independent-questions/new",
      htmlBefore: '<i class="material-icons">question_answer</i>',
      htmlAfter: "",
      submenu: [
        {
          title: "Add Independent Question",
          to: "/lead/independent-questions/new"
        },
        {
          title: "My Drafts",
          to: "/lead/independent-questions/all/draft"
        },
        {
          title: "In Review",
          to: "/lead/independent-questions/all/in-review"
        },
        {
          title: "Denied",
          to: "/lead/independent-questions/all/denied"
        },
        {
          title: "Published",
          to: "/lead/independent-questions/all/published"
        }
      ]
    },
    {
      title: "Manage User",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/lead/manage-users"
    },
    {
      title: "Logout",
      htmlBefore: '<i class="material-icons">backspace</i>',
      to: "/login",
      logout: true
    }
  ];
}
