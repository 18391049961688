import {
  RETRIEVE_ALL_INDEPENDENT_QUESTIONS,
  ADD_INDEPENDENT_QUESTION,
  CLEAR_INDEPENDENT_QUESTION,
  UPDATE_INDEPENDENT_QUESTION
} from "../actions/independent-question-actions";

export default function independentQuestionReducer(
  state = [],
  { type, payload }
) {
  switch (type) {
    case RETRIEVE_ALL_INDEPENDENT_QUESTIONS:
      return payload;
    case ADD_INDEPENDENT_QUESTION:
      return state;
    case CLEAR_INDEPENDENT_QUESTION:
      return [];
    case UPDATE_INDEPENDENT_QUESTION:
      return state;
    default:
      return state;
  }
}
