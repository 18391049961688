import { authenticate, registerUser, modifiyUser, getUsers, authenticateUserByToken } from "../../api/user-api";

export const LOGIN_USER = "user:authenticateUser";
export const LOGOUT_USER = "user:logoutUser";
export const ADD_USER = "user:addUser";
export const GET_USERS = "user:getUsers";
export const UPDATE_USER = "user:updateUser";
export const UPDATE_USER_ROLE = "user:updateUserRole";

export function authenticateUser(user = []) {
  return dispatch => {
    return authenticate(user);
  };
}

export function authenticateDashboardUser(token, notificationSystem) {
  return dispatch => {
    return authenticateUserByToken(token)
      .then(({ data }) => {
        localStorage.setItem("token", data.data.token);
        dispatch(updateUser({ user: data.data.user }));
      })
      .catch(err => {
        if (notificationSystem) {
          console.log(err);
          return notificationSystem("There was an error authenticating you. Please, try again.", "error", "tr");
        }
      });
  }
}

export function getAllUsers() {
  return dispatch => {
    getUsers()
      .then(data => {
        dispatch({
          type: GET_USERS,
          payload: data.data.data
        });
      })
      .catch(err => console.log(err));
  };
}

export function addUser(data, notificationSystem) {
  return dispatch => {
    registerUser(data)
      .then(data => {
        console.log(data);
        notificationSystem("User created", "success", "tr");
        dispatch({
          type: ADD_USER,
          payload: null
        });
      })
      .catch(err => {
        notificationSystem(err.response.data.details, "error", "tr");
      });
  };
}

export function updateUser(user = []) {
  return {
    type: UPDATE_USER,
    payload: user
  };
}

export function updateUserRole(user, notificationSystem) {
  return dispatch => {
    modifiyUser(user).then(data => {
      notificationSystem("User updated", "success", "tr");
      dispatch({
        type: UPDATE_USER_ROLE,
        payload: user
      })
    }).catch(err => {
      notificationSystem(err, "error", "tr");
    })
  }
}

export function logoutUser() {
  localStorage.removeItem("token");
  localStorage.removeItem("persist:root");
  return {
    type: LOGOUT_USER,
    payload: {
      users: []
    }
  };
}
