import Axios from "axios";

const api = require("../api/api-config");

export function authenticate(data) {
  return new Promise((resolve, reject) => {
    Axios.post(api.api + "/user/authenticate", data)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getUsers() {
  return new Promise((resolve, reject) => {
    Axios.get(api.api + "/user")
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function registerUser(data) {
  return new Promise((resolve, reject) => {
    Axios.post(api.api + "/user/register", data)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function modifiyUser(data) {
  return new Promise((resolve, reject) => {
    Axios.post(api.api + "/user/update", data)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function authenticateUserByToken(token) {
  return new Promise((resolve, reject) => {
    Axios.post(`${api.api}/user/dashboard/authenticate`, { token })
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
}
