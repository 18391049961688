import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";

// reducers
import userReducer from "./redux/reducer/user-reducer";
import passageReducer from "./redux/reducer/passage-reducer";
import genreReducer from "./redux/reducer/genre-reducer";
import gradeReducer from "./redux/reducer/grade-reducer";
import skillReducer from "./redux/reducer/skill-reducer";
import skillGroupsReducer from "./redux/reducer/skill-groups-reducer";
import skillIndependentReducer from "./redux/reducer/skill-independent-reducer";
import subjectReducer from "./redux/reducer/subject-reducer";
import questionTypeReducer from "./redux/reducer/questionType-reducer";
import passageLengthReducer from "./redux/reducer/passage-length-reducer";
import bookReducer from "./redux/reducer/book-reducer";
import questionReducer from "./redux/reducer/question-reducer";
import independentQuestionReducer from "./redux/reducer/independent-question-reducer";
import statusReducer from "./redux/reducer/status-reducer";
import { idReducer } from "./redux/reducer/id-reducer";
import { userPassagesReducer } from "./redux/reducer/user-passages-reducer";
import { userSkillsReducer } from "./redux/reducer/user-skills-reducer";
import commonCoreReducer from "./redux/reducer/common-core-reducer";
import commonCoreIndependentReducer from "./redux/reducer/common-core-independent-reducer";
import contentPackReducer from "./redux/reducer/content-pack-reducer";
import grammarCheckReducer from "./redux/reducer/grammar-reducer";
import {LOGOUT_USER} from "./redux/actions/user-actions";

const allReducers = combineReducers({
  userData: userReducer,
  passageLength: passageLengthReducer,
  passages: passageReducer,
  genre: genreReducer,
  grades: gradeReducer,
  skills: skillReducer,
  skillGroups: skillGroupsReducer,
  skillsIndependent: skillIndependentReducer,
  subjects: subjectReducer,
  questionTypes: questionTypeReducer,
  books: bookReducer,
  commonCore: commonCoreReducer,
  commonCoreIndependent: commonCoreIndependentReducer,
  questions: questionReducer,
  independentQuestions: independentQuestionReducer,
  currentStatus: statusReducer,
  id: idReducer,
  userPassages: userPassagesReducer,
  userSkills: userSkillsReducer,
  contentPacks: contentPackReducer,
  grammarCheck: grammarCheckReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }

  return allReducers(state, action);
};

const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(
    persistedReducer,
    {
      userData: {
        users: []
      },
      passages: [],
      genre: [],
      passageLength: [],
      books: [],
      grades: [],
      questions: [],
      currentStatus: "draft",
      id: null,
      userPassages: [],
      commonCore: []
    },
    composeWithDevTools(applyMiddleware(thunk))
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
