import {
  LOGIN_USER,
  ADD_USER,
  GET_USERS,
  UPDATE_USER,
  UPDATE_USER_ROLE
} from "../actions/user-actions";

export default function userReducer(state = {}, { type, payload }) {
  switch (type) {
    case LOGIN_USER:
      return state;
    case ADD_USER:
      return state;
    case UPDATE_USER:
      const newState = { ...state, ...payload };
      return newState;
    case UPDATE_USER_ROLE:
      const updatedUser = state.users.find(e => Number(e.id) === Number(payload.id));
      if (updatedUser) {
        updatedUser.roleId = payload.roleId;
      }
      return state;
    case GET_USERS:
      const users = Object.assign({}, state);
      users.users = payload;
      return users;
    default:
      return state;
  }
}
