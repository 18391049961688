import Axios from "axios";

const api = require("../api/api-config");

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`
};

export function getPassages(endpoint = "local", isAssigned) {
  console.log(`/passages/${endpoint}`);
  return new Promise((resolve, reject) => {
    Axios.get(api.api + `/passages/${endpoint}`, {
      params: { isAssigned }
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getPassageById(endpoint, id) {
  return new Promise((resolve, reject) => {
    Axios.get(api.api + `/passages/${endpoint}/${id}`)
      .then(data => {
        console.log(data);
        resolve(data);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}

export function assignPassage(passage) {
  return new Promise((resolve, reject) => {
    Axios.post(api.api + `/passages/live/assign`, passage)
      .then(data => {
        console.log(data);
        resolve(data);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}

export function postPassage(passage, endpoint = "local") {
  return new Promise((resolve, reject) => {
    Axios.post(api.api + `/passages/${endpoint}`, passage)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function putPassage(endpoint = "local", passage, id) {
  return new Promise((resolve, reject) => {
    Axios.put(api.api + `/passages/${endpoint}/${id}`, passage)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function deletePassage(passage, endpoint = "local") {
  return new Promise((resolve, reject) => {
    Axios.delete(api.api + `/passages/${endpoint}`, passage)
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
