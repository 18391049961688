import Axios from "axios";
import qs from "querystring";

const apiKey = "bff52d8550msh6bdc4a50ab9b509p11b84ajsn00863a994b2f";

export async function requestGrammarCheck(text) {
  return Axios({
		method: "POST",
    url: "https://grammarbot.p.rapidapi.com/check",
    headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			"x-rapidapi-host": "grammarbot.p.rapidapi.com",
			"x-rapidapi-key": apiKey
		},
		data: qs.stringify({
			language: "en-US",
			text
		})
	});
}
