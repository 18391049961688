import React from "react";
import { Container, Row } from "shards-react";
import "../assets/login.css";

const NoLayout = props => {
  return (
    <Container fluid className="login center-content cube_animation">
      <div className="cube"></div>
      <div className="cube"></div>
      <div className="cube"></div>
      <div className="cube"></div>
      <div className="cube"></div>
      <div className="cube"></div>
      <Row>{props.children}</Row>
    </Container>
  );
};

export default NoLayout;
