import React from 'react';
import { QuestionCategory, QuestionType } from '../../enums/QuestionType';
import { generate } from "shortid";

/**
 * Adds a new answer when "add new answer" button is clicked
 * @param questionTypeId
 * @param answers
 * @param triggerNotification
 * @param setAnswers
 * @returns 
 */

export const addAnswer = (questionTypeId, triggerNotification, answers, setAnswers) => {
    if (questionTypeId !== null) {
        const prevState = Object.assign([], answers);
        if (questionTypeId == QuestionType.OpenEnded) {
            triggerNotification(
                "Open Ended questions cannot have any answers"
            );
        } else if (
            questionTypeId == QuestionType.TrueFalse &&
            prevState.length >= 2
        ) {
            triggerNotification(
                "Max 2 answers allowed for True and False Questions"
            );
        } else if (prevState.length >= 6) {
            triggerNotification("Max 6 answers allowed");
        } else {
            prevState.push({ id: generate(), label: '', value: null });
            setAnswers([...prevState]);
        }
    } else {
        triggerNotification(
            "Please choose question type before adding answer"
        );
    }
};

/**
 * Removes Answer when remove answer button is clicked
 * @param id
 * @param answers
 * @param setAnswers
 * @returns 
 */
export const removeAnswer = (id, answers, setAnswers) => {
    let prevState = Object.assign([], answers);
    prevState = prevState.filter(e => {
        return e.id !== id;
    });
    setAnswers([...prevState]);
};

/**
 * Triggered when questions answers are being updated for addQuestions and addIndependentQuestion
 * @param evt
 * @param id
 * @param setAnswers
 * @returns 
 */
export const answerChange = (evt, id, setAnswers) => {
    let value = evt.target.value;
    const name = evt.target.name;
    if (value == "true") {
        value = true;
    } else if (value === "false") {
        value = false;
    }
    setAnswers(answer => {
        return answer.map(e => {
            return e.id === id ? { ...e, [name]: value } : e;
        });
    });
}

/**
 * Triggered when questions answers are being updated for editQuestions and editIndependentQuestion
 * @param evt
 * @param id
 * @param setEdit
 * @param setAnswers
 * @returns 
 */
export const editAnswerChange = (evt, id, setEdit, setAnswers) => {
    setEdit({
        editing: true
    });
    let value = evt.target.value;
    let name = evt.target.name;
    if (value === "true" || value === 1) {
        value = true;
    } else if (value === "false" || value === 0) {
        value = false;
    }
    if (name === "orderValue") {
        if (!isNaN(value)) {
            value = Number(value);
            name = "value";
        }
    }

    setAnswers(answer => {
        return answer.map(e => {
            return e.id === id ? { ...e, [name]: value } : e;
        });
    });
}

/**
 * Sending question or independent question to review
 * @param props
 * @param validateQuestion
 * @param type
 * @returns 
 */
export const sendQuestionToReview = (props, validateQuestion, type) => {
    const update = (type === QuestionCategory.Passage ? props.updateQuestion : props.updateIndependentQuestion);
    if (validateQuestion()) {
        update(
            "sendToReview",
            { status: "In Review" },
            props.match.params.id,
            props.triggerNotification
        );
    }
}

/**
 * Sending question back as denied 
 * @param props
 * @param note
 * @param toggleModal
 * @param type
 * @returns 
 */
export const sendQuestionBack = (props, note, toggleModal, type) => {
    const update = (type === QuestionCategory.Passage ? props.updateQuestion : props.updateIndependentQuestion);
    update(
        "Denied",
        note,
        props.match.params.id,
        props.triggerNotification
    );
    toggleModal();
}

