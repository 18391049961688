import React, { useState } from 'react';
import Modal from 'react-modal';
import {
	Row,
	Col,
	FormGroup,
	FormInput,
	Button
} from 'shards-react';

const IndependentQuestionsModal = props => {
	const [currentQuestion, setCurrentQuestion] = useState(props.questions[0]);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

	const handleChangeQuestion = index => {
		if (index < 0 || index > props.questions.length - 1) {
			return null;
		}
		setCurrentQuestionIndex(index);
		setCurrentQuestion(props.questions[index]);
	}
	
	const renderNavigationControls = () => {
		if (props.questions.length > 1) {
			return (<div className="question-modal__navigation-controls">
				<Button
					theme="info"
					disabled={currentQuestionIndex === 0}
					onClick={() => handleChangeQuestion(currentQuestionIndex - 1)}>
						Previous
				</Button>
				<label>Question {currentQuestionIndex + 1} of {props.questions.length}</label>
				<Button
					theme="info"
					disabled={currentQuestionIndex === props.questions.length - 1}
					onClick={() => handleChangeQuestion(currentQuestionIndex + 1)}>
						Next
				</Button>
			</div>);
		}
		return null;
	}

	return (
		<Modal isOpen={props.isOpen}>
			{renderNavigationControls()}
			<Row style={{ marginBottom: 40 }}>
				<Col lg={12}>
					<FormGroup>
						<label>Name:</label>
						<FormInput readOnly value={currentQuestion.name} />
					</FormGroup>
				</Col>
				<Col lg={12}>
					<FormGroup>
						<label>Created By:</label>
						<FormInput readOnly value={currentQuestion.educationToolData.userName} />
					</FormGroup>
				</Col>
				<Col lg={12}>
					<FormGroup>
						<label>Question:</label>
						<div
							className="question-modal__content-html"
							dangerouslySetInnerHTML={{ __html: currentQuestion.question }}>
						</div>
					</FormGroup>
				</Col>
				<Col lg={12}>
					<FormGroup>
						<label>Question Type:</label>
						<FormInput readOnly value={currentQuestion.questionType.name} />
					</FormGroup>
				</Col>
				<Col lg={12}>
					<FormGroup>
						<label>Subject:</label>
						<FormInput readOnly value={currentQuestion.subject.name} />
					</FormGroup>
				</Col>
				<Col lg={12}>
					<FormGroup>
						<label>Grade:</label>
						<FormInput readOnly value={currentQuestion.gradeId} />
					</FormGroup>
				</Col>
				<Col lg={12}>
					<FormGroup>
						<label>Course Code:</label>
						<FormInput readOnly value={currentQuestion.skill.name} />
					</FormGroup>
				</Col>
				<Col lg={12}>
					<FormGroup>
						<label>Standards:</label>
						<FormInput readOnly value={currentQuestion.standards.description} />
					</FormGroup>
				</Col>
				<Col lg={12}>
					<FormGroup>
						<label>Answers:</label>
						{currentQuestion.answers.map(answer => <FormInput readOnly value={answer.label} style={{ marginBottom: 10 }} />)}
					</FormGroup>
				</Col>
				<Col lg={12}>
					<FormGroup>
						<label>Feedback for Incorrect Answer:</label>
						<FormInput readOnly value={JSON.parse(currentQuestion.feedbackIncorrect)[0]} />
					</FormGroup>
				</Col>
			</Row>
			<div className="question-modal__close">
				<Button theme="danger" pill onClick={props.handleClose}>X</Button>
			</div>
		</Modal>
	);
}

export default IndependentQuestionsModal;