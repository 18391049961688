import { RETRIEVE_ALL_INDEPENDENT_SKILLS } from "../actions/skill-independent-actions";


export default function skillIndependentReducer(state = [], {type, payload}) {
    switch (type) {
        case RETRIEVE_ALL_INDEPENDENT_SKILLS:
            return payload;
        default:
            return state;
    }
}
