export default function () {
  return [
    {
      title: "Review Questions",
      to: "/writer/review-questions",
      htmlBefore: '<i class="material-icons">chat</i>'
    },
    {
      title: "Passages",
      to: "/writer/passages/all",
      htmlBefore: '<i class="material-icons">chat</i>',
      submenu: [
        {
          title: "Add new passage",
          to: "/writer/passages/new"
        },
        {
          title: "Assigned Passages",
          to: "/writer/assigned-passages/published"
        },
        {
          title: "My Drafts",
          to: "/writer/passages/all/draft"
        },
        {
          title: "Published",
          to: "/writer/passages/all/published"
        },
        {
          title: "Ready Passages",
          to: "/writer/passages/all/disabled/1"
        }
      ]
    },
    {
      title: "Passages Questions",
      to: "/writer/questions/all",
      htmlBefore: '<i class="material-icons">question_answer</i>',
      htmlAfter: "",
      submenu: [
        {
          title: "Add question",
          to: "/writer/questions/new"
        },
        {
          title: "My Drafts",
          to: "/writer/questions/all/draft"
        },
        {
          title: "Denied",
          to: "/writer/questions/all/denied"
        },
        {
          title: "In Review",
          to: "/writer/review-questions"
        },
        {
          title: "Published",
          to: "/writer/questions/all/published"
        }
      ]
    },
    {
      title: "Independent Questions",
      to: "/writer/questions/all",
      htmlBefore: '<i class="material-icons">question_answer</i>',
      htmlAfter: "",
      submenu: [
        {
          title: "Add Independent Question",
          to: "/writer/independent-questions/new"
        },
        {
          title: "My Drafts",
          to: "/writer/independent-questions/all/draft"
        },
        {
          title: "Denied",
          to: "/writer/independent-questions/all/denied"
        },
        {
          title: "In Review",
          to: "/writer/review-independent-questions"
        },
        {
          title: "Published",
          to: "/writer/independent-questions/all/published"
        }
      ]
    },
    {
      title: "Books",
      to: "/writer/books",
      htmlBefore: '<i class="material-icons">library_books</i>',
      htmlAfter: ""
    },
    {
      title: "Manage User",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/writer/manage-users"
    },
    {
      title: "Logout",
      htmlBefore: '<i class="material-icons">backspace</i>',
      to: "/login",
      logout: true
    }
  ];
}
