import { RETRIEVE_ALL_SKILLS } from "../actions/skill-actions";


export default function skillReducer(state = [], {type, payload}) {
    switch (type) {
        case RETRIEVE_ALL_SKILLS:
            return payload;
        default:
            return state;
    }
}
