import React from "react";
import { getBooks, postBook, putBook } from "../../api/books-api";
export const RETRIEVE_ALL_BOOKS = "book:retrieveAllBooks";
export const ADD_BOOK = "book:addBook";
export const UPDATE_BOOK = "book:updateBook";

export function getAllBooks(books = []) {
  return dispatch => {
    getBooks()
      .then(data => {
        return dispatch({
          type: RETRIEVE_ALL_BOOKS,
          payload: data.data.data
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function updateBook(books = [], id, notificationSystem) {
  return dispatch => {
    putBook(books, id)
      .then(data => {
        notificationSystem("updated");
      })
      .catch(err => {
        if (err.response.status === 400) {
          notificationSystem("Bad request", "error");
        } else {
          notificationSystem("Error adding book", "error");
        }
      });
  };
}

export function addBook(books = [], notificationSystem) {
  return dispatch => {
    postBook(books)
      .then(data => {
        notificationSystem("Added new book", "success");
      })
      .catch(err => {
        if (err.response.status === 400) {
          notificationSystem("Bad request", "error");
        } else {
          notificationSystem("Error adding book", "error");
        }
      });
  };
}
