import {
  getUserPassages,
  userPassageDone
} from "../actions/user-passage-actions";

export function userPassagesReducer(state = [], { type, payload }) {
  switch (type) {
    case getUserPassages:
      return [...payload];
    case userPassageDone:
      return state;
    default:
      return state;
  }
}
