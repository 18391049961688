import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  FormInput,
  Button,
  FormSelect
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Loader from "../components/loader/Loader";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateBook } from "../redux/actions/book-actions";

class EditBook extends React.Component {
  handleChange = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  };
  updateBook = id => {
    console.log(this.state);
    this.props.updateBook(this.state, id, this.props.triggerNotification);
  };
  render() {
    let currentBook = "";
    if (this.props.books.length > 0) {
      currentBook = this.props.books.filter(e => {
        return this.props.match.params.id === String(e.id);
      });
      currentBook = currentBook[0];
      console.log(currentBook);
    }
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        {currentBook ? (
          <Fragment>
            <Row noGutters className="page-header py-4">
              <PageTitle
                sm="4"
                title="Edit Book"
                subtitle=""
                className="text-sm-left"
              />
            </Row>
            <Row form>
              <Col sm="4" className="form-group">
                <label htmlFor="">Title</label>
                <FormInput
                  name="title"
                  onChange={this.handleChange}
                  defaultValue={currentBook.title}
                  type="text"
                />
              </Col>
              <Col sm="4" className="form-group">
                <label htmlFor="">Author</label>
                <FormInput
                  name="author"
                  onChange={this.handleChange}
                  defaultValue={currentBook.author}
                  type="text"
                />
              </Col>
              <Col sm="4" className="form-group">
                <label htmlFor="">genre</label>
                <FormSelect onChange={this.handleChange} name="genreId">
                  <option value={null}>Choose</option>
                  {this.props.genre.map((e, i) => {
                    if (e.id === currentBook.genreId) {
                      return (
                        <option key={i} value={e.id} selected>
                          {e.name}
                        </option>
                      );
                    } else {
                      return (
                        <option key={i} value={e.id}>
                          {e.name}
                        </option>
                      );
                    }
                  })}
                </FormSelect>
              </Col>

              <Col sm="4" className="form-group">
                <label htmlFor="">Year</label>
                <FormInput
                  name="year"
                  onChange={this.handleChange}
                  defaultValue={currentBook.year}
                  type="text"
                />
              </Col>
              <Col sm="4" className="form-group">
                <label htmlFor="">Publisher Rights</label>
                <FormInput
                  name="publisherRights"
                  onChange={this.handleChange}
                  defaultValue={currentBook.publisherRights}
                  type="text"
                />
              </Col>
              <Col sm="4" className="form-group">
                <label htmlFor="">Description</label>
                <FormInput
                  name="description"
                  onChange={this.handleChange}
                  defaultValue={currentBook.description}
                  type="text"
                />
              </Col>
              <Col sm="4" className="form-group">
                <label htmlFor="">Approximate grade level</label>
                <FormInput
                  name="approxGradeLevel"
                  onChange={this.handleChange}
                  defaultValue={currentBook.approxGradeLevel}
                  type="text"
                />
              </Col>
              <Col sm="4" className="form-group">
                <label htmlFor="">Link</label>
                <FormInput
                  name="link"
                  onChange={this.handleChange}
                  defaultValue={currentBook.link}
                  type="text"
                />
              </Col>
              <Col sm="4" className="form-group">
                <label htmlFor="">Advisory</label>
                <FormInput
                  onChange={this.handleChange}
                  name="advisory"
                  defaultValue={currentBook.advisory}
                  type="text"
                />
              </Col>
              <Col sm="4" className="form-group">
                <label htmlFor="">Additional Info</label>
                <FormInput
                  name="additionalInfo"
                  onChange={this.handleChange}
                  defaultValue={currentBook.additionalInfo}
                  type="text"
                />
              </Col>
              <Col sm="4" className="form-group">
                <label htmlFor="">Translated By, From (Year)</label>
                <FormInput
                  name="translatedBy"
                  onChange={this.handleChange}
                  defaultValue={currentBook.additionalInfo}
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Button
                  theme="dark"
                  onClick={() => this.updateBook(currentBook.id)}
                >
                  Update Book
                </Button>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <Loader />
        )}
      </Container>
    );
  }
}

const mapActionToProps = dispatch => {
  return {
    updateBook: bindActionCreators(updateBook, dispatch)
  };
};

export default connect(
  null,
  mapActionToProps
)(EditBook);
