import {
  getQuestionById,
  getQuestions,
  postQuestions,
  putQuestions
} from "../../api/questions-api";
import { SET_ID } from "./id-actions";
import { SET_STATUS } from "./status-actions";

export const RETRIEVE_ALL_QUESTIONS = "question:retrieveAllQuestions";
export const ADD_QUESTION = "question:addQuestion";
export const CLEAR_QUESTION = "question:clearQuestions";
export const UPDATE_QUESTION = "question:updateQuestion";

export function getAllQuestions(status, userId) {
  let endpoint = "local";
  if (status === "published" || status === "publish") {
    endpoint = "live";
  } else if (status === "in-review") {
    endpoint += "/?status=in%20review";
  } else if (status === "draft") {
    endpoint += "/?status=Draft";
  } else if (status === "denied") {
    endpoint += "/?status=denied";
  }
  return dispatch => {
    getQuestions(endpoint)
      .then(data => {
        return dispatch({
          type: RETRIEVE_ALL_QUESTIONS,
          payload: data.data.data
        });
      })
      .catch(err => {
        return dispatch({
          type: RETRIEVE_ALL_QUESTIONS,
          payload: []
        });
      });
  };
}

export function getQuestionId(status, id, questions = []) {
  let endpoint = "local";
  if (status === "published" || status === "publish") {
    endpoint = "live";
  }

  return dispatch => {
    return getQuestionById(endpoint, id);
  };
}

export function updateQuestion(status, question, id, notificationSystem) {
  let endpoint = "local";
  if (status === "published" || status === "publish") {
    endpoint = "live";
  }

  if (question.status === "in-review") {
    question.status = "In Review";
  } else if (question.status && question.status.includes("published")) {
    delete question.status;
  }

  return dispatch => {
    putQuestions(question, id, endpoint)
      .then(data => {
        if (status === "sendToReview") {
          dispatch({
            type: SET_STATUS,
            payload: "in-review"
          });
          notificationSystem("Sent to review", "success");
        } else if (status === "Denied") {
          notificationSystem("Sent back", "success");
        } else {
          notificationSystem("Successful", "success");
        }
      })
      .catch(err => {
        notificationSystem(err.response.data.details);
      });
  };
}

export function clearQuestion() {
  return {
    type: CLEAR_QUESTION,
    payload: []
  };
}

export function addQuestion(
  questions = [],
  notificationSystem,
  status = "local"
) {
  let endpoint = "local";
  if (status === "Publish") {
    endpoint = "live";
  }
  return dispatch => {
    postQuestions(questions, endpoint)
      .then(data => {
        if (endpoint === "live") {
          dispatch({
            type: SET_ID,
            payload: data.data.data[0].id
          });
          dispatch({
            type: SET_STATUS,
            payload: "publish"
          });
          notificationSystem("Published successfully", "success", "tr");
        } else {
          notificationSystem("Successful", "success", "tr");
          dispatch({
            type: SET_ID,
            payload: data.data.data.id
          });
          dispatch({
            type: SET_STATUS,
            payload: "draft"
          });
        }

        return dispatch({
          type: ADD_QUESTION,
          payload: questions
        });
      })
      .catch(err => {
        console.log(err);
        notificationSystem("Error", "error", "tr");
      });
  };
}
