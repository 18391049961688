import Axios from "axios";

Axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`
};

const api = require("../api/api-config");

export const getCommonCoreStandards = () => {
  return new Promise(
    (resolve, reject) => {
      Axios.get(`${api.api}/common-core`)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
    }
  );
}

export const getCommonCoreIndependentStandards = () => {
  return new Promise(
    (resolve, reject) => {
      Axios.get(`${api.api}/common-core/independent`)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        })
    }
  );
}
