import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Col,
  Container,
  FormGroup,
  FormInput,
  Row
} from "shards-react";
import FormSelect from '../../../components/formselect/FormSelect';
import { generate } from "shortid";
import PageTitle from "../../../components/common/PageTitle";
import Editor from "../../../components/add-new-post/Editor";
import { setId } from "../../../redux/actions/id-actions";
import { addIndependentQuestion } from "../../../redux/actions/independent-question-actions";
import { getAllIndependentSkills } from "../../../redux/actions/skill-independent-actions";
import { getCommonCoreIndependent } from "../../../redux/actions/common-core-independent-actions";
import { Answers } from "../answer/Answers";
import { addAnswer, answerChange, removeAnswer } from "../QuestionOperations";
import {
  validateAndBuildAnswersByQuestionType,
  validateQuestionAnswers,
} from "../questionValidation";
import { QuestionType } from "../../../enums/QuestionType";


const AddIndependentQuestions = props => {
  // states
  const [state, setState] = useState({
    name: '',
    potentialAnswers: [],
    correctAnswers: [],
    questionTypeId: null, // 1 = multiple choice, 3 = drag and drop, 4 = select all
    skillId: null,
    subjectId: null,
    question: null,
    gradeId: null,
    grades: Array.from(Array(8)).map((e, i) => i + 1),
    commonCoreIndependentId: null,
    status: "In Review",
    feedbackCorrect: "",
    fedbackIncorrect: "",
    dei: 0
  });
  const [answers, setAnswers] = useState([]);
  const [order, setOrder] = useState([1, 2, 3, 4, 5, 6]);

  useEffect(() => {
    props.setId(null);
    props.getAllIndependentSkills();
    props.getCommonCoreIndependent();
    return () => {
      console.log("Add Question dismounting");
    };
  }, []);

  useEffect(() => {
    props.getAllIndependentSkills();
    props.getCommonCoreIndependent();
  }, [state.gradeId])

  useEffect(() => {
    if (props.questionId !== null) {
      if (props.match.url.includes("writer")) {
        props.history.push(
          `/writer/independent-questions/edit/${props.questionId}`
        );
      } else if (props.match.url.includes("lead+")) {
        props.history.push(
          `/lead+/independent-questions/edit/${props.questionId}`
        );
      } else if (props.match.url.includes("lead")) {
        props.history.push(
          `/lead/independent-questions/edit/${props.questionId}`
        );
      } else if (props.match.url.includes("external-creator")) {
        props.history.push(
          `/external-creator/independent-questions/edit/${props.questionId}`
        );
      } else {
        props.history.push(
          `/teacher/independent-questions/edit/${props.questionId}`
        );
      }
    }
  }, [props.questionId]);

  const handleChange = evt => {
    setState({
      ...state,
      [evt.target.name]: evt.target.value
    });
  };

  const validateQuestion = () => {
    let isValid = true;

    if (state.questionTypeId === null) {
      props.triggerNotification("Question type needs to be selected", "error");
      isValid = false;
    }
    if (state.gradeId === null) {
      props.triggerNotification("Grade cannot be empty", "error");
      isValid = false;
    }
    if (state.skillId === null) {
      props.triggerNotification("Skill needs to be selected", "error");
      isValid = false;
    }
    if (state.commonCoreIndependentId === null) {
      props.triggerNotification("Common Core cannot be empty", "error");
      isValid = false;
    }
    // multiple choice validation
    isValid = validateQuestionAnswers(state.questionTypeId, answers, props.triggerNotification);
    // drag and drop

    if (state.question === null) {
      props.triggerNotification("Question needs to be entered", "error");
      isValid = false;
    }

    return isValid;
  };

  const handleAnswerChange = (evt, id) => {
    answerChange(evt, id, setAnswers)
  };

  const requestBuilder = (potentialAnswers, correctAnswers, status) => {
    let request = {
      name: state.name,
      potentialAnswers,
      correctAnswers,
      questionTypeId: state.questionTypeId,
      gradeId: state.gradeId,
      skillId: state.skillId,
      question: state.question,
      commonCoreIndependentId: state.commonCoreIndependentId,
      feedbackCorrect: JSON.stringify([state.feedbackCorrect]),
      feedbackIncorrect: JSON.stringify([state.feedbackIncorrect]),
      dei: Number(state.dei),
      status
    };
    if (props.userData.user.role === 'External Creator') {
      request.subjectId = state.subjectId;
    }
    return request;
  };


  const removeRow = id => {
    removeAnswer(id, answers, setAnswers)
  };
  const addRow = () => {
    addAnswer(state.questionTypeId, props.triggerNotification, answers, setAnswers)
  }

  const addIndependentQuestion = status => {
    try {
      const { potentialAnswers, correctAnswers, requestObject } = validateAndBuildAnswersByQuestionType(state.questionTypeId, answers, status, props.triggerNotification, requestBuilder);
      return validateQuestion(potentialAnswers, correctAnswers)
        ? props.addIndependentQuestion(requestObject, props.triggerNotification)
        : null;
    } catch (error) {
      console.log('Make sure you have selected atleast one correct answer option and that no answser fields are empty.');
    }
  };

  let skills = props.skillsIndependent || [];
  if (props.userData.user.role === 'External Creator') {
    skills = skills.filter(item => {
      return item.subject &&
        item.grade === parseInt(state.gradeId) &&
        item.subject.id === parseInt(state.subjectId);
    });
  }

  let commonCoreIndependentData = props.commonCoreIndependent || [];
  let commonCoreIndependent = commonCoreIndependentData
    .filter(item => [item.gradeId - 1, item.gradeId, item.gradeId + 1].includes(parseInt(state.gradeId)));
  if (props.userData.user.role === 'External Creator' && !props.userData.user.isInternal) {
    commonCoreIndependent = commonCoreIndependent
      .filter(item => {
        return item.skillId === parseInt(state.skillId) &&
          item.subjectId === parseInt(state.subjectId);
      });
  }

  let title = 'Add New Independent Question';
  if (props.userData.user.role === 'External Creator') {
    title = 'Add New Question';
  }

  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title={title}
          subtitle=""
          className="text-sm-left"
        />
        <Col sm="8" className="text-right">
          {props.match.url.includes("viewer") ? (
            ""
          ) : (
            <Button theme="info" onClick={() => window.location.reload()}>
              Start a new question
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        {props.userData.user.role === 'External Creator' &&
          <Col lg="12">
            <FormGroup>
              <label htmlFor="name">Name:</label>
              <FormInput name="name" onChange={handleChange} />
            </FormGroup>
          </Col>
        }
        <Col lg="12">
          <label htmlFor="questions">Question:</label>
          <Editor
            editorName="question"
            text={state.question}
            handleQuillChange={question => setState({ ...state, question })}
            toolbar={[['bold', 'italic'], ['image']]}
          />
        </Col>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="gradeId">Grade:</label>
            <FormSelect name="gradeId" onChange={handleChange}>
              <option value={null}>Choose</option>
              {state.grades.map((e, i) => {
                return (
                  <option key={i} value={e}>
                    {e}
                  </option>
                );
              })}
            </FormSelect>
          </FormGroup>
        </Col>
        {props.userData.user.role === 'External Creator' &&
          <Col lg="12">
            <FormGroup>
              <label htmlFor="subjectId">Subject:</label>
              <FormSelect name="subjectId" onChange={handleChange}>
                <option value={null}>Choose</option>
                {props.subjects.map((e, i) => {
                  return (
                    <option key={i} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </FormSelect>
            </FormGroup>
          </Col>
        }
        <Col lg="12">
          <FormGroup>
            <label htmlFor="skills">Skills:</label>
            <FormSelect name="skillId" onChange={handleChange}>
              <option value={null}>Choose</option>
              {
                Object.keys(props.skillGroups).map((group) => {
                  return (
                    <optgroup label={group}>
                      {
                        props.skillGroups[group].map((skill) => {
                          if (skill) {
                            return (
                              <option key={group + skill.name} value={skill.id}>
                                {skill.name}
                              </option>
                            )
                          }
                        })
                      }
                    </optgroup>
                  )
                })
              }
            </FormSelect>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="commonCoreIndependentId">Standards</label>
            {
                commonCoreIndependent.length === 0 && <p>Grade must be selected to view standards</p>
            }
            <FormSelect name="commonCoreIndependentId" onChange={handleChange}>
              <option value={null}>Choose</option>
              {commonCoreIndependent.map((data, i) => (
                <option key={i} title={data.description} value={data.id} selected={data.id === state.commonCoreIndependentId}>
                  {data.description}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
        </Col>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="questionType">Question type:</label>
            <FormSelect onChange={handleChange} name="questionTypeId">
              <option value={null}>Choose</option>
              {props.questionTypes.map((e, i) => {
                if (e.name !== "Highlight") {
                  return (
                    <option key={i} value={e.id}>
                      {e.name}
                    </option>
                  );
                }
              })}
            </FormSelect>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        {/* answer */}
        {answers.map((row, i) => {
          return <Answers key={i} handleAnswerChange={handleAnswerChange} order={order} row={row} removeRow={removeRow} state={state} />
        })}
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <Button onClick={addRow}>Add new answer</Button>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="feedbackIncorrect">Feedback for Incorrect Answer:</label>
            <FormInput name="feedbackIncorrect" onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <label htmlFor="dei">DEI:</label>
            <FormSelect onChange={handleChange} name="dei">
              <option value="0" selected={!state.dei}>False</option>
              <option value="1" selected={state.dei}>True</option>
            </FormSelect>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="text-right">
          &nbsp;
          <Button
            theme="success"
            onClick={() => addIndependentQuestion("Draft")}
          >
            Save as Draft
          </Button>
          {
            props.userData.user.role === 'External Creator' &&
            <Button
              theme="primary"
              className="ml-1"
              onClick={() => addIndependentQuestion("Published")}
            >
              Submit to Question Pool
            </Button>
          }
          &nbsp;
          {props.currentStatus === "Draft" ? (
            <Button onClick={() => addIndependentQuestion("In Review")}>
              Send To Review
            </Button>
          ) : null}
        </Col>
      </Row>
      <br />
    </Container>
  );
};

const mapStateToProps = state => ({
  questionId: state.id,
  commonCore: state.commonCore,
  skills: state.skills,
  subjects: state.subjects,
  userSkills: state.userSkills,
  userData: state.userData,
  currentStatus: state.currentStatus,
  commonCoreIndependent: state.commonCoreIndependent,
  skillsIndependent: state.skillsIndependent
});

const mapActionToProps = dispatch => ({
  addIndependentQuestion: bindActionCreators(addIndependentQuestion, dispatch),
  getAllIndependentSkills: bindActionCreators(getAllIndependentSkills, dispatch),
  getCommonCoreIndependent: bindActionCreators(getCommonCoreIndependent, dispatch),
  setId: bindActionCreators(setId, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(AddIndependentQuestions);
