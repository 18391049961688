import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import SidebarNavItemDropdown from "./SidebarNavItemDropdown";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems(this.props.type)
    };

    this.onChange = this.onChange.bind(this);
    this.logout = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(this.props.type)
    });
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("persist:root");
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => {
            if (item.submenu) {
              return <SidebarNavItemDropdown key={idx} item={item} />;
            } else {
              return (
                <SidebarNavItem onLogOut={this.logout} key={idx} item={item} />
              );
            }
          })}
        </Nav>
      </div>
    );
  }
}

export default SidebarNavItems;
