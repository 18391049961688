import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  FormInput,
  Col,
  FormSelect,
  Button
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAllPassages,
  assignPassages
} from "../redux/actions/passage-actions";
import { getAllUsers } from "../redux/actions/user-actions";
import Loader from "../components/loader/Loader";

const AssignPassages = props => {
  const [state, setState] = useState({
    passageId: null,
    userId: null,
    numOfQuestions: null
  });

  useEffect(() => {
    props.getAllPassages("published");
    props.getUsers();
  }, []);

  const handleChange = evt => {
    setState({ ...state, [evt.target.name]: evt.target.value });
  };

  const assignPassage = () => {
    if (
      state.passageId === null ||
      state.userId === null ||
      state.numOfQuestions === null
    ) {
      props.triggerNotification(
        "All fields must be filled to assign a passage"
      );
    } else {
      props.assignPassage(state, props.triggerNotification);
    }
  };
  return (
    <>
      {props && props.users && props.users.length > 0 ? (
        <Container>
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Assign Passages"
              subtitle=""
              className="text-sm-left"
            />
            <br />
          </Row>
          <br />
          <Row form>
            <Col sm="8">
              <FormSelect name="userId" onChange={handleChange}>
                <option value={null}>User</option>
                {props.users
                  .map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
              </FormSelect>
            </Col>
          </Row>
          <br />
          <Row form>
            <Col sm="8">
              <FormInput
                placeholder={"Number of Questions"}
                type="number"
                onChange={handleChange}
                name="numOfQuestions"
              />
            </Col>
          </Row>
          <br />
          <Row form>
            <Col sm="8">
              <FormSelect name="passageId" onChange={handleChange}>
                <option value={null}>Passages</option>
                {props.passages.length > 0
                  ? props.passages.map((e, i) => {
                      return (
                        <option key={i} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })
                  : ""}
                <option>Passage</option>
              </FormSelect>
            </Col>
            <Col sm="4">
              <Button outline theme="info" onClick={assignPassage}>
                Assign Passage
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="12">
              <Table
                tableType="passages"
                filter={true}
                {...props}
                tableData={props.passages}
                filterType={"all-passages"}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    users: state.userData.users,
    passages: state.passages
  };
};

const mapActionToProps = dispatch => {
  return {
    getAllPassages: bindActionCreators(getAllPassages, dispatch),
    assignPassage: bindActionCreators(assignPassages, dispatch),
    getUsers: bindActionCreators(getAllUsers, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(AssignPassages);
