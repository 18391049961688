import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  FormInput,
  Col,
  FormSelect,
  Button
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/filterable-table/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllUsers } from "../redux/actions/user-actions";
import { getAllSkills, assignSkill } from "../redux/actions/skill-actions";
import Loader from "../components/loader/Loader";

const AssignSkills = props => {
  const [state, setState] = useState({
    gradeId: null,
    userId: null,
    numOfQuestions: null
  });

  useEffect(() => {
    props.getUsers();
    props.getAllSkills();
  }, []);

  const handleChange = evt => {
    setState({ ...state, [evt.target.name]: evt.target.value });
  };

  const assignSkill = () => {
    if (
      state.userId === null ||
      state.numOfQuestions === null ||
      state.gradeId === null
    ) {
      props.triggerNotification("All fields must be filled to assign a skill");
    } else {
      props.assignSkill(state, props.triggerNotification);
    }
  };
  return (
    <>
      {props.users.length > 0 ? (
        <Container>
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Assign Skills"
              subtitle=""
              className="text-sm-left"
            />
            <br />
          </Row>
          <br />
          <Row form>
            <Col sm="8">
              <FormSelect name="userId" onChange={handleChange}>
                <option value={null}>User</option>
                {props.users.map((e, i) => {
                  return (
                    <option key={i} value={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </FormSelect>
            </Col>
          </Row>
          <br />
          <Row form>
            <Col sm="8">
              <FormInput
                placeholder={"Number of Questions"}
                type="number"
                onChange={handleChange}
                name="numOfQuestions"
              />
            </Col>
          </Row>
          <br />
          <Row form>
            <Col sm="8">
              <FormSelect name="skillId" onChange={handleChange}>
                <option value={null}>Skill</option>
                {
                  Object.keys(props.skillGroups).map((group) => {
                  return (
                    <optgroup label={group}>
                      {
                        props.skillGroups[group].map((groupSkill) => {
                          const skill = props.skills.find(e => e.id == groupSkill.skillId);
                          if (skill) {
                            return (
                              <option key={group+skill.name} value={skill.id}>
                                {skill.name}
                              </option>
                            )
                          }
                        })
                      }
                    </optgroup>
                  )
                })
              }
              </FormSelect>
            </Col>
          </Row>
          <br />
          <Row form>
            <Col sm="8">
              <FormSelect name="gradeId" onChange={handleChange}>
                <option value={null}>Grade</option>
                {props.grades.length > 0
                  ? props.grades.map((e, i) => {
                      return (
                        <option key={i} value={e.id}>
                          {e.name}
                        </option>
                      );
                    })
                  : ""}
              </FormSelect>
            </Col>
            <Col sm="4">
              <Button outline theme="info" onClick={assignSkill}>
                Assign Skill
              </Button>
            </Col>
          </Row>
          <br />
          {/* <Row>
            <Col lg="12">
              <Table
                tableType="skills"
                filter={true}
                {...props}
                tableData={props.skills}
                filterType={"all-skills"}
              />
            </Col>
          </Row> */}
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    users: state.userData.users,
    grades: state.grades
  };
};

const mapActionToProps = dispatch => {
  return {
    assignSkill: bindActionCreators(assignSkill, dispatch),
    getUsers: bindActionCreators(getAllUsers, dispatch),
    getAllSkills: bindActionCreators(getAllSkills, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(AssignSkills);
