import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";

import NotificationSystem from "react-notification-system";
import ErrorBoundary from "./ErrorBoundary";

class App extends React.Component {
  notificationSystem = React.createRef();

  showPop = (
    message = "whoops something happened",
    level = "info",
    position = "bc"
  ) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message,
      level,
      position: "tr"
    });
  };

  render() {
    return (
      <>
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <ErrorBoundary>
            <div>
              <Switch>
                {routes.map((route, index) => {
                  if (route.protected) {
                    return (
                      <ProtectedRoute
                        triggerNotification={this.showPop}
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={props => {
                          return (
                            <route.layout {...props}>
                              <route.component {...props} />
                              <NotificationSystem
                                ref={this.notificationSystem}
                              />
                            </route.layout>
                          );
                        }}
                      />
                    );
                  } else {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={props => {
                          return (
                            <route.layout {...props}>
                              <route.component
                                {...props}
                                triggerNotification={this.showPop}
                              />
                              <NotificationSystem
                                ref={this.notificationSystem}
                              />
                            </route.layout>
                          );
                        }}
                      />
                    );
                  }
                })}
              </Switch>
            </div>
          </ErrorBoundary>
        </Router>
      </>
    );
  }
}

export default App;
