import React from "react";
import { Button } from "shards-react";

const CheckGrammarButton = (props) => {
	return (
		<Button
			size="sm"
			outline
			theme="accent"
			className="mr-auto"
			onClick={props.onClick}
			style={props.style}
		>
			<i className="material-icons">spellcheck</i>{" "}
			Check Grammar
		</Button>
	);
}

export default CheckGrammarButton;