import {GET_COMMONCORE_INDEPENDENT} from "../actions/common-core-independent-actions";

export default function commonCoreReducer(state = [], {type, payload}) {
  switch (type) {
    case GET_COMMONCORE_INDEPENDENT:
      return payload;
    default:
      return state;
  }
}
