import { RETRIEVE_ALL_GENRES } from "../actions/genre-actions";

export default function genreReducer(state = [], {type, payload}) {
    switch (type) {
        case RETRIEVE_ALL_GENRES:
            return payload;
        default:
            return state;
    }
}
