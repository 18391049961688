import Axios from "axios";
import store from "../store";

const api = require("../api/api-config");

export function getGenres() {
  Axios.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };
  return new Promise((resolve, reject) => {
    Axios.get(api.api + "/genres")
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
