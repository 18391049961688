import React from "react";
import {Row, Col, FormInput, InputGroup,
  InputGroupAddon, InputGroupText} from "shards-react";

const SearchBar = (props) => {
  return(
      <Row>
        <Col lg="12">     
           <InputGroup className="mb-2">
            <InputGroupAddon type="prepend">
              <InputGroupText><i className="fas fa-search"></i></InputGroupText>
            </InputGroupAddon>
            <FormInput onChange={props.onChange} value={props.value || ""} placeholder="Enter your search" />
          </InputGroup>  
        </Col>
      </Row>
  );
}

export default SearchBar;